import React from 'react'
import InputPriceEdit from "../InputPriceEdit";
import { EditProductsContainerAdmin } from "./wrapper";
import { useState, useEffect } from "react";
import CheckBoxComp from "../CustomCheckBox";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import {ALL_REGIONS_EXCEPT_A} from '../../constants/allConstants';
import Select from 'react-select';
import { OFFER_BASE_URL} from "../../service/servicesConfig";
import axios from 'axios';

const CommercialViewComp = ({
  regionDetails,
  prodData,
  priceFromDB,
  calcObj,
  showWastage,
  showMaterial,
  includeMaterial,
  ...props
}) => {
const priceSymbol = (
      <span
        style={{ width: "auto", fontWeight: "normal", margin: "0rem 0.5rem" }}
      >
        {" "}
        &#x20B9;{" "}
      </span>
    );

const showMaterialText = (
  <span
    style={{
      textDecorationLine: showMaterial ? "" : "line-through",
      fontWeight: showMaterial ? "bold" : "normal",
    }}
  >
    4 / {priceFromDB.lot_size}
  </span>
);
const showWastageText = (
  <span
    style={{
      textDecorationLine: showWastage ? "" : "line-through",
      fontWeight: showWastage ? "bold" : "normal",
    }}
  >
    {priceFromDB.wastage_per} % of {priceFromDB.base_price}
  </span>
);
console.log(priceFromDB);
let sourceObj = {
  mill: "Mill",
  dc_delivered: "DC Delivered",
  local_pick: "Local Pick",
  SELECT: "SELECT",
};
let millTransportObj = {
  included: "Included",
  excluded: "Excluded",
  SELECT: "SELECT",
};
console.log(sourceObj);
let sourceVal = priceFromDB.source_type
  ? sourceObj[priceFromDB.source_type]
  : "SELECT";
let millTransportVal = priceFromDB.mill_transport
  ? millTransportObj[priceFromDB.mill_transport]
  : "SELECT";

const [sourceType, setSourceType] = useState(sourceVal);
const [millTransport, setMillTransport] = useState(millTransportVal);
const [millTransportCost, setMillTransportCost] = useState(
  priceFromDB.mill_transport_value ? priceFromDB.mill_transport_value : ""
);
const [initialRender, setInitialRender] = useState(true);
const[seriesNewEMList,setSeriesNewEMList] = useState([]);
const[seriesList,setSeriesList] = useState([]);
const primaryPMFlag = priceFromDB.primary_pm_cost > 0? true : false;
const [showPrimaryPM,setShowPrimaryPM] = useState(primaryPMFlag);
const secondaryPMFlag = priceFromDB.package_material_cost > 0? true : false;
const [showSecondaryPM,setShowSecondaryPM] = useState(secondaryPMFlag)
const [isSecondaryPMSelected, setIsSecondaryPMSelected] = useState(priceFromDB.package_material_cost > 0);
const [materialCostFactor, setMaterialCostFactor] = useState(priceFromDB.material_cost_factor);
const [secondaryPMCost, setSecondaryPMCost] = useState(priceFromDB.package_material_cost);
const [isMaterialCostSelected, setIsMaterialCostSelected] = useState(showMaterial);

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_RIGHT,
});

var dbSeries = [];
    {
        Object.entries(priceFromDB).map(([key,value]) => {

        if(key.includes("extra_margin_per_")){
        
            dbSeries.push(key.split("_")[3]);
            console.log(`selectedVal is ${dbSeries}`)
        }
        })
        
        }

    const ddVal = ALL_REGIONS_EXCEPT_A.filter(({value,label}) => ! dbSeries.includes(value))
    console.log(`check all dd `, ddVal)
 

// ----- 
const handleChange = (e) => {
  if(!e){
      setSeriesList([]); 
      
  } else{
      e.map((series) => {
      setSeriesNewEMList(seriesNewEMList => {
          let filteredEMList = seriesNewEMList.filter(val => val !== "extra_margin_per_" +series.value);
          return [...filteredEMList, "extra_margin_per_" +series.value]
        }); 
      }); 
  }
}

useEffect(() => {
  if(!showPrimaryPM)
      props.priceDropdownModify("primary_pm_cost",0);
}, [showPrimaryPM]);

useEffect(() => {
  if(!showSecondaryPM)
      props.priceDropdownModify("package_material_cost",0);
}, [showSecondaryPM]);

//   useEffect(() => {
//         setIsMaterialCostSelected(showMaterial);
//   }, [showMaterial]);

useEffect(() => {
   setIsSecondaryPMSelected(priceFromDB.package_material_cost > 0);
}, [priceFromDB.package_material_cost]);

useEffect(() => {
   setIsMaterialCostSelected(priceFromDB.material_cost_factor > 0);
}, [priceFromDB.material_cost_factor]);

useEffect(() => {
  if (isMaterialCostSelected) {
      setIsSecondaryPMSelected(false);
      includeMaterial(true);
      props.priceModify("package_material_cost",0);
  } else {
      includeMaterial(false);
      props.priceModify('material_cost_factor', 0);
  }
}, [isMaterialCostSelected]);

useEffect(() => {
  if (isSecondaryPMSelected) {
      setIsMaterialCostSelected(false);
      includeMaterial(false);
      props.priceModify('material_cost_factor', 0);
  } else {
      includeMaterial(true);
      props.priceModify("package_material_cost",0);
  }
}, [isSecondaryPMSelected]);

useEffect(() => {
  setMaterialCostFactor(priceFromDB.material_cost_factor);
  setSecondaryPMCost(priceFromDB.package_material_cost);
}, [priceFromDB]);

const toggleMaterialCostSelection = () => {
  setIsMaterialCostSelected(prev => !prev);
  setIsSecondaryPMSelected(false);
  includeMaterial(prev => !prev);
};

const toggleSecondaryPMSelection = () => {
  setIsSecondaryPMSelected(prev => !prev);
  setIsMaterialCostSelected(false);
  includeMaterial(prev => !prev);
};

function extraMarginText(str) {
  var splitStr = str.split('_');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' ')+' %'; 
}

function dealerPriceText(str) {
  var splitStr = str.split('_');
  return splitStr[splitStr.length-1]; 
}

const dealerPriceValidation = (item_id,dealer_price,e) =>{
     
  const url = `${OFFER_BASE_URL}/product-offer?item_id=${item_id}&dealer_price=${dealer_price}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
  axios.get(url)
 .then(function (response) {
   let isValid =  response.data.data;
   if(isValid) {
       updateValidations(e);
   }else {
       return toast("Dealer Price should be greater than offer price.", { type: toast.TYPE.ERROR });
   }
  }).catch(function (error) {
   return false;
 });
}

const updateValidations = (e) => {
  if (
    (initialRender && sourceVal == "SELECT") ||
    (!initialRender && sourceType == "SELECT")
  ) {
    console.log(initialRender);
    console.log(sourceVal);
    console.log(sourceType);
    return toast("Please select source type.", { type: toast.TYPE.ERROR });
  } else {
    props.updateData(e);
  }
};
    return (
      <EditProductsContainerAdmin>
        <header>
          <h3>Edit the product</h3>
          <span className="close_icon" onClick={props.closeScreen}>
            x
          </span>
        </header>
        <div className="edit_item_name">
          <h4>
            {" "}
            {prodData.serialNo}. {prodData.keyNo} / {prodData.item_description1}{" "}
            / {prodData.brand}{" "}
          </h4>
          <h4>
            {" "}
            <i className="fas fa-university"></i> . {regionDetails["curr_city"]}
          </h4>
        </div>
        <div className="calContainer">
          <div className="leftPanel">
            <h6>
              <span>Base Price</span>:{priceSymbol}
              <InputPriceEdit
                priceVal={priceFromDB.base_price}
                modifyVal={props.priceModify}
                // isDisabled={true}
                nameText="base_price"
              />
            </h6>
            <h6>
              <span>Lot Size</span>:
              <InputPriceEdit
                priceVal={priceFromDB.lot_size}
                isDisabled={true}
                modifyVal={props.priceModify}
                nameText="lot_size"
              />
            </h6>
            <h6>
              <span>Source Type</span>:
              <DropdownButton
                title={
                  initialRender ? sourceVal : sourceType ? sourceType : "SELECT"
                }
                id="dropdown-menu-select-portal"
                // disabled={true}
              >
                <Dropdown.Item
                  href="#"
                  name="local_pick"
                  onClick={(e) => {
                    e.preventDefault();
                    setInitialRender(false);
                    setSourceType("Local Pick");
                    props.priceDropdownModify("source_type", e.target.name);
                  }}
                >
                  Local Pick
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  name="dc_delivered"
                  onClick={(e) => {
                    e.preventDefault();
                    setInitialRender(false);
                    setSourceType("DC Delivered");
                    props.priceDropdownModify("source_type", e.target.name);
                  }}
                >
                  DC Delivered
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  name="mill"
                  onClick={(e) => {
                    e.preventDefault();
                    setInitialRender(false);
                    setSourceType("Mill");
                    props.priceDropdownModify("source_type", e.target.name);
                  }}
                >
                  Mill
                </Dropdown.Item>
              </DropdownButton>
            </h6>
            {((initialRender && sourceVal == "Mill") ||
              sourceType == "Mill") && (
              <h6>
                <span>Mill Transport</span>:
                <DropdownButton
                  title={
                    initialRender
                      ? millTransportVal
                      : millTransport
                      ? millTransport
                      : "SELECT"
                  }
                  id="dropdown-menu-select-portal"
                  // disabled={true}
                >
                  <Dropdown.Item
                    href="#"
                    name="included"
                    onClick={(e) => {
                      e.preventDefault();
                      setMillTransport("Included");
                      props.priceDropdownModify(
                        "mill_transport",
                        e.target.name
                      );
                    }}
                  >
                    Included
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    name="excluded"
                    onClick={(e) => {
                      e.preventDefault();
                      setMillTransport("Excluded");
                      props.priceDropdownModify(
                        "mill_transport",
                        e.target.name
                      );
                    }}
                  >
                    Excluded
                  </Dropdown.Item>
                </DropdownButton>
              </h6>
            )}
            {((initialRender && millTransportVal == "Excluded") ||
              millTransport == "Excluded") &&
              ((initialRender && sourceVal == "Mill") ||
                sourceType == "Mill") && (
                <h6>
                  <span>Mill Transport Cost</span>:
                  <InputPriceEdit
                    priceVal={priceFromDB.mill_transport_value}
                    modifyVal={props.priceModify}
                    nameText="mill_transport_value"
                    // isDisabled={true}
                  />
                </h6>
              )}
            {((initialRender && sourceVal == "Mill") ||
              sourceType == "Mill") && (
              <h6>
                <span>CD %</span>:
                <InputPriceEdit
                  priceVal={priceFromDB.cd_per}
                  modifyVal={props.priceModify}
                  nameText="cd_per"
                  // isDisabled={true}
                />
              </h6>
            )}

            {((initialRender && sourceVal=="Mill") || sourceType=="Mill") && <h6>
                        <span>Primary PM Cost </span>:
                        <input type="checkbox" checked={priceFromDB.primary_pm_cost > 0 || showPrimaryPM}  onChange={e => setShowPrimaryPM(!showPrimaryPM)}  />
                        {(priceFromDB.primary_pm_cost > 0 || showPrimaryPM) && <InputPriceEdit priceVal={priceFromDB.primary_pm_cost} modifyVal={props.priceModify} nameText="primary_pm_cost" />}
            </h6>}
            <h6>
              <span>APMC %</span>:
              <InputPriceEdit
                priceVal={priceFromDB.apmc_per}
                modifyVal={props.priceModify}
                nameText="apmc_per"
                isDisabled={true}
              />
            </h6>
            <h6>
              <span>GST %</span>:
              <InputPriceEdit
                priceVal={priceFromDB.gst_per}
                modifyVal={props.priceModify}
                nameText="gst_per"
                isDisabled={true}
              />
            </h6>
            <h6>
              <span>APMC Loading</span>:
              <InputPriceEdit
                priceVal={priceFromDB.apmc_loading}
                modifyVal={props.priceModify}
                nameText="apmc_loading"
                isDisabled={true}
              />
            </h6>
            <h6>
              <span>Local Transport</span>:
              <InputPriceEdit
                priceVal={priceFromDB.local_transport}
                modifyVal={props.priceModify}
                nameText="local_transport"
                isDisabled={true}
              />
            </h6>

            <span className="hz_line"></span>
            <h6>
              <span>Conversion %</span>:
              <InputPriceEdit
                priceVal={priceFromDB.conversion_per}
                modifyVal={props.priceModify}
                nameText="conversion_per"
                // isDisabled={true}
              />
            </h6>
            <h6>
              <span>Material Cost Factor </span>:
              <CheckBoxComp
                isChecked={isMaterialCostSelected}
                toggleIsChecked={toggleMaterialCostSelection}
                idText="1"
                // disabled = {true}
              />
              {isMaterialCostSelected && (
                <InputPriceEdit
                  priceVal={materialCostFactor}
                  modifyVal={props.priceModify}
                  nameText="material_cost_factor"
                  // isDisabled={true}
                />
              )}
            </h6>
            <h6>
              <span>Wastage %</span>:
              <CheckBoxComp
                isChecked={showWastage}
                toggleIsChecked={props.includeWastage}
                idText="2"
                // disabled={true}
              />
              {showWastage && (
                <InputPriceEdit
                  priceVal={priceFromDB.wastage_per}
                  modifyVal={props.priceModify}
                  nameText="wastage_per"
                  // isDisabled={true}
                />
              )}
              %
            </h6> 
            <h6>
              <span>Secondary PM Cost </span>:
              {/* <input type="checkbox" checked={isSecondaryPMSelected}  onChange={toggleSecondaryPMSelection}  /> */}
              <CheckBoxComp isChecked={isSecondaryPMSelected} toggleIsChecked={toggleSecondaryPMSelection} idText="3" />
              {isSecondaryPMSelected && <InputPriceEdit priceVal={secondaryPMCost} modifyVal={props.priceModify} nameText="package_material_cost" />}
            </h6>
            <h6>
              <span>Extra Margin Per(%)</span>:
              <InputPriceEdit
                priceVal={priceFromDB.extra_margin_per}
                modifyVal={props.priceModify}
                nameText="extra_margin_per"
                // isDisabled={true}
              />
              %
            </h6>

                     <div>
                        <h6 >
                            <span>
                            Select Extra Margin Series
                            </span>
                            : 
                        </h6>
                        <div style ={{
                            width:'300px'}}>
                            <Select
                            options = {ddVal}
                            defaultValue = {seriesList}
                            onChange = {handleChange}
                            isMulti = {true}
                            >
                            +
                            </Select>
                            
                        </div>
                    </div>

                    {Object.entries(priceFromDB).map(([key,value]) => {
                        if(key.includes("extra_margin_per_") && !seriesNewEMList.includes(key)){
                        return (
                            <h6>    
                                <span>{extraMarginText(key)}</span>:
                                <InputPriceEdit  priceVal = {value} modifyVal={props.priceModify} nameText={key}/>%
                            </h6>)
                        }} )
                    }
      
      
                    {(  seriesNewEMList.map((series) => {
                      return (
                      <h6>    
                          <span>{extraMarginText(series)}</span>:
                          <InputPriceEdit priceVal={priceFromDB[series]} modifyVal={props.priceModify} nameText={series}/>%
                     </h6>)})
                      )}
          </div>
          <div className="rightPanel">
            <section className="topPart">
              {((initialRender && sourceVal == "Mill") ||
                sourceType == "Mill") && (
                <h6>
                  {" "}
                  <span>CD Amount</span> : {priceSymbol} {calcObj.cd_amount}{" "}
                </h6>
              )}
              <h6>
                {" "}
                <span>APMC Charge</span> : {priceSymbol} {calcObj.apmc_charge}{" "}
              </h6>
              <h6>
                {" "}
                <span>Buying GST Charge</span> : {priceSymbol}{" "}
                {calcObj.buying_gst_charge}{" "}
              </h6>
              {/* <h6>
                {" "}
                <span>Landing Cost</span> : {priceSymbol} {calcObj.landing_cost}{" "}
              </h6> */}
              <h6> <span>Gross Cost</span> : {priceSymbol} {calcObj.gross_cost} </h6>
              <h6>
                {" "}
                <span>Final Buying Price</span> : {priceSymbol}{" "}
                {calcObj.final_buying_price}{" "}
              </h6>
              <h6>
                {" "}
                <span>Final Buying Price Ex GST</span> : {priceSymbol}{" "}
                {calcObj.buying_price_ex_gst}{" "}
              </h6>
              <h6>
                {" "}
                <span>Conversion Cost</span> : {priceSymbol}{" "}
                {calcObj.conversion_charges}{" "}
              </h6>
              {showMaterial ? (
                <h6>
                  {" "}
                  <span>Material Cost</span> : {priceSymbol}{" "}
                  {calcObj.material_cost}{" "}
                </h6>
              ) : null}
              {showWastage ? (
                <h6>
                  {" "}
                  <span>Wastage Cost</span> : {priceSymbol} {calcObj.wastage}{" "}
                </h6>
              ) : null}

            {/* {calcObj.package_material_cost > 0 ? (<h6> <span>Secondary PM Cost</span> : {priceSymbol} {calcObj.package_material_cost} </h6>) : null} */}
            {isSecondaryPMSelected || isMaterialCostSelected ? (<h6> <span>Secondary PM Cost</span> : {priceSymbol} {calcObj.package_material_cost} </h6>) : null}
              <h6> <span>Landing Cost</span> : {priceSymbol} {calcObj.landing_cost} </h6>
            </section>

            <section className="midPart">
              {/* <h6>
                {" "}
                <span>Conversion Cost</span> : {priceSymbol}{" "}
                {calcObj.conversion_charges}{" "}
              </h6>
              {showMaterial ? (
                <h6>
                  {" "}
                  <span>Material Cost</span> : {priceSymbol}{" "}
                  {calcObj.material_cost}{" "}
                </h6>
              ) : null}
              {showWastage ? (
                <h6>
                  {" "}
                  <span>Wastage Cost</span> : {priceSymbol} {calcObj.wastage}{" "}
                </h6>
              ) : null} */}
              <h6>
                {" "}
                <span>Extra Margin</span> : {priceSymbol}{" "}
                {calcObj.extra_margin_charges}{" "}
              </h6>
              <h6>
                {" "}
                <span>Extra Margin Pune</span> : {priceSymbol}{" "}
                {calcObj.extra_margin_charges}{" "}
              </h6>
              <h6>
                {" "}
                <span>GST Charge</span> : {priceSymbol} {calcObj.gst_charge}{" "}
              </h6>
              <h6>
                {" "}
                <span>Total Price</span> : {priceSymbol} {calcObj.total_price}{" "}
              </h6>
              <h6>
                {" "}
                <span>Final Price</span> : {priceSymbol} {calcObj.final_price}{" "}
              </h6>
              <h6>
                {" "}
                <span>Final Price Ex GST</span> : {priceSymbol}{" "}
                {calcObj.final_price_ex_gst}{" "}
              </h6>

              <h6>
                {" "}
                <span>Gross Margin %</span> : {priceSymbol}{" "}
                {calcObj.gross_gm_per}{" "}
              </h6>
              <h6>
                {" "}
                <span>Net Margin %</span> : {priceSymbol} {calcObj.net_gm_per}{" "}
              </h6>
              <h6>
                {" "}
                <span>Total Bag Price</span> : {priceSymbol}{" "}
                {calcObj.total_bag_price}{" "}
              </h6>
              <h6>
                {" "}
                <span>Final Bag Price</span> : {priceSymbol}{" "}
                {calcObj.final_bag_price}{" "}
              </h6>
              <h6>
                {" "}
                <span>Dealer Price</span> : {priceSymbol} {calcObj.dealer_price}{" "}
              </h6>

              {Object.entries(priceFromDB).map(([key,value]) => {
                        if(key.includes("extra_margin_per_") && !seriesNewEMList.includes(key)){
                        return (
                            <h6>    
                                <span>{"Dealer Price "+dealerPriceText(key)}</span>:
                                {priceSymbol} {calcObj["dealer_price_"+dealerPriceText(key)]}
                            </h6>)
                }} 
              )}

              {(  seriesNewEMList.map((series) => {
                      return (
                      <h6>    
                          <span>{"Dealer Price "+dealerPriceText(series)}</span>:
                          {priceSymbol} {calcObj["dealer_price_"+dealerPriceText(series)]}
                     </h6>)})
                      )}

              {prodData.market_price && (
                <h6>
                  {" "}
                  <span>Retailer Margin Per(%)</span> :{" "}
                  {calcObj.retailer_margin_per}{" "}
                </h6>
              )}
            </section>

            <section className="botPart">
              <div className="update" onClick={(e) => { dealerPriceValidation(priceFromDB.item_id,calcObj.dealer_price,e); }}> Update </div>
              <div className="cancel" onClick={props.closeScreen}> Cancel</div>
            </section>
          </div>
        </div>
      </EditProductsContainerAdmin>
    );
};

export default CommercialViewComp;