import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import RootRoute from './routes';
import './index.css';
import { StateProvider } from './store';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';

const RootComponent = () => {
  useEffect(() => {
    const disableScrollOnNumberInputs = (event) => {
      if (event.target.type === "number") {
        event.preventDefault();  
        event.target.blur();     
      }
    };

    // Add event listener to disable scroll on number inputs
    document.addEventListener("wheel", disableScrollOnNumberInputs, { passive: true });

    return () => {
      document.removeEventListener("wheel", disableScrollOnNumberInputs);
    };
  }, []);

  return (
    <StateProvider>
      <RootRoute />
    </StateProvider>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById('root'));
