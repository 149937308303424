import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  InputGroupAddon,
  Input,
  InputGroupText,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";
import {
  Breadcrumb,
  Tab,
  Form,
  Row,
  Col,
  Nav,
  Button,
  InputGroup,
  Badge,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import Select from "react-select";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import XLSX from "xlsx";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import LoaderComp from "../../components/Loader";
import {
  validateGRNOnlyUser,
  validateIsAdminUser,
  validatePurchaseUser,
  validatePurchaseExportUser,
  validatePurchaseExportOnlyUser,
  validatePurchaseOnlyUser,
  validatePurchaseReportUser,
} from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import UpdateProductNewDealerPriceModal from "./updatedealerpricemodal";
import BulkUpdateProductDealerPriceModal from "./bulkpriceupdatetable";
import BulkAddProductPendingApprovalModal from "./bulkaddproductapproval";
import { TRANSPORT_FACTOR, REGION_BOM_A, ALL_REGIONS_LIST } from "../../constants/allConstants";
import baseUrl from "../../service/servicesConfig";
import PriceCalculations from "../../service/priceCalculations";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

function originalFormatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const PriceUpdates = () => {
  const [loading, setLoading] = useState(true);
  const [priceAnalysisList, setPriceAnalysisList] = useState([]);
  const [priceAnalysisListForUI, setPriceAnalysisListForUI] = useState([]);
  const [searchPriceAnalysisList, setSearchPriceAnalysisList] = useState([]);
  const [todayPriceAnalysisList, setTodayPriceAnalysisList] = useState([]);
  const [initialProductCheck, setInitialProductCheck] = useState(true);
  const [bulkProductsUpdateList, setBulkProductsUpdateList] = useState([]);
  const [bulkProductUpdateModalOpen, setBulkProductUpdateModalOpen] =
    useState(false);
  const [region, setRegion] = useState("All");
  const [regionTransportCharge, setRegionTransportCharge] = useState(
    TRANSPORT_FACTOR["A"]
  );
  const [priceChangeText, setPriceChangeText] = useState("All");
  const [pendingApprovalProducts, setPendingApprovalProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [lastNumDaysText, setLastNumDaysText] = useState("3");
  let [productDataObj, setProductDataObj] = useState({});
  let [priceDataObj, setPriceDataObj] = useState({});
  const [pendingProductsObj, setPendingProductsObj] = useState({});
  const [onlyLatestChanges, setOnlyLatestChanges] = useState(true);
  const [updatedProducts, setUpdatedProducts] = useState(false);
  const [purchaseDetailsList, setPurchaseDetailsList] = useState([]);

  //update table row
  const [updateProduct, setUpdateProduct] = useState(false);
  const [productPriceDetails, setProductPriceDetails] = useState("");
  const [latestBasePrice, setLatestBasePrice] = useState(0);
  const [rowIndex, setRowIndex] = useState();

  //bulk product pending approval
  const [bulkProductPendingApprovalOpen, setBulkProductPendingApprovalOpen] =
    useState(false);
  const [bulkProductsPendingApprovalList, setBulkProductsPendingApprovalList] =
    useState([]);

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  //new code
  useEffect(()=>{
    let finalDateList = [];
    let today = new Date();
    let finalTodayDate = formatDate(new Date(today));
    finalDateList.push(finalTodayDate);

    for (let datevall = 1; datevall <= 3; datevall++) {
      let prevDate = new Date(today);
      prevDate.setDate(prevDate.getDate() - datevall);
      let finalPrevDate = formatDate(new Date(prevDate));
      finalDateList.push(finalPrevDate);
    }

    Promise.all([
      database.database().ref(`Product_Master_New/A/`).once("value"),
      database.database().ref(`Price_Details/mumbai/items`).once("value"),
      database.database().ref(`Pending_Price_Update/`).once("value"),
    ]).then(([productsnap, pricesnap, pendingprodsnap]) => {
        // let pendingproductobj = snapshot.val();
        let pendingProdList = [];
        let dealerpriceobj = productsnap.val();
        let productpriceobj = pricesnap.val();
        let pendingproductobj = pendingprodsnap.val();
        //for (let i = 0; i < priceAnalysisList.length; i++) {
          //if (priceAnalysisList[i]["productapproved"]) {
            //if (priceAnalysisList[i]["productapproved"] == "N") {
              // let ele = priceAnalysisList[i];
              // let itemid = priceAnalysisList[i]["rowselectkey"];
              let itemPriceCheck = false;
              priceDataObj = productpriceobj;
              productDataObj = dealerpriceobj;
              
              let innerEl = {};
              for (let fdate = finalDateList.length - 1; fdate >= 0; fdate--) {
                let loopCheckPrevDate = finalDateList[fdate];
                let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
                let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

                if (pendingproductobj[loopCheckPrevYear]) {
                  if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                    if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][loopCheckPrevDate]) {
                      let currpendingprodobj =pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][loopCheckPrevDate];

                      const uniqueData = {};
                      const uniqueDataOb = {};
                      let sortedEntries = Object.entries(currpendingprodobj).sort(([, a], [, b]) => {
                        if (a.approved === 'Y' && b.approved !== 'Y') return -1;
                        if (a.approved !== 'Y' && b.approved === 'Y') return 1;
                        
                        return 0;
                      });
                      
                      let sortedCurrpendingprodobj = Object.fromEntries(sortedEntries);

                      for (const [key, value] of Object.entries(sortedCurrpendingprodobj)) {
                        let item_id = value.item_id;
                        let updated_at = value.updated_at;
                        //if(item_id =='1000910043') {
                         if(item_id in uniqueData){
                          if(value.approved != 'Y' && updated_at > uniqueData[item_id].updated_at){
                            uniqueData[item_id] = value;
                            uniqueDataOb[key] = value; 
                          }
                          // else {
                          //   uniqueData[item_id] = [];
                          //   for (const [innerKey, innerVal] of Object.entries(uniqueDataOb)) {
                          //     if (innerVal['item_id'] == item_id ) {
                          //       uniqueDataOb[innerKey] = [];
                          //     }
                          //   }
                          // }
                         }else {
                          uniqueData[item_id] = value;
                          uniqueDataOb[key] = value;
                         }
                        //}
                      }
                      //})
                      console.log(uniqueData);
                      console.log(uniqueDataOb);
                      console.log(currpendingprodobj);
                      currpendingprodobj = uniqueDataOb;
                      //if (currpendingprodobj[itemid]) {
                        Object.keys(currpendingprodobj).map((key, index) => {
                            let ele = {};
                            let parent_id = currpendingprodobj[key]["parent_id"] ? currpendingprodobj[key]["parent_id"] : "";
                            if(currpendingprodobj[key]['approved'] == 'N' && (parent_id == currpendingprodobj[key]["item_id"] || !parent_id ) || (currpendingprodobj[key].updated_from == 'Edit Product Details' && currpendingprodobj[key]['approved'] == "N") ) {
                              let articleCode = currpendingprodobj[key]["item_id"];
                              let newBasePrice = currpendingprodobj[key]["base_price"];
                              if(currpendingprodobj[key]['updated_from'] == 'Edit Price'){
                                //priceFromDB[articleCode].extra_margin_per 
                                priceDataObj[articleCode][`extra_margin_per`] = currpendingprodobj[key][`extra_margin_per`];
                                priceDataObj[articleCode][`dealer_price`] = currpendingprodobj[key][`dealer_price`];
                                ALL_REGIONS_LIST.forEach((region) =>{
                                  if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                                    priceDataObj[articleCode][`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                                  }
  
                                  if(currpendingprodobj[key][`dealer_price_${region}`]) {
                                    priceDataObj[articleCode][`dealer_price_${region}`] = currpendingprodobj[key][`dealer_price_${region}`];
                                  }
                                })
                              }

                              if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){ 
                                priceDataObj[articleCode][`retailer_margin_per`] = currpendingprodobj[key][`retailer_margin_per`];
        
                                ALL_REGIONS_LIST.forEach((region) =>{
                                  if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                                    priceDataObj[articleCode][`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                                  }
                                })
                              }
                              let show_mat = priceDataObj[articleCode] && priceDataObj[articleCode].material_cost > 0 ? true: false;
                              let show_was = priceDataObj[articleCode] && priceDataObj[articleCode].wastage > 0 ? true : false;
                              let updatedProdPriceObj = dealerPriceCalculations(priceDataObj[articleCode],productDataObj[articleCode],show_mat,show_was,newBasePrice); 
                              ele["purchase_price"] = currpendingprodobj[key]["base_price"];
                              ele["newDealerPrice"] = currpendingprodobj[key]["dealer_price"];
                              ele["newgstcharge"] = currpendingprodobj[key]["gst_charge"];
                              ele["newapmccharge"] = "" + currpendingprodobj[key]["apmc_charge"];
                              // ele["newtransportcharge"] = currpendingprodobj[key]["transport_charge"];
                              ele["newtransportcharge"] = currpendingprodobj[key]["mill_transport_value"];
                              ele["table_update_date"] = loopCheckPrevDate;
                              itemPriceCheck = true;
                              ele["newSource"] = currpendingprodobj[key]["source_type"];
                              ele["newTransport"] = currpendingprodobj[key]["transport"] ? currpendingprodobj[key]["transport"] : currpendingprodobj[key]["mill_transport"];
                              // ele["newtransportcharge"] = currpendingprodobj[itemid].transport_charge;
                              ele["newCD"] = currpendingprodobj[key]["cd_per"];
                              ele["newExtraMargin"] = currpendingprodobj[key]["extra_margin_per"];
                              ele["newTotalPrice"] = currpendingprodobj[key]["total_price"];
                              ele["article_code"] = currpendingprodobj[key]["item_id"];
                              ele["rowselectkey"] = key;
                              ele["old_base_price"] = currpendingprodobj[key]["old_base_price"];
                              ele["item_name"] = `${productDataObj[currpendingprodobj[key]["item_id"]].brand}-${productDataObj[currpendingprodobj[key]["item_id"]].long_description}`;
                              // ele["dealer_price"] = updatedProdPriceObj["dealer_price"];
                              ele["dealer_price"] = currpendingprodobj[key]["dealer_price"];
                              ele["base_price"] = currpendingprodobj[key]["old_base_price"];
                              ele["apmc_charge"] = "" + updatedProdPriceObj["apmc_charge"];
                              ele["gst_charge"] = getRoundNoThree(updatedProdPriceObj["gst_charge"]);
                              ele["updated_from"] = currpendingprodobj[key]["updated_from"];
                              ele["calcObj"] = updatedProdPriceObj;
                              ele["productdata"] = productDataObj[articleCode];
                              ele[`retailer_margin_per`] = currpendingprodobj[key][`retailer_margin_per`];
                              ALL_REGIONS_LIST.forEach((region) =>{
                                if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                                  ele[`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                                }

                                if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                                  ele[`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                                }
                              })

                              ele[`selling_price`] = currpendingprodobj[key][`selling_price`];
                              ele[`selling_price_ex_gst`] = currpendingprodobj[key][`selling_price_ex_gst`];
                              ele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                              ele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                              ele[`conversion_per`] = currpendingprodobj[key]['conversion_per'];
                              ele[`conversion`] = currpendingprodobj[key]['conversion'];
                              ele[`wastage_per`] = currpendingprodobj[key]['wastage_per'];
                              ele[`wastage`] = currpendingprodobj[key]['wastage'];
                              ele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                              ele[`mrp`] = currpendingprodobj[key][`market_price`];
                              ele[`gst_charge`] = currpendingprodobj[key][`gst_charge`];
                              ele['pending_price_updated_at'] = currpendingprodobj[key]['updated_at']
                              ele["productapproved"] = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"]: "";
                              pendingProdList.push(ele);
                            }
                        })
                    }
                  }
                }
              }


        let finalProductsList = [];
        for (let penprod = 0; penprod < pendingProdList.length; penprod++) {
          let actualParentItem = pendingProdList[penprod]["article_code"];

          let parentele = pendingProdList[penprod];
          if(parentele.updated_from != 'Edit Product Details') { 
            parentele.type = "parent";
            parentele.parent_id = actualParentItem;
            finalProductsList.push(parentele);
          }

          //find child data
          for (let finaldate = finalDateList.length - 1; finaldate >= 0; finaldate--) {
            let loopCheckPrevDate = finalDateList[finaldate];
            let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
            let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

            if (pendingproductobj[loopCheckPrevYear]) {
              if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                if (
                  pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                    loopCheckPrevDate
                  ]
                ) {
                  let currpendingprodobj =
                    pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                      loopCheckPrevDate
                    ];
                  //find child item key

                  const articleCodeApprovals = {};
                  
                  function parseDate(dateString) {
                    const [datePart, timePart] = dateString.split(' ');
                    const [day, month, year] = datePart.split('-');
                    return new Date(`${year}-${month}-${day}T${timePart}`);
                  }                  

                  Object.keys(currpendingprodobj).forEach((key) => {
                    const articleCode = currpendingprodobj[key]['item_id'];
                    const updatedAt = currpendingprodobj[key]['updated_at'];
                    const approvedStatus = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"] : "";
                    const currentUpdatedAt = parseDate(updatedAt);

                    // Only store the latest updated_at with approved as "Y"
                    // if (!articleCodeApprovals[articleCode] || new Date(updatedAt) > new Date(articleCodeApprovals[articleCode].updated_at)) {
                    //   articleCodeApprovals[articleCode] = { updated_at: updatedAt, approved: approvedStatus };
                    // }
                    if (!articleCodeApprovals[articleCode]) {
                      articleCodeApprovals[articleCode] = {};
                    }

                    const updatedFromType = currpendingprodobj[key].updated_from;
                    const currentApprovalData = articleCodeApprovals[articleCode][currpendingprodobj[key].updated_from];
                    if (!articleCodeApprovals[articleCode][updatedFromType]) {
                      articleCodeApprovals[articleCode][updatedFromType] = {
                          updated_at: updatedAt,
                          approved: approvedStatus
                      };
                    } else {
                        const existingApprovalData = articleCodeApprovals[articleCode][updatedFromType];
                        if (new Date(currentUpdatedAt) > new Date(parseDate(existingApprovalData.updated_at))) {
                            articleCodeApprovals[articleCode][updatedFromType] = {
                                updated_at: updatedAt,
                                approved: approvedStatus
                            };
                        }
                    }   
                  });

                  Object.keys(currpendingprodobj).map((key, index) => {
                    let parent_id = currpendingprodobj[key]["parent_id"] ? currpendingprodobj[key]["parent_id"] : "";
                    let articleCode = currpendingprodobj[key]["item_id"] ? currpendingprodobj[key]["item_id"] : "";
                    let oldBasePrice = currpendingprodobj[key]["old_base_price"];
                    let newBasePrice = currpendingprodobj[key]["base_price"];
                    let approvedProd = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"] : "";

                    const updatedAt = currpendingprodobj[key]['updated_at'];

                    const updatedFromType = currpendingprodobj[key].updated_from;
                    const latestApproval = articleCodeApprovals[articleCode][updatedFromType];

                    if (latestApproval && latestApproval.updated_at) {
                      const latestApprovalDate = parseDate(latestApproval.updated_at);
                      const currentUpdatedAt = parseDate(updatedAt);
                      switch (updatedFromType) {
                        case 'Edit Product Details':
                        case 'Edit Price':
                        case 'Edit Dealer Price':
                        case 'Price Update':
                            if (latestApproval.approved === 'Y' && latestApprovalDate >= currentUpdatedAt) {
                                // Skip this iteration as the latest update for this article_code has already been approved
                                return;
                            }
                            break;
            
                        default:
                            break;
                      }            
                    }

                    if (parent_id == actualParentItem && parent_id != articleCode && approvedProd == "N" || (currpendingprodobj[key].updated_from == 'Edit Product Details' && approvedProd == "N")) {

                      if(currpendingprodobj[key]['updated_from'] == 'Edit Price'){
                        //priceFromDB[articleCode].extra_margin_per 
                        priceDataObj[articleCode][`extra_margin_per`] = currpendingprodobj[key][`extra_margin_per`];
                        priceDataObj[articleCode][`dealer_price`] = currpendingprodobj[key][`dealer_price`];
                        
                        ALL_REGIONS_LIST.forEach((region) =>{
                          if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                            priceDataObj[articleCode][`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                          }

                          if(currpendingprodobj[key][`dealer_price_${region}`]) {
                            priceDataObj[articleCode][`dealer_price_${region}`] = currpendingprodobj[key][`dealer_price_${region}`];
                          }
                        })
                      }

                      if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){
                        priceDataObj[articleCode][`retailer_margin_per`] = currpendingprodobj[key][`retailer_margin_per`];

                        ALL_REGIONS_LIST.forEach((region) =>{
                          if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                            priceDataObj[articleCode][`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                          }
                        })
                      }
                      let show_mat = priceDataObj[articleCode].material_cost > 0 ? true: false;
                      let show_was = priceDataObj[articleCode].wastage > 0 ? true : false;

                      priceDataObj[articleCode]['source_type'] = currpendingprodobj[key]["source_type"];
                      priceDataObj[articleCode]['mill_transport_value'] = currpendingprodobj[key]["mill_transport_value"];
                      priceDataObj[articleCode]['transport'] = currpendingprodobj[key]["transport"];
                      priceDataObj[articleCode]['apmc_loading'] = currpendingprodobj[key]["apmc_loading"];

                      let updatedProdPriceObj = dealerPriceCalculations(
                        priceDataObj[articleCode],
                        productDataObj[articleCode],
                        show_mat,
                        show_was,
                        newBasePrice
                      );
                      let childprodele = {};
                      // childprodele["dealer_price"] = currpendingprodobj[key]["dealer_price"];
                      childprodele["dealer_price"] = updatedProdPriceObj["dealer_price"];
                      childprodele["base_price"] = newBasePrice;
                      childprodele["apmc_charge"] = "" + updatedProdPriceObj["apmc_charge"];
                      childprodele["gst_charge"] = getRoundNoThree(
                        updatedProdPriceObj["gst_charge"]
                      );
                      childprodele["transport_charge"] = currpendingprodobj[key]["transport_charge"]; //parseToNo(productDataObj[articleCode].transport_charge);
                      childprodele["article_code"] = articleCode;
                      childprodele["old_base_price"] = "" + oldBasePrice;
                      childprodele["old_dealer_price"] = productDataObj[articleCode].dealer_price;
                      childprodele["type"] = "child";
                      childprodele["parent_id"] = actualParentItem;
                      if(currpendingprodobj[key]['updated_from'] == 'Edit Product Details') {
                        childprodele["parent_id"] = parent_id;  
                      }
                      childprodele["newSource"] = parentele.newSource; //priceDataObj[articleCode].source_type;
                      childprodele["newTransport"] = parentele.newTransport;
                      childprodele["newCD"] = currpendingprodobj[key]?.cd_per ? currpendingprodobj[key]?.cd_per : priceDataObj[articleCode].cd_per;
                      childprodele["newExtraMargin"] = priceDataObj[articleCode].extra_margin_per;
                      if(currpendingprodobj[key]['updated_from'] && currpendingprodobj[key]['updated_from'] == 'Price Update') {
                        childprodele["newExtraMargin"] = currpendingprodobj[key]['extra_margin_per'];
                      }
                      childprodele[`retailer_margin_per`] = priceDataObj[articleCode][`retailer_margin_per`];
                      ALL_REGIONS_LIST.forEach((region) =>{
                        if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                          childprodele[`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                        }

                        if(currpendingprodobj[key][`dealer_price_${region}`]) {
                          childprodele[`dealer_price_${region}`] = currpendingprodobj[key][`dealer_price_${region}`];
                        }

                        if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                          childprodele[`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                        }
                      });     
                      childprodele["purchase_price"] = newBasePrice;
                      childprodele["newDealerPrice"] =
                        updatedProdPriceObj["dealer_price"];
                      childprodele["newgstcharge"] = getRoundNoThree(
                        updatedProdPriceObj["gst_charge"]
                      );
                      childprodele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
                      childprodele["newtransportcharge"] = parentele.newtransportcharge //parseToNo(productDataObj[articleCode].transport_charge);
                      
                      childprodele["rowselectkey"] = key;
                      childprodele["newTotalPrice"] = updatedProdPriceObj.total_price;
                      childprodele["gst_per"] = productDataObj[articleCode].gst_per ? (productDataObj[articleCode].gst_per).replace('%', '') :'0'//parentele.gst_per;
                      childprodele["calcObj"] = updatedProdPriceObj;
                      childprodele["productdata"] = productDataObj[articleCode];
                      childprodele["updated_at"] = currpendingprodobj[key]["updated_at"];
                      childprodele["table_update_date"] = loopCheckPrevDate;
                      childprodele["item_name"] = `${productDataObj[articleCode].brand}-${productDataObj[articleCode].long_description}`;
                      childprodele["updated_from"] = currpendingprodobj[key]["updated_from"];
                      childprodele[`selling_price`] = currpendingprodobj[key][`selling_price`];
                      childprodele[`selling_price_ex_gst`] = currpendingprodobj[key][`selling_price_ex_gst`];
                      if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){ 
                        childprodele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                        childprodele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                        childprodele[`conversion_per`] = currpendingprodobj[key]['conversion_per'];
                        childprodele[`conversion`] = currpendingprodobj[key]['conversion'];
                        childprodele[`wastage_per`] = currpendingprodobj[key]['wastage_per'];
                        childprodele[`wastage`] = currpendingprodobj[key]['wastage'];
                        childprodele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                      }
                      if(currpendingprodobj[key]['updated_from'] == 'Price Update') {
                        childprodele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                        childprodele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                      }
                      
                      childprodele[`mrp`] = currpendingprodobj[key][`market_price`];
                      childprodele[`gst_charge`] = currpendingprodobj[key][`gst_charge`];   
                      childprodele['pending_price_updated_at'] = currpendingprodobj[key]['updated_at'];

                      if(currpendingprodobj[key]['updated_from'] == 'Edit Product Details') {
                        childprodele["productapproved"] = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"]: "";
                      }
                      finalProductsList.push(childprodele);
                    }
                  });
                }
              }
            }
          }
        }
        const uniqueData = {};

        // Iterate through the data array
        for (const item of finalProductsList) {
          const { article_code, pending_price_updated_at } = item;

          if (article_code in uniqueData) {
            if ((pending_price_updated_at >= uniqueData[article_code].pending_price_updated_at)) {
              uniqueData[article_code] = item;
            }
          } else {
            uniqueData[article_code] = item;
          }
        }
        const latestDateRecords = Object.values(uniqueData);

        console.log(latestDateRecords);
        let updatedLatestDateRecords = latestDateRecords.filter((val) => {
          return val.productapproved && val.productapproved == 'N'
           }
          )
        setPriceAnalysisListForUI(updatedLatestDateRecords);
      });
  },[])

  useEffect(() => {
    let finalDateList = [];
    let today = new Date();
    let finalTodayDate = formatDate(new Date(today));
    console.log(finalTodayDate);

    let todayYear = finalTodayDate.split("-")[2];
    let todayMonth = finalTodayDate.split("-")[1];

    finalDateList.push(finalTodayDate);

    for (let dateval = 1; dateval <= 3; dateval++) {
      let prevDate = new Date(today);
      prevDate.setDate(prevDate.getDate() - dateval);
      let finalPrevDate = formatDate(new Date(prevDate));
      finalDateList.push(finalPrevDate);
    }
    console.log(finalDateList);

    let startDate = finalDateList[finalDateList.length - 1];
    let startDateList = startDate.split("-");
    let start_date =
      startDateList[2] + "-" + startDateList[1] + "-" + startDateList[0];

    let endDate = finalDateList[0];
    let endDateList = endDate.split("-");
    let end_date = endDateList[2] + "-" + endDateList[1] + "-" + endDateList[0];

    let getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?grn_start_date=${start_date}&grn_end_date=${end_date}&req_from=PriceUpdate`;
    console.log(getUrl);

    Promise.all([
      baseUrl.get(getUrl).then((response) => {
        return response.data;
      }),
      database.database().ref(`Product_Master_New/A/`).once("value"),
      database.database().ref(`Price_Details/mumbai/items`).once("value"),
      database.database().ref(`Pending_Price_Update/`).once("value"),
    ]).then(
      ([purchasedetailslist, productsnap, pricesnap, pendingprodsnap]) => {
        console.log(purchasedetailslist);

        let dealerpriceobj = productsnap.val();
        let productpriceobj = pricesnap.val();
        let pendingproductobj = pendingprodsnap.val();
        // console.log(dealerpriceobj);
        // console.log(productpriceobj);
        console.log(pendingproductobj);
        let finalPurchaseListData = [];

        const uniqueArticleCodes = Object.values(
          purchasedetailslist.reduce((acc, item) => {
            // If the article_code already exists, compare the created_at value
            if (acc[item.article_code]) {
              if (new Date(item.created_at) > new Date(acc[item.article_code].created_at)) {
                acc[item.article_code] = item; // Replace with the latest item
              }
            } else {
              acc[item.article_code] = item; // Add the item if article_code doesn't exist yet
            }
            return acc;
          }, {})
        );

        purchasedetailslist = uniqueArticleCodes;
        
        for (let purval = 0; purval < purchasedetailslist.length; purval++) {
          
          let purchaseDate = purchasedetailslist[purval].purchased_date;
          let itemid = purchasedetailslist[purval]["article_code"];

          let ele = purchasedetailslist[purval];
          let basePrice = dealerpriceobj[itemid]["base_price"]?parseFloat(dealerpriceobj[itemid]["base_price"]).toFixed(2):purchasedetailslist[purval]["base_price"]? parseFloat(dealerpriceobj[itemid]["base_price"]).toFixed(2): "";
          ele["base_price"] = basePrice;
          let purchasePrice = Number(purchasedetailslist[purval]["purchase_price"]).toFixed(2);
          ele["purchase_price"] = purchasePrice;
          if (basePrice != purchasePrice) {
            //if (basePrice != purchasePrice || productpriceobj[purchasedetailslist[purval]["article_code"]].source_type.toLowerCase() != purchasedetailslist[purval].source_type.toLowerCase() || productpriceobj[purchasedetailslist[purval]["article_code"]].mill_transport.toLowerCase() != purchasedetailslist[purval].transport.toLowerCase()) {
            let unique_key = purchasedetailslist[purval]["unique_key"];
            let unique_key_list = unique_key.split("-");
            let key = unique_key_list[3] + "-" + unique_key_list[4];
            ele["rowselectkey"] = key;

            //dealer price
            if (dealerpriceobj[itemid]) {
              ele["dealer_price"] = dealerpriceobj[itemid]["dealer_price"];
            }
            //apmc,gst,other prices
            if (productpriceobj[itemid]) {
              ele["apmc_loading"] = productpriceobj[itemid]["apmc_loading"];
              ele["apmc_per"] = productpriceobj[itemid]["apmc_per"];
              ele["conversion_per"] = productpriceobj[itemid]["conversion_per"];
              ele["extra_margin_per"] = productpriceobj[itemid]["extra_margin_per"];
              ele["gst_per"] = productpriceobj[itemid]["gst_per"] ? (productpriceobj[itemid]["gst_per"]).replace('%', '') :'';
              ele["lot_size"] = productpriceobj[itemid]["lot_size"];
              ele["material_cost"] = productpriceobj[itemid]["material_cost"];
              ele["sz_margin"] = productpriceobj[itemid]["sz_margin"];
              ele["wastage"] = productpriceobj[itemid]["wastage"];
            }
            
            //Number(purchasedetailslist[p]["purchase_price"]);
            if (dealerpriceobj[itemid] && productpriceobj[itemid]) {
              let sourceType = '';
              if(purchasedetailslist[purval]["source_type"] == ('MILL' || 'Mill' || 'mill') ){
                sourceType = 'mill';
              } else if (purchasedetailslist[purval]["source_type"] == ('LOCAL PICK' || 'Local Pick') ) {
                sourceType = 'local_pick';
              } else if (purchasedetailslist[purval]["source_type"] == ('DC DELIVERED' || 'DC Delivered')) {
                sourceType = 'dc_delivered';
              }
              // productpriceobj[itemid]['cd_per'] = Number(purchasedetailslist[p]["cash_discount"]) ?Number(purchasedetailslist[p]["cash_discount"]): productpriceobj[itemid]['cd_per']!='undefined' ? productpriceobj[itemid]['cd_per'] : 1;
              productpriceobj[itemid]['cd_per'] = Number(purchasedetailslist[purval]["cash_discount"]) ?Number(purchasedetailslist[purval]["cash_discount"]): productpriceobj[itemid]['cd_per'];
              productpriceobj[itemid]['source_type'] = purchasedetailslist[purval]["source_type"] ? sourceType: productpriceobj[itemid]['source_type'];
              productpriceobj[itemid]['transport'] = purchasedetailslist[purval]["transport"] ?purchasedetailslist[purval]["transport"]: productpriceobj[itemid]['transport'];
              productpriceobj[itemid]['mill_transport_value'] = Number(purchasedetailslist[purval]["transport_charges"]) ?Number(purchasedetailslist[purval]["transport_charges"]): productpriceobj[itemid]['mill_transport_value'];

              let show_mat = productpriceobj[itemid].material_cost > 0 ? true: false;
              let show_was = productpriceobj[itemid].wastage > 0 ? true : false;
              let updatedProdPriceObj = dealerPriceCalculations(
                productpriceobj[itemid],
                dealerpriceobj[itemid],
                show_mat,
                show_was,
                purchasePrice
              );
              ele["pricedata"] = productpriceobj[itemid];
              ele["productdata"] = dealerpriceobj[itemid];
              ele["calcObj"] = updatedProdPriceObj;
              ele["newDealerPrice"] = updatedProdPriceObj["dealer_price"];
              ele["newgstcharge"] = getRoundNoThree(
                updatedProdPriceObj["gst_charge"]
              );
              ele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
              let calcTransportCharge = parseToNo(
                dealerpriceobj[itemid].transport_charge
              );
              ele["newtransportcharge"] = calcTransportCharge;
              ele["gross_cost"] = updatedProdPriceObj.gross_cost;
              ele["landing_cost"] = updatedProdPriceObj.landing_cost;
            }

            for (let datelistval = finalDateList.length - 1; datelistval >= 0; datelistval--) {
              let loopCheckPrevDate = finalDateList[datelistval];
              let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
              let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

              if (pendingproductobj[loopCheckPrevYear]) {
                if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                  if (
                    pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                      loopCheckPrevDate
                    ]
                  ) {
                    let currpendingprodobj =
                      pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                        loopCheckPrevDate
                      ];
                      // for (const item of currpendingprodobj) {
                      //   const { item_id, updated_at } = item;
                
                      //   if (item_id in uniqueData) {
                      //     if ((updated_at >= uniqueData[item_id].updated_at)) {
                      //       uniqueData[item_id] = item;
                      //     }
                      //   } else {
                      //     uniqueData[item_id] = item;
                      //   }
                      // }
                      // const uniqueData = {};
                      // const uniqueDataOb = {};
                      // for (const [key, value] of Object.entries(currpendingprodobj)) {
                      //   let item_id = value.item_id;
                      //   let updated_at = value.updated_at;
                      //   //if(item_id =='1000910043') {
                      //    if(item_id in uniqueData){
                      //     if(value.approved != 'Y' && updated_at > uniqueData[item_id].updated_at){
                      //       uniqueData[item_id] = value;
                      //       uniqueDataOb[key] = value; 
                      //     }
                      //    }else {
                      //     uniqueData[item_id] = value;
                      //     uniqueDataOb[key] = value;
                      //    }
                      //   //}
                      // }  
                    if (currpendingprodobj[key]) {
                      ele["pendingproductdetails"] = currpendingprodobj[key];
                      ele["productapproved"] = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"]: "";
                      ele["table_update_date"] = loopCheckPrevDate;
                    }
                  }
                }
              }
            }

            finalPurchaseListData.push(ele);
          }
         }

         finalPurchaseListData.sort(function (a, b) {
          var keyA = a.created_at,
            keyB = b.created_at;
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });

        let updatedFinalPurchaseListData = [];
        let itemAddedSet = new Set();
        for (let i = 0; i < finalPurchaseListData.length; i++) {
          let itemele = finalPurchaseListData[i];
          let item_id = finalPurchaseListData[i]["article_code"];

          if (!itemAddedSet.has(item_id)) {
            itemAddedSet.add(item_id);
            if (itemele["productapproved"] != "Y") {
              updatedFinalPurchaseListData.push(itemele);
            }
          }
        }
        setLoading(false);
        setProductDataObj(dealerpriceobj);
        setPriceDataObj(productpriceobj);
        setPendingProductsObj(pendingproductobj);
        setPriceAnalysisList(updatedFinalPurchaseListData);
        setSearchPriceAnalysisList(updatedFinalPurchaseListData);
        setTodayPriceAnalysisList(updatedFinalPurchaseListData);
        setPurchaseDetailsList(purchasedetailslist);
      }
    );
  }, []);

  useEffect(() => {
    let finalDateList = [];
    let purchaseDateSet = new Set();
    let today = new Date();
    let finalTodayDate = formatDate(new Date(today));

    let todayYear = finalTodayDate.split("-")[2];
    let todayMonth = finalTodayDate.split("-")[1];

    finalDateList.push(finalTodayDate);
    purchaseDateSet.add(originalFormatDate(new Date()));

    let numDays = lastNumDaysText != "Today" ? Number(lastNumDaysText) : 0;

    for (let datevallist = 1; datevallist <= numDays; datevallist++) {
      let prevDate = new Date(today);
      prevDate.setDate(prevDate.getDate() - datevallist);
      let finalPrevDate = formatDate(new Date(prevDate));
      purchaseDateSet.add(originalFormatDate(new Date(prevDate)));
      finalDateList.push(finalPrevDate);
    }


    let dealerpriceobj = productDataObj;
    let productpriceobj = priceDataObj;
    let pendingproductobj = pendingProductsObj;

    if (onlyLatestChanges == true) {
      purchaseDetailsList.sort(function (a, b) {
        var keyA = a.created_at,
          keyB = b.created_at;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    }

    let finalPurchaseListData = [];
    let itemCheckedSet = new Set();

    for (let purdetval = 0; purdetval < purchaseDetailsList.length; purdetval++) {
      let purchaseDate = purchaseDetailsList[purdetval].purchased_date;
      if (purchaseDateSet.has(purchaseDate)) {
        let ele = purchaseDetailsList[purdetval];
        let itemid = purchaseDetailsList[purdetval]["article_code"];
        // let basePrice = dealerpriceobj[itemid]? Number(dealerpriceobj[itemid]["base_price"]): "";
        let basePrice = dealerpriceobj[itemid]["base_price"]?parseFloat(dealerpriceobj[itemid]["base_price"]).toFixed(2):purchaseDetailsList[purdetval]["base_price"]? parseFloat(purchaseDetailsList[purdetval]["base_price"]).toFixed(2): "";
        ele["base_price"] = basePrice;
        let purchasePrice = Number(purchaseDetailsList[purdetval]["purchase_price"]).toFixed(2);
        ele["purchase_price"] = purchasePrice;
        if (((!itemCheckedSet.has(itemid) && onlyLatestChanges == true) 
              && basePrice != purchasePrice) 
              || onlyLatestChanges == false) {
          let unique_key = purchaseDetailsList[purdetval]["unique_key"];
          let unique_key_list = unique_key.split("-");
          let key = unique_key_list[3] + "-" + unique_key_list[4];
          ele["rowselectkey"] = key;

          //dealer price
          if (dealerpriceobj[itemid]) {
            ele["dealer_price"] = dealerpriceobj[itemid]["dealer_price"];
          }
          //apmc,gst,other prices
          if (productpriceobj[itemid]) {
            ele["apmc_loading"] = productpriceobj[itemid]["apmc_loading"];
            ele["apmc_per"] = productpriceobj[itemid]["apmc_per"];
            ele["conversion_per"] = productpriceobj[itemid]["conversion_per"];
            ele["extra_margin_per"] =
              productpriceobj[itemid]["extra_margin_per"];
            // ele["gst_per"] = productpriceobj[itemid]["gst_per"];
            ele["gst_per"] = productpriceobj[itemid]["gst_per"] ? (productpriceobj[itemid]["gst_per"]).replace('%', '') :'';
            ele["lot_size"] = productpriceobj[itemid]["lot_size"];
            ele["material_cost"] = productpriceobj[itemid]["material_cost"];
            ele["sz_margin"] = productpriceobj[itemid]["sz_margin"];
            ele["wastage"] = productpriceobj[itemid]["wastage"];
          }

          if (dealerpriceobj[itemid] && productpriceobj[itemid]) {
            let show_mat = productpriceobj[itemid].material_cost > 0 ? true: false;
            let show_was = productpriceobj[itemid].wastage > 0 ? true : false;
            let updatedProdPriceObj = dealerPriceCalculations(
              productpriceobj[itemid],
              dealerpriceobj[itemid],
              show_mat,
              show_was,
              purchasePrice
            );
            ele["pricedata"] = productpriceobj[itemid];
            ele["productdata"] = dealerpriceobj[itemid];
            ele["calcObj"] = updatedProdPriceObj;
            ele["newDealerPrice"] = updatedProdPriceObj["dealer_price"];
            ele["newgstcharge"] = getRoundNoThree(
              updatedProdPriceObj["gst_charge"]
            );
            ele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
            let calcTransportCharge = parseToNo(
              dealerpriceobj[itemid].transport_charge
            );
            ele["newtransportcharge"] = calcTransportCharge;
          }

          for (let fdateval = finalDateList.length - 1; fdateval >= 0; fdateval--) {
            let loopCheckPrevDate = finalDateList[fdateval];
            let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
            let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

            if (pendingproductobj[loopCheckPrevYear]) {
              if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                if (
                  pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                    loopCheckPrevDate
                  ]
                ) {
                  let currpendingprodobj =
                    pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                      loopCheckPrevDate
                    ];
                  if (currpendingprodobj[key]) {
                    ele["pendingproductdetails"] = currpendingprodobj[key];
                    ele["productapproved"] = currpendingprodobj[key]["approved"]
                      ? currpendingprodobj[key]["approved"]
                      : "";
                    ele["table_update_date"] = loopCheckPrevDate;
                  }
                }
              }
            }
          }

          if (updatedProducts == true) {
            if (ele["productapproved"] == "Y") {
              finalPurchaseListData.push(ele);
            }
            itemCheckedSet.add(itemid);
          } else {
            itemCheckedSet.add(itemid);
            // if (ele["productapproved"] != "Y") {
              finalPurchaseListData.push(ele);
            //}
          }
        }
      }
    }
    setLoading(false);
    setPriceAnalysisList(finalPurchaseListData);
    setSearchPriceAnalysisList(finalPurchaseListData);
  }, [updatedProducts, onlyLatestChanges]);

  //last X days data fetching
  useEffect(() => {
    let finalDateList = [];
    let today = new Date();
    let finalTodayDate = formatDate(new Date(today));

    let todayYear = finalTodayDate.split("-")[2];
    let todayMonth = finalTodayDate.split("-")[1];

    finalDateList.push(finalTodayDate);

    setLoading(true);
    setPriceAnalysisList([]);
    setSearchPriceAnalysisList([]);
    let numDays = lastNumDaysText != "Today" ? Number(lastNumDaysText) : 0;

    for (let dayval = 1; dayval <= numDays; dayval++) {
      let prevDate = new Date(today);
      prevDate.setDate(prevDate.getDate() - dayval);
      let finalPrevDate = formatDate(new Date(prevDate));
      finalDateList.push(finalPrevDate);
    }


    let startDate = finalDateList[finalDateList.length - 1];
    let startDateList = startDate.split("-");
    let start_date =
      startDateList[2] + "-" + startDateList[1] + "-" + startDateList[0];

    let endDate = finalDateList[0];
    let endDateList = endDate.split("-");
    let end_date = endDateList[2] + "-" + endDateList[1] + "-" + endDateList[0];

    let getUrl = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?grn_start_date=${start_date}&grn_end_date=${end_date}&req_from=PriceUpdate`;

    Promise.all([
      baseUrl.get(getUrl).then((response) => {
        return response.data;
      }),
      database.database().ref(`Pending_Price_Update/`).once("value"),
    ]).then(([purchasedetailslist, pendingprodsnap]) => {
      let dealerpriceobj = productDataObj;
      let productpriceobj = priceDataObj;
      let pendingproductobj = pendingprodsnap.val();
      let finalPurchaseListData = [];

      for (let purdetal = 0; purdetal < purchasedetailslist.length; purdetal++) {
        let purchaseDate = purchasedetailslist[purdetal].purchased_date;

        let ele = purchasedetailslist[purdetal];
        let itemid = purchasedetailslist[purdetal]["article_code"];
        // let basePrice = dealerpriceobj[itemid]? Number(dealerpriceobj[itemid]["base_price"]): "";
        let basePrice = dealerpriceobj[itemid]? Number(dealerpriceobj[itemid]["base_price"]).toFixed(2) :purchasedetailslist[purdetal]["base_price"]? Number(purchasedetailslist[purdetal]["base_price"]).toFixed(2): "";
        ele["base_price"] = basePrice;
        let purchasePrice = Number(purchasedetailslist[purdetal]["purchase_price"]).toFixed(2);
        ele["purchase_price"] = purchasePrice;
        if (basePrice != purchasePrice) {
          let unique_key = purchasedetailslist[purdetal]["unique_key"];
          let unique_key_list = unique_key.split("-");
          let key = unique_key_list[3] + "-" + unique_key_list[4];
          ele["rowselectkey"] = key;

          //dealer price
          if (dealerpriceobj[itemid]) {
            ele["dealer_price"] = dealerpriceobj[itemid]["dealer_price"];
          }
          //apmc,gst,other prices
          if (productpriceobj[itemid]) {
            ele["apmc_loading"] = productpriceobj[itemid]["apmc_loading"];
            ele["apmc_per"] = productpriceobj[itemid]["apmc_per"];
            ele["conversion_per"] = productpriceobj[itemid]["conversion_per"];
            ele["extra_margin_per"] =
              productpriceobj[itemid]["extra_margin_per"];
            // ele["gst_per"] = productpriceobj[itemid]["gst_per"];
            ele["gst_per"] = productpriceobj[itemid]["gst_per"] ? (productpriceobj[itemid]["gst_per"]).replace('%', '') :'';
            ele["lot_size"] = productpriceobj[itemid]["lot_size"];
            ele["material_cost"] = productpriceobj[itemid]["material_cost"];
            ele["sz_margin"] = productpriceobj[itemid]["sz_margin"];
            ele["wastage"] = productpriceobj[itemid]["wastage"];
          }

          if (dealerpriceobj[itemid] && productpriceobj[itemid]) {
            let show_mat = productpriceobj[itemid].material_cost > 0 ? true: false;
            let show_was = productpriceobj[itemid].wastage > 0 ? true : false;
            let updatedProdPriceObj = dealerPriceCalculations(
              productpriceobj[itemid],
              dealerpriceobj[itemid],
              show_mat,
              show_was,
              purchasePrice
            );
            ele["pricedata"] = productpriceobj[itemid];
            ele["productdata"] = dealerpriceobj[itemid];
            ele["calcObj"] = updatedProdPriceObj;
            ele["newDealerPrice"] = updatedProdPriceObj["dealer_price"];
            ele["newgstcharge"] = getRoundNoThree(
              updatedProdPriceObj["gst_charge"]
            );
            ele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
            let calcTransportCharge = parseToNo(
              dealerpriceobj[itemid].transport_charge
            );
            ele["newtransportcharge"] = calcTransportCharge;
          }

          for (let finalval = finalDateList.length - 1; finalval >= 0; finalval--) {
            let loopCheckPrevDate = finalDateList[finalval];
            let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
            let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

            if (pendingproductobj[loopCheckPrevYear]) {
              if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                if (
                  pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                    loopCheckPrevDate
                  ]
                ) {
                  let currpendingprodobj =
                    pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                      loopCheckPrevDate
                    ];
                  if (currpendingprodobj[key]) {
                    ele["pendingproductdetails"] = currpendingprodobj[key];
                    ele["productapproved"] = currpendingprodobj[key]["approved"]
                      ? currpendingprodobj[key]["approved"]
                      : "";
                    ele["table_update_date"] = loopCheckPrevDate;
                  }
                }
              }
            }
          }

          finalPurchaseListData.push(ele);
        }
      }


      finalPurchaseListData.sort(function (a, b) {
        var keyA = a.created_at,
          keyB = b.created_at;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      let updatedFinalPurchaseListData = [];
      let itemAddedSet = new Set();
      for (let purdata = 0; purdata < finalPurchaseListData.length; purdata++) {
        let itemele = finalPurchaseListData[purdata];
        let item_id = finalPurchaseListData[purdata]["article_code"];

        if (!itemAddedSet.has(item_id)) {
          itemAddedSet.add(item_id);
          if (itemele["productapproved"] != "Y") {
            updatedFinalPurchaseListData.push(itemele);
          }
        }
      }

      setLoading(false);
      setPriceAnalysisList(updatedFinalPurchaseListData);
      setSearchPriceAnalysisList(updatedFinalPurchaseListData);
    });
  }, [lastNumDaysText]);

  const calculateGST = (
    gst_abs_per,
    region_name,
    total_price,
    calcObj,
    prodData
  ) => {
    const gstCharges =
      gst_abs_per *
      (parseFloat(total_price) +
        parseFloat(calcObj.apmc_charge) +
        (parseFloat(prodData.transport_charge) / TRANSPORT_FACTOR["A"]) *
          TRANSPORT_FACTOR[region_name]);
    return gstCharges.toFixed(3);
  };

  useEffect(() => {
    let pendingProdList = [];
    for (let priceval = 0; priceval < priceAnalysisList.length; priceval++) {
      if (priceAnalysisList[priceval]["productapproved"]) {
        if (priceAnalysisList[priceval]["productapproved"] == "N") {
          pendingProdList.push(priceAnalysisList[priceval]);
        }
      }
    }
    setPendingApprovalProducts(pendingProdList);
  }, [priceAnalysisList]);

  useEffect(() => {
    if (searchText) {
      filterProductPriceDetails();
    } else {
      setPriceAnalysisList(searchPriceAnalysisList);
    }
  }, [searchText]);

  const filterProductPriceDetails = () => {

    let checksearchtext = searchText.trim().toLowerCase();
    let filteredData = [];
    for (let searchval = 0; searchval < searchPriceAnalysisList.length; searchval++) {
      let pushflag = false;
      let itemname = searchPriceAnalysisList[searchval]["item_name"];
      let itemid = searchPriceAnalysisList[searchval]["article_code"];
      let category = searchPriceAnalysisList[searchval]["category"];

      if (itemname) {
        itemname = itemname.trim().toLowerCase();
        if (itemname.includes(checksearchtext)) {
          pushflag = true;
          filteredData.push(searchPriceAnalysisList[searchval]);
        }
      }

      if (itemid && pushflag == false) {
        itemid = itemid.trim().toLowerCase();
        if (itemid.includes(checksearchtext)) {
          filteredData.push(searchPriceAnalysisList[searchval]);
          pushflag = true;
        }
      }

      if (category && pushflag == false) {
        category = category.trim().toLowerCase();
        if (category.includes(checksearchtext)) {
          filteredData.push(searchPriceAnalysisList[searchval]);
          pushflag = true;
        }
      }
    }
    setPriceAnalysisList(filteredData);
  };

  useEffect(() => {
    if (priceChangeText) {
      filterProductsByPriceChange();
    } else {
      setPriceAnalysisList(searchPriceAnalysisList);
    }
  }, [priceChangeText]);

  const filterProductsByPriceChange = () => {
    let filteredData = [];
    if (priceChangeText == "All") {
      setPriceAnalysisList(searchPriceAnalysisList);
    } else {
      for (let searchpriceval = 0; searchpriceval < searchPriceAnalysisList.length; searchpriceval++) {
        let purchasePrice = Number(
          searchPriceAnalysisList[searchpriceval]["purchase_price"]
        );
        let basePrice = Number(searchPriceAnalysisList[searchpriceval]["base_price"]);

        if (priceChangeText == "Increased") {
          if (purchasePrice > basePrice) {
            filteredData.push(searchPriceAnalysisList[searchpriceval]);
          }
        } else if (priceChangeText == "Decreased") {
          if (purchasePrice < basePrice) {
            filteredData.push(searchPriceAnalysisList[searchpriceval]);
          }
        } else if (priceChangeText == "No Change") {
          if (purchasePrice == basePrice) {
            filteredData.push(searchPriceAnalysisList[searchpriceval]);
          }
        }
      }
      setPriceAnalysisList(filteredData);
    }
  };

  const dealerPriceCalculations = (
    priceFromDB,
    prodData,
    showMaterial,
    showWastage,
    updatedBasePrice
  ) => {
    if(updatedBasePrice) {
      priceFromDB['base_price'] = updatedBasePrice;
      // prodData['base_price'] = updatedBasePrice;
    }
    if(priceFromDB.transport) {
      priceFromDB.mill_transport = priceFromDB.transport.toLowerCase();
    }
    
    if(priceFromDB.source_type == 'LOCAL PICK'){
      priceFromDB.source_type = 'local_pick';
    }
    let resObj = PriceCalculations(priceFromDB, prodData, showMaterial, showWastage);
    return resObj;
  };

  const isValid = (data) => {
    if (
      data === undefined ||
      data === null ||
      data === "" ||
      data.length === 0
    ) {
      return 0;
    }
    return data;
  };

  const parseToNo = (string) => {
    return parseFloat(isValid(string));
  };

  const getAbsFromPer = (data) => {
    return parseFloat(parseToNo(data) / 100);
  };

  const getRoundNo = (data) => {
    return parseToNo(data).toFixed(2);
  };

  const getRoundNoThree = (data) => {
    return parseToNo(data).toFixed(3);
  };

  const bulkUpdateDealerPrice = () => {
    let finalDateList = [];
    let today = new Date();
    let finalTodayDate = formatDate(new Date(today));
    finalDateList.push(finalTodayDate);

    for (let datevall = 1; datevall <= 3; datevall++) {
      let prevDate = new Date(today);
      prevDate.setDate(prevDate.getDate() - datevall);
      let finalPrevDate = formatDate(new Date(prevDate));
      finalDateList.push(finalPrevDate);
    }

    database
      .database()
      .ref(`Pending_Price_Update`)
      .once("value")
      .then((snapshot) => {
        let pendingproductobj = snapshot.val();
        let pendingProdList = [];
        //for (let i = 0; i < priceAnalysisList.length; i++) {
          //if (priceAnalysisList[i]["productapproved"]) {
            //if (priceAnalysisList[i]["productapproved"] == "N") {
              // let ele = priceAnalysisList[i];
              // let itemid = priceAnalysisList[i]["rowselectkey"];
              let itemPriceCheck = false;
              
              let innerEl = {};
              for (let fdate = finalDateList.length - 1; fdate >= 0; fdate--) {
                let loopCheckPrevDate = finalDateList[fdate];
                let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
                let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

                if (pendingproductobj[loopCheckPrevYear]) {
                  if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                    if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][loopCheckPrevDate]) {
                      let currpendingprodobj =pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][loopCheckPrevDate];

                      const uniqueData = {};
                      const uniqueDataOb = {};
                      for (const [key, value] of Object.entries(currpendingprodobj)) {
                        let item_id = value.item_id;
                        let updated_at = value.updated_at;
                        //if(item_id =='1000910043') {
                         if(item_id in uniqueData){
                          if(value.approved != 'Y' && updated_at > uniqueData[item_id].updated_at){
                            uniqueData[item_id] = value;
                            uniqueDataOb[key] = value; 
                          }
                          // else {
                          //   uniqueData[item_id] = [];
                          //   for (const [innerKey, innerVal] of Object.entries(uniqueDataOb)) {
                          //     if (innerVal['item_id'] == item_id ) {
                          //       uniqueDataOb[innerKey] = [];
                          //     }
                          //   }
                          // }
                         }else {
                          uniqueData[item_id] = value;
                          uniqueDataOb[key] = value;
                         }
                        //}
                      }
                      //})
                      console.log(uniqueData);
                      console.log(uniqueDataOb);
                      console.log(currpendingprodobj);
                      currpendingprodobj = uniqueDataOb;
                      //if (currpendingprodobj[itemid]) {
                        Object.keys(currpendingprodobj).map((key, index) => {
                            let ele = {};
                            let parent_id = currpendingprodobj[key]["parent_id"] ? currpendingprodobj[key]["parent_id"] : "";
                            if(currpendingprodobj[key]['approved'] == 'N' && (parent_id == currpendingprodobj[key]["item_id"] || !parent_id ) || (currpendingprodobj[key].updated_from == 'Edit Product Details' && currpendingprodobj[key]['approved'] == 'N') ) {
                              let articleCode = currpendingprodobj[key]["item_id"];
                              let newBasePrice = currpendingprodobj[key]["base_price"];
                              if(currpendingprodobj[key]['updated_from'] == 'Edit Price'){
                                //priceFromDB[articleCode].extra_margin_per 
                                priceDataObj[articleCode][`extra_margin_per`] = currpendingprodobj[key][`extra_margin_per`];
                                priceDataObj[articleCode][`dealer_price`] = currpendingprodobj[key][`dealer_price`];
                                ALL_REGIONS_LIST.forEach((region) =>{
                                  if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                                    priceDataObj[articleCode][`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                                  }
  
                                  if(currpendingprodobj[key][`dealer_price_${region}`]) {
                                    priceDataObj[articleCode][`dealer_price_${region}`] = currpendingprodobj[key][`dealer_price_${region}`];
                                  }
                                })
                              }

                              if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){ 
                                priceDataObj[articleCode][`retailer_margin_per`] = currpendingprodobj[key][`retailer_margin_per`];
        
                                ALL_REGIONS_LIST.forEach((region) =>{
                                  if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                                    priceDataObj[articleCode][`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                                  }
                                })
                              }
                              let show_mat = priceDataObj[articleCode] && priceDataObj[articleCode].material_cost > 0 ? true: false;
                              let show_was = priceDataObj[articleCode] && priceDataObj[articleCode].wastage > 0 ? true : false;
                              let updatedProdPriceObj = dealerPriceCalculations(priceDataObj[articleCode],productDataObj[articleCode],show_mat,show_was,newBasePrice); 
                              ele["purchase_price"] = currpendingprodobj[key]["base_price"];
                              ele["newDealerPrice"] = currpendingprodobj[key]["dealer_price"];
                              ele[`primary_pm_cost`] = currpendingprodobj[key][`primary_pm_cost`];
                              ele[`package_material_cost`] = currpendingprodobj[key][`package_material_cost`];
                              ele["newgstcharge"] = currpendingprodobj[key]["gst_charge"];
                              ele["newapmccharge"] = "" + currpendingprodobj[key]["apmc_charge"];
                              // ele["newtransportcharge"] = currpendingprodobj[key]["transport_charge"];
                              ele["newtransportcharge"] = currpendingprodobj[key]["mill_transport_value"];
                              ele["table_update_date"] = loopCheckPrevDate;
                              itemPriceCheck = true;
                              ele["newSource"] = currpendingprodobj[key]["source_type"];
                              ele["newTransport"] = currpendingprodobj[key]["transport"] ? currpendingprodobj[key]["transport"] : currpendingprodobj[key]["mill_transport"];
                              // ele["newtransportcharge"] = currpendingprodobj[itemid].transport_charge;
                              ele["newCD"] = currpendingprodobj[key]["cd_per"];
                              ele["newExtraMargin"] = currpendingprodobj[key]["extra_margin_per"];
                              ele["newTotalPrice"] = currpendingprodobj[key]["total_price"];
                              ele["article_code"] = currpendingprodobj[key]["item_id"];
                              ele["rowselectkey"] = key;
                              ele["old_base_price"] = currpendingprodobj[key]["old_base_price"];
                              ele["item_name"] = `${productDataObj[currpendingprodobj[key]["item_id"]].brand}-${productDataObj[currpendingprodobj[key]["item_id"]].long_description}`;
                              // ele["dealer_price"] = updatedProdPriceObj["dealer_price"];
                              ele["dealer_price"] = currpendingprodobj[key]["dealer_price"];
                              ele["base_price"] = currpendingprodobj[key]["old_base_price"];
                              ele["apmc_charge"] = "" + updatedProdPriceObj["apmc_charge"];
                              ele["gst_charge"] = getRoundNoThree(updatedProdPriceObj["gst_charge"]);
                              ele["updated_from"] = currpendingprodobj[key]["updated_from"];
                              ele["calcObj"] = updatedProdPriceObj;
                              ele["productdata"] = productDataObj[articleCode];
                              ele[`retailer_margin_per`] = currpendingprodobj[key][`retailer_margin_per`];
                              ALL_REGIONS_LIST.forEach((region) =>{
                                if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                                  ele[`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                                }

                                if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                                  ele[`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                                }
                              })

                              ele[`selling_price`] = currpendingprodobj[key][`selling_price`];
                              ele[`selling_price_ex_gst`] = currpendingprodobj[key][`selling_price_ex_gst`];
                              if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){  
                                ele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                                ele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                                ele[`conversion_per`] = currpendingprodobj[key]['conversion_per'];
                                ele[`conversion`] = currpendingprodobj[key]['conversion'];
                                ele[`wastage_per`] = currpendingprodobj[key]['wastage_per'];
                                ele[`wastage`] = currpendingprodobj[key]['wastage'];
                                ele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                              }

                              if(currpendingprodobj[key]['updated_from'] == 'Price Update') {
                                ele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                                ele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                              }
                              if(currpendingprodobj[key]['updated_from'] == 'Edit Price') {
                                ele[`conversion_per`] = currpendingprodobj[key]['conversion_per'];
                                ele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                                ele[`package_material_cost`] = currpendingprodobj[key]['package_material_cost'];
                                ele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                                ele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                                // ele[`conversion`] = currpendingprodobj[key]['conversion'];
                                ele[`wastage_per`] = currpendingprodobj[key]['wastage_per'];
                                ele[`wastage`] = currpendingprodobj[key]['wastage'];
                                ele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                              }
                              ele[`mrp`] = currpendingprodobj[key][`market_price`];
                              ele[`gst_charge`] = currpendingprodobj[key][`gst_charge`];
                              ele['pending_price_updated_at'] = currpendingprodobj[key]['updated_at']
                              pendingProdList.push(ele);
                            }
                        })
                    }
                  }
                }
              }


        let finalProductsList = [];
        for (let penprod = 0; penprod < pendingProdList.length; penprod++) {
          let actualParentItem = pendingProdList[penprod]["article_code"];

          let parentele = pendingProdList[penprod];
          if(parentele.updated_from != 'Edit Product Details') {
            parentele.type = "parent";
            parentele.parent_id = actualParentItem;
            finalProductsList.push(parentele);
          }         

          //find child data
          for (let finaldate = finalDateList.length - 1; finaldate >= 0; finaldate--) {
            let loopCheckPrevDate = finalDateList[finaldate];
            let loopCheckPrevYear = loopCheckPrevDate.split("-")[2];
            let loopCheckPrevMonth = loopCheckPrevDate.split("-")[1];

            if (pendingproductobj[loopCheckPrevYear]) {
              if (pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth]) {
                if (
                  pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                    loopCheckPrevDate
                  ]
                ) {
                  let currpendingprodobj =
                    pendingproductobj[loopCheckPrevYear][loopCheckPrevMonth][
                      loopCheckPrevDate
                    ];
                  //find child item key
                  const articleCodeApprovals = {};

                  function parseDate(dateString) {
                    const [datePart, timePart] = dateString.split(' ');
                    const [day, month, year] = datePart.split('-');
                    return new Date(`${year}-${month}-${day}T${timePart}`);
                  }                  

                  Object.keys(currpendingprodobj).forEach((key) => {
                    const articleCode = currpendingprodobj[key]['item_id'];
                    const updatedAt = currpendingprodobj[key]['updated_at'];
                    const approvedStatus = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"] : "";
                    const currentUpdatedAt = parseDate(updatedAt);

                    // Only store the latest updated_at with approved as "Y"
                    // if (!articleCodeApprovals[articleCode] || new Date(updatedAt) > new Date(articleCodeApprovals[articleCode].updated_at)) {
                    //   articleCodeApprovals[articleCode] = { updated_at: updatedAt, approved: approvedStatus };
                    // }
                    if (!articleCodeApprovals[articleCode]) {
                      articleCodeApprovals[articleCode] = {};
                    }

                    const updatedFromType = currpendingprodobj[key].updated_from;
                    const currentApprovalData = articleCodeApprovals[articleCode][currpendingprodobj[key].updated_from];
                    if (!articleCodeApprovals[articleCode][updatedFromType]) {
                      articleCodeApprovals[articleCode][updatedFromType] = {
                          updated_at: updatedAt,
                          approved: approvedStatus
                      };
                    } else {
                        const existingApprovalData = articleCodeApprovals[articleCode][updatedFromType];
                        if (new Date(currentUpdatedAt) > new Date(parseDate(existingApprovalData.updated_at))) {
                            articleCodeApprovals[articleCode][updatedFromType] = {
                                updated_at: updatedAt,
                                approved: approvedStatus
                            };
                        }
                    }   
                  });

                  Object.keys(currpendingprodobj).map((key, index) => {
                    let parent_id = currpendingprodobj[key]["parent_id"] ? currpendingprodobj[key]["parent_id"] : "";
                    let articleCode = currpendingprodobj[key]["item_id"] ? currpendingprodobj[key]["item_id"] : "";
                    let oldBasePrice = currpendingprodobj[key]["old_base_price"];
                    let newBasePrice = currpendingprodobj[key]["base_price"];
                    let approvedProd = currpendingprodobj[key]["approved"] ? currpendingprodobj[key]["approved"] : "";
                    const updatedAt = currpendingprodobj[key]['updated_at'];                    

                    // if (!articleCodeApprovals[articleCode]) {
                    //   articleCodeApprovals[articleCode] = {};
                    // }

                    const updatedFromType = currpendingprodobj[key].updated_from;
                    // const currentApprovalData = articleCodeApprovals[articleCode][currpendingprodobj[key].updated_from];
                    // if (!articleCodeApprovals[articleCode][updatedFromType]) {
                    //   articleCodeApprovals[articleCode][updatedFromType] = {
                    //       updated_at: updatedAt,
                    //       approved: approvedProd
                    //   };
                    // } else {
                    //     const existingApprovalData = articleCodeApprovals[articleCode][updatedFromType];
                    //     if (new Date(currentUpdatedAt) > new Date(parseDate(existingApprovalData.updated_at))) {
                    //         articleCodeApprovals[articleCode][updatedFromType] = {
                    //             updated_at: updatedAt,
                    //             approved: approvedProd
                    //         };
                    //     }
                    // }            

                    // const latestApproval = articleCodeApprovals[articleCode];
                    const latestApproval = articleCodeApprovals[articleCode][updatedFromType];

                    if (latestApproval && latestApproval.updated_at) {
                      const latestApprovalDate = parseDate(latestApproval.updated_at);
                      const currentUpdatedAt = parseDate(updatedAt);
                      // if (latestApproval.approved === 'Y' && latestApprovalDate >= currentUpdatedAt) {
                      //   // Skip this iteration as the latest update for this article_code has been approved
                      //   return;
                      // }

                      switch (updatedFromType) {
                        case 'Edit Product Details':
                        case 'Edit Price':
                        case 'Edit Dealer Price':
                        case 'Price Update':
                            if (latestApproval.approved === 'Y' && latestApprovalDate >= currentUpdatedAt) {
                                // Skip this iteration as the latest update for this article_code has already been approved
                                return;
                            }
                            break;
            
                        default:
                            break;
                      }            
                    }



                    if (parent_id == actualParentItem && parent_id != articleCode && approvedProd == "N" || (currpendingprodobj[key].updated_from == 'Edit Product Details' && approvedProd == "N") ) {

                      if(currpendingprodobj[key]['updated_from'] == 'Edit Price'){
                        //priceFromDB[articleCode].extra_margin_per 
                        priceDataObj[articleCode][`extra_margin_per`] = currpendingprodobj[key][`extra_margin_per`];
                        priceDataObj[articleCode][`dealer_price`] = currpendingprodobj[key][`dealer_price`];

                        priceDataObj[articleCode][`primary_pm_cost`] = currpendingprodobj[key][`primary_pm_cost`];
                        priceDataObj[articleCode][`package_material_cost`] = currpendingprodobj[key][`package_material_cost`];
                        priceDataObj[articleCode][`conversion_per`] = currpendingprodobj[key][`conversion_per`];
                        
                        ALL_REGIONS_LIST.forEach((region) =>{
                          if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                            priceDataObj[articleCode][`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                          }

                          if(currpendingprodobj[key][`dealer_price_${region}`]) {
                            priceDataObj[articleCode][`dealer_price_${region}`] = currpendingprodobj[key][`dealer_price_${region}`];
                          }
                        })
                      }

                      if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){ 
                        priceDataObj[articleCode][`retailer_margin_per`] = currpendingprodobj[key][`retailer_margin_per`];

                        ALL_REGIONS_LIST.forEach((region) =>{
                          if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                            priceDataObj[articleCode][`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                          }
                        })
                      }
                      let show_mat = priceDataObj[articleCode].material_cost > 0 ? true: false;
                      let show_was = priceDataObj[articleCode].wastage > 0 ? true : false;

                      priceDataObj[articleCode]['source_type'] = currpendingprodobj[key]["source_type"];
                      priceDataObj[articleCode]['mill_transport_value'] = currpendingprodobj[key]["mill_transport_value"];
                      priceDataObj[articleCode]['transport'] = currpendingprodobj[key]["transport"];
                      priceDataObj[articleCode]['apmc_loading'] = currpendingprodobj[key]["apmc_loading"];
                      // priceDataObj[articleCode]['mrp'] = currpendingprodobj[key]["mrp"] ? currpendingprodobj[key]["mrp"] : '';

                      let updatedProdPriceObj = dealerPriceCalculations(
                        priceDataObj[articleCode],
                        productDataObj[articleCode],
                        show_mat,
                        show_was,
                        newBasePrice
                      );
                      let childprodele = {};
                      childprodele["dealer_price"] = currpendingprodobj[key]["dealer_price"];
                      childprodele["base_price"] = newBasePrice;
                      childprodele["apmc_charge"] = "" + updatedProdPriceObj["apmc_charge"];
                      childprodele["gst_charge"] = getRoundNoThree(
                        updatedProdPriceObj["gst_charge"]
                      );
                      childprodele["transport_charge"] = currpendingprodobj[key]["transport_charge"]; //parseToNo(productDataObj[articleCode].transport_charge);
                      childprodele["article_code"] = articleCode;
                      childprodele["old_base_price"] = "" + oldBasePrice;
                      childprodele["old_dealer_price"] = productDataObj[articleCode].dealer_price;
                      childprodele["type"] = "child";
                      childprodele["parent_id"] = actualParentItem;
                      if(currpendingprodobj[key]['updated_from'] == 'Edit Product Details') {
                        childprodele["parent_id"] = parent_id;  
                      }
                      childprodele["newSource"] = parentele.newSource; //priceDataObj[articleCode].source_type;
                      childprodele["newTransport"] = parentele.newTransport;
                      childprodele["newCD"] = currpendingprodobj[key]?.cd_per ? currpendingprodobj[key]?.cd_per : priceDataObj[articleCode].cd_per;
                      childprodele["newExtraMargin"] = priceDataObj[articleCode].extra_margin_per;
                      if(currpendingprodobj[key]['updated_from'] && currpendingprodobj[key]['updated_from'] == 'Price Update') {
                        childprodele["newExtraMargin"] = currpendingprodobj[key]['extra_margin_per'];
                      }
                      childprodele[`retailer_margin_per`] = priceDataObj[articleCode][`retailer_margin_per`];
                      ALL_REGIONS_LIST.forEach((region) =>{
                        if(currpendingprodobj[key][`extra_margin_per_${region}`]) {
                          childprodele[`extra_margin_per_${region}`] = currpendingprodobj[key][`extra_margin_per_${region}`];
                        }

                        if(currpendingprodobj[key][`dealer_price_${region}`]) {
                          childprodele[`dealer_price_${region}`] = currpendingprodobj[key][`dealer_price_${region}`];
                        }

                        if(currpendingprodobj[key][`retailer_margin_per_${region}`]) {
                          childprodele[`retailer_margin_per_${region}`] = currpendingprodobj[key][`retailer_margin_per_${region}`];
                        }
                      });     
                      childprodele["purchase_price"] = newBasePrice;
                      childprodele["newDealerPrice"] = productDataObj[articleCode].market_price == '' ? updatedProdPriceObj["dealer_price"] : '';
                      childprodele["newgstcharge"] = getRoundNoThree(updatedProdPriceObj["gst_charge"]);
                      childprodele["newapmccharge"] = "" + updatedProdPriceObj["apmc_charge"];
                      childprodele["newtransportcharge"] = parentele.newtransportcharge //parseToNo(productDataObj[articleCode].transport_charge);
                      
                      childprodele["rowselectkey"] = key;
                      childprodele["newTotalPrice"] = productDataObj[articleCode].market_price == '' ? updatedProdPriceObj.total_price : '';
                      childprodele["gst_per"] = productDataObj[articleCode].gst_per ? (productDataObj[articleCode].gst_per).replace('%', '') :'0'//parentele.gst_per;
                      childprodele["calcObj"] = updatedProdPriceObj;
                      childprodele["productdata"] = productDataObj[articleCode];
                      childprodele["updated_at"] = currpendingprodobj[key]["updated_at"];
                      childprodele["table_update_date"] = loopCheckPrevDate;
                      childprodele["item_name"] = `${productDataObj[articleCode].brand}-${productDataObj[articleCode].long_description}`;
                      childprodele["updated_from"] = currpendingprodobj[key]["updated_from"];
                      childprodele[`selling_price`] = currpendingprodobj[key][`selling_price`];
                      childprodele[`selling_price_ex_gst`] = currpendingprodobj[key][`selling_price_ex_gst`];
                      if(currpendingprodobj[key]['updated_from'] == 'Edit Dealer Price'){ 
                        childprodele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                        childprodele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                        childprodele[`conversion_per`] = currpendingprodobj[key]['conversion_per'];
                        childprodele[`conversion`] = currpendingprodobj[key]['conversion'];
                        childprodele[`wastage_per`] = currpendingprodobj[key]['wastage_per'];
                        childprodele[`wastage`] = currpendingprodobj[key]['wastage'];
                        childprodele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                      }

                      if(currpendingprodobj[key]['updated_from'] == 'Price Update' || currpendingprodobj[key]['updated_from'] == 'Edit Product Details') {
                        childprodele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                        childprodele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                      }

                      if(currpendingprodobj[key]['updated_from'] == 'Edit Price') {
                        childprodele[`conversion_per`] = currpendingprodobj[key]['conversion_per'];
                        childprodele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                        childprodele[`package_material_cost`] = currpendingprodobj[key]['package_material_cost'];
                        childprodele[`gross_cost`] = currpendingprodobj[key]['gross_cost'];
                        childprodele[`primary_pm_cost`] = currpendingprodobj[key]['primary_pm_cost'];
                        // childprodele[`conversion`] = currpendingprodobj[key]['conversion'];
                        childprodele[`wastage_per`] = currpendingprodobj[key]['wastage_per'];
                        childprodele[`wastage`] = currpendingprodobj[key]['wastage'];
                        childprodele[`landing_cost`] = currpendingprodobj[key]['landing_cost'];
                      }
                      childprodele[`mrp`] = currpendingprodobj[key][`market_price`] ? currpendingprodobj[key][`market_price`] : currpendingprodobj[key][`mrp`] ? currpendingprodobj[key][`mrp`] :"";
                      childprodele[`gst_charge`] = currpendingprodobj[key][`gst_charge`];   
                      childprodele['pending_price_updated_at'] = currpendingprodobj[key]['updated_at'];
                      finalProductsList.push(childprodele);
                    }
                  });
                }
              }
            }
          }
        }
        const uniqueData = {};

        // Iterate through the data array
        for (const item of finalProductsList) {
          const { article_code, pending_price_updated_at } = item;

          if (article_code in uniqueData) {
            if ((pending_price_updated_at >= uniqueData[article_code].pending_price_updated_at)) {
              uniqueData[article_code] = item;
            }
          } else {
            uniqueData[article_code] = item;
          }
        }
        const latestDateRecords = Object.values(uniqueData);

        console.log(latestDateRecords);
        //const latestProductList = getLatestDateRecord(finalProductsList);
        setBulkProductsUpdateList(latestDateRecords);
        setBulkProductUpdateModalOpen(true);
      });
  };

  const PriceUpdatesTableHeader = () => {
    const checkRef = useRef();

    return (
      <thead className="thead-light">
        <tr>
          <th>
            <input
              type="checkbox"
              id="selectAllProdCheckbox"
              ref={checkRef}
              onChange={() => {
                let bulkProductsChecks = document.getElementsByClassName(
                  "bulkProductsApprovalCheck"
                );
                for (let bulkprod = 0; bulkprod < bulkProductsChecks.length; bulkprod++) {
                  let prodele = priceAnalysisList[bulkprod];

                  if (checkRef.current.checked) {
                    if (
                      prodele.productapproved == "N" ||
                      prodele.productapproved == "Y"
                    ) {
                      bulkProductsChecks[bulkprod].checked = false;
                    } else {
                      bulkProductsChecks[bulkprod].checked = true;
                    }
                  } else {
                    bulkProductsChecks[bulkprod].checked = false;
                  }
                }
              }}
            />
          </th>
          <th>GRNDate</th>
          <th>ItemId</th>
          <th>Item Description</th>
          <th>Category</th>
          <th>UOM</th>
          <th>OuterSize</th>
          <th>Current BasePrice</th>
          <th>New BasePrice</th>
          <th>Current DealerPrice</th>
          <th>New DealerPrice</th>
          <th>Save</th>
        </tr>
      </thead>
    );
  };

  const updateProductsPriceList = (updatedData) => {
    let updatedProductsList = [...priceAnalysisList];
    updatedProductsList[rowIndex]["productapproved"] = updatedData["approved"];
    updatedProductsList[rowIndex]["purchase_price"] = updatedData["base_price"];
    updatedProductsList[rowIndex]["newDealerPrice"] = updatedData["dealer_price"];
    updatedProductsList[rowIndex]["newgstcharge"] = updatedData["gst_charge"];
    updatedProductsList[rowIndex]["newapmccharge"] = updatedData["apmc_charge"];
    setPriceAnalysisList(updatedProductsList);
    setPriceAnalysisListForUI(updatedProductsList);
  };

  const updatePriceAnalysisDataAfterDeletion = (prodKey, prodDate) => {
    let updatedProductsList = [];
    for (let pricedet = 0; pricedet < priceAnalysisList.length; pricedet++) {
      let ele = priceAnalysisList[pricedet];
      if (priceAnalysisList[pricedet].rowselectkey == prodKey) {
        delete ele["pendingproductdetails"];
        delete ele["productapproved"];
        delete ele["table_update_date"];
      }
      updatedProductsList.push(ele);
    }

    let tableDate = prodDate;
    let tableYear = prodDate.split("-")[2];
    let tableMonth = prodDate.split("-")[1];

    if (pendingProductsObj[tableYear][tableMonth][tableDate]) {
      delete pendingProductsObj[tableYear][tableMonth][tableDate][prodKey];
    }

    setPriceAnalysisList(updatedProductsList);
    // let updatedProdListForApproval = updatedProductsList.filter(val => val.productapproved && val.productapproved == 'Y')
    // setPriceAnalysisListForUI(updatedProdListForApproval);
  };

  const PriceUpdatesTableBody = () => {
    let rows = [];

    if (priceAnalysisList.length > 0) {
      rows = priceAnalysisList.map((item, index) => {
        let priceText = "";
        let purchasePrice = Number(item.purchase_price);
        let basePrice = Number(item.base_price);
        if (purchasePrice > basePrice) {
          priceText = "Increased";
        } else if (purchasePrice < basePrice) {
          priceText = "Decreased";
        } else {
          priceText = "No Change";
        }

        let disableCheckbox = false;
        if (item.productapproved == "N" || item.productapproved == "Y") {
          disableCheckbox = true;
        }

        return (
          <tr
            key={index}
            style={{
              backgroundColor:
                priceText &&
                (item.productapproved == "N" || item.productapproved == "Y"
                  ? "#808080"
                  : priceText === "Increased"
                  ? "#FFCCCC"
                  : priceText === "Decreased"
                  ? "#CCFFCC"
                  : "#FFFFFF"),
            }}
          >
            <td>
              <input
                type="checkbox"
                className="bulkProductsApprovalCheck"
                disabled={disableCheckbox}
                onChange={(e) => {
                  console.log(e.target.checked);
                }}
              />
            </td>
            <td>{item.grn_date}</td>
            <td>{item.article_code}</td>
            <td>{item.item_name}</td>
            <td>{item.category}</td>
            <td>{item.uom}</td>
            <td>{item.outer_size}</td>
            <td>{item.base_price}</td>
            <td>{item.purchase_price}</td>
            <td>{item.dealer_price}</td>
            <td>{item.newDealerPrice}</td>
            {(item.productapproved === undefined && (item.base_price !=item.purchase_price)) ||
            (item.productapproved != "N" && item.productapproved != "Y" && (item.base_price !=item.purchase_price)) ? (
              <td>
                <Button
                  onClick={() => {
                    setUpdateProduct(true);
                    setProductPriceDetails(item);
                    setLatestBasePrice(item.purchase_price);
                    setRowIndex(index);
                  }}
                >
                  Save
                </Button>
              </td>
            ) : item.productapproved == "Y" ? (
              <td>
                <i className="fa fa-check" aria-hidden="true"></i>
              </td>
            ) : (
              <td></td>
            )}
          </tr>
        );
      });
    } else {
      rows = [];
    }

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const exportPriceDetails = () => {
    console.log("exportPriceDetails");
    const wb = XLSX.utils.book_new();

    let priceDetailsSummary = [
      [
        "PurchaseDate",
        "ItemId",
        "Item Description",
        "Category",
        "UOM",
        "OuterSize",
        "Current BasePrice",
        "New BasePrice",
        "Current DealerPrice",
        "New DealerPrice",
      ],
    ];

    priceAnalysisList.map((item) => {
      let priceDetailsItem = [];
      priceDetailsItem.push(item["purchased_date"]);
      priceDetailsItem.push(item["article_code"]);
      priceDetailsItem.push(item["item_name"]);
      priceDetailsItem.push(item["category"]);
      priceDetailsItem.push(item["uom"]);
      priceDetailsItem.push(item["outer_size"]);
      priceDetailsItem.push(Number(item["base_price"]));
      priceDetailsItem.push(Number(item["purchase_price"]));
      priceDetailsItem.push(Number(item["dealer_price"]));
      priceDetailsItem.push(Number(item["newDealerPrice"]));
      priceDetailsSummary.push(priceDetailsItem);
    });
    let priceExportDetails = XLSX.utils.aoa_to_sheet(priceDetailsSummary);
    let wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 35 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    priceExportDetails["!cols"] = wscols;
    XLSX.utils.book_append_sheet(wb, priceExportDetails, `Price Details`);
    XLSX.writeFile(wb, `PriceUpdates.xlsx`);
  };

  const bulkAddToPendingApproval = () => {
    console.log("bulkAddToPendingApproval");
    let bulkProductsApprovalCheck = document.getElementsByClassName(
      "bulkProductsApprovalCheck"
    );

    let pendingprodlist = [];
    for (let approvalval = 0; approvalval < bulkProductsApprovalCheck.length; approvalval++) {
      if (bulkProductsApprovalCheck[approvalval].checked) {
        let prodele = priceAnalysisList[approvalval];
        if (
          prodele.productapproved === undefined ||
          (prodele.productapproved != "N" && prodele.productapproved != "Y")
        ) {
          pendingprodlist.push(prodele);
        }
      }
    }
    console.log(pendingprodlist);

    if (pendingprodlist.length > 0) {
      setBulkProductsPendingApprovalList(pendingprodlist);
      setBulkProductPendingApprovalOpen(true);
    } else {
      toast("Please select products to send for approval.", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  const handleKeyPress = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <NavBar />
      <center>
        <h3 style={{ paddingTop: 100 }}>Price Updates</h3>
      </center>
      <div className="row" style={{ margin: 10 }}>
        <div className="col-md-3" style={{ paddingLeft: 30 }}>
          <Form.Control
            type="search"
            style={{ marginLeft: 10 }}
            placeholder="ItemID / ItemName / Category"
            value={searchText}
            onChange={(e) => {
              handleKeyPress(e);
            }}
          />
        </div>
        <div className="col-md-3">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="regionid">Price Change</InputGroup.Text>
            </InputGroup.Prepend>
            <DropdownButton
              id="dropdown-basic-button"
              title={priceChangeText ? priceChangeText : "All"}
            >
              <Dropdown.Item
                value={"All"}
                onClick={(e) => {
                  e.preventDefault();
                  setPriceChangeText("All");
                }}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                value={"Increased"}
                onClick={(e) => {
                  e.preventDefault();
                  setPriceChangeText("Increased");
                }}
              >
                Increased
              </Dropdown.Item>
              <Dropdown.Item
                value={"Decreased"}
                onClick={(e) => {
                  e.preventDefault();
                  setPriceChangeText("Decreased");
                }}
              >
                Decreased
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup>
        </div>
        <div className="col-md-3">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="regionid">Last X Days</InputGroup.Text>
            </InputGroup.Prepend>
            <DropdownButton
              id="dropdown-basic-button"
              title={
                lastNumDaysText != "Today"
                  ? "Today + " + lastNumDaysText
                  : "Today"
              }
            >
              <Dropdown.Item
                value={"Today"}
                onClick={(e) => {
                  e.preventDefault();
                  setLastNumDaysText("Today");
                }}
              >
                Today
              </Dropdown.Item>
              <Dropdown.Item
                value={"Today + 1"}
                onClick={(e) => {
                  e.preventDefault();
                  setLastNumDaysText("1");
                }}
              >
                Today + 1
              </Dropdown.Item>
              <Dropdown.Item
                value={"Today + 2"}
                onClick={(e) => {
                  e.preventDefault();
                  setLastNumDaysText("2");
                }}
              >
                Today + 2
              </Dropdown.Item>
              <Dropdown.Item
                value={"Today + 3"}
                onClick={(e) => {
                  e.preventDefault();
                  setLastNumDaysText("3");
                }}
              >
                Today + 3
              </Dropdown.Item>
            </DropdownButton>
            <InputGroup.Append>
              {lastNumDaysText != "Today" ? (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setLastNumDaysText("Today");
                  }}
                >
                  X
                </Button>
              ) : (
                ""
              )}
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Updated Products</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Checkbox
              aria-label="updatedProd"
              onChange={(e) => {
                setUpdatedProducts(e.target.checked);
              }}
            />
          </InputGroup>
        </div>
        <div>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Only Latest Changes</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Checkbox
              aria-label="onlyLatestChanges"
              defaultChecked
              onChange={(e) => {
                setOnlyLatestChanges(e.target.checked);
              }}
            />
          </InputGroup>
        </div>
        <div style={{ paddingTop: 10 }}>
          {   priceAnalysisListForUI.length > 0 &&
            // validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && 
            (
              <Button
                variant="success"
                style={{ marginLeft: 40 }}
                onClick={() => {
                  bulkUpdateDealerPrice();
                }}
              >
                Pending Approval ({priceAnalysisListForUI.length})
              </Button>
            )}
          <Button
            variant="success"
            style={{ marginLeft: 15 }}
            onClick={() => {
              bulkAddToPendingApproval();
            }}
          >
            Bulk Add
          </Button>
          {/* {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && ( */}
            <Button
              variant="secondary"
              style={{ marginLeft: 15 }}
              onClick={() => {
                window.open("#/priceanalysis");
              }}
            >
              Price Analysis
            </Button>
          {/* )} */}
          {/* {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && ( */}
            <Button
              variant="secondary"
              style={{ marginLeft: 15 }}
              onClick={() => {
                window.open("#/priceupdatehistory");
              }}
            >
              Price History
            </Button>
          {/* )} */}
          {/* {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && ( */}
            <Button
              variant="warning"
              style={{ marginLeft: 15 }}
              onClick={() => {
                exportPriceDetails();
              }}
            >
              Export
            </Button>
          {/* )} */}
        </div>
      </div>
      {priceAnalysisList.length > 0 ? (
        <table className="table" style={{ marginTop: 10, paddingTop: 100 }}>
          <PriceUpdatesTableHeader />
          <PriceUpdatesTableBody />
        </table>
      ) : loading ? (
        <LoaderComp />
      ) : (
        <div style={{ margin: 40 }}>No test data present</div>
      )}

      {updateProduct && (
        <UpdateProductNewDealerPriceModal
          show={updateProduct}
          onHide={() => setUpdateProduct(false)}
          callback={updateProductsPriceList}
          updateDetails={productPriceDetails}
          updateProduct={updateProduct}
          newBasePrice={latestBasePrice}
          region={region}
          productDataObj={productDataObj}
          priceDataObj={priceDataObj}
        />
      )}
      {bulkProductUpdateModalOpen && bulkProductsUpdateList.length > 0 && (
        <BulkUpdateProductDealerPriceModal
          show={bulkProductUpdateModalOpen}
          onHide={() => setBulkProductUpdateModalOpen(false)}
          tempProductDetails={bulkProductsUpdateList}
          bulkProductUpdateModalOpen={bulkProductUpdateModalOpen}
          region={region}
          regionTransportCharge={regionTransportCharge}
          productDataObj={productDataObj}
          priceDataObj={priceDataObj}
          callback={updatePriceAnalysisDataAfterDeletion}
        />
      )}
      {bulkProductPendingApprovalOpen &&
        bulkProductsPendingApprovalList.length > 0 && (
          <BulkAddProductPendingApprovalModal
            show={bulkProductPendingApprovalOpen}
            onHide={() => setBulkProductPendingApprovalOpen(false)}
            tempProductDetails={bulkProductsPendingApprovalList}
            bulkProductPendingApprovalOpen={bulkProductPendingApprovalOpen}
            regionTransportCharge={regionTransportCharge}
            region={region}
            productDataObj={productDataObj}
            priceDataObj={priceDataObj}
          />
        )}
    </>
  );
};

export default PriceUpdates;
