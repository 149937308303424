import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { rootStore } from '../../store';
import database from '../../service/firebaseConfig';
import PriceCalculations from '../../service/priceCalculations';
import { validateDCManagerUser, validateIsAdminUser,validateCommercialUser } from '../../service/credentials';
import AdminViewComp from './adminView';
import FinanceViewComp from './financeView';
import { CLOSE_SCREEN, UPDATE_DATA_IN_DB, ADMIN_TEXT,COMMERCIAL_TEXT, FINANCE_TEXT, INIT_PAGE_TITLE, LOGGED_USER_EMAIL, PROD_MASTER_TABLE, PRICE_TABLE, REGION_BOM_A, REGION_BOM_B, REGION_BOM_D, REGION_BOM_N, REGION_BOM_AA, REGION_BOM_O,REGION_BOM_DD,REGION_BOM_NN,REGION_BOM_PG,REGION_BOM_ST,REGION_BOM_NK, REGION_BOM_H,REGION_BOM_K, REGION_S,REGION_PUNE_P,TRANSPORT_FACTOR, CITY_MUMBAI, CITY_PUNE, ALL_REGIONS_LIST} from '../../constants/allConstants';
import CommercialViewComp from './commercialView';
import axios from "axios";
import moment from "moment";
import {ORDERING_BASE_URL} from './../../service/servicesConfig';

const priceDetailsDB = database.database().ref(`${PRICE_TABLE}/mumbai/items`);

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
}

const EditProducts = () => {
    const getUserType = () => {
        const isAdminUser = validateIsAdminUser(userEmail);
        if (isAdminUser === true) {
            return ADMIN_TEXT;
        } else if (validateCommercialUser(userEmail)) {
          return COMMERCIAL_TEXT;
        }
        return FINANCE_TEXT;
    };

    const dbStatusObj = {
        "region_a": null,
        "region_dd": null,
        "region_d": null,
        "region_rg": null,
        "region_s": null,
        "region_c": null,
        "region_b": null,
        "region_n": null,
        "region_pg": null,
        "region_nk": null,
        "region_h": null,
        "region_p": null,
        "region_k": null,
        "priceDetails": null,
    };

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_RIGHT,
    });

    const checkValid = (data) => {
        if (data === undefined || data === null || isNaN(data) || data === "") {
            return false;
        } else {
            data = parseFloat(data).toFixed(2);
            if (data === "0.00") {
                return false;
            }
            return true;
        }
    };

    const globalState = useContext(rootStore);
    const { dispatch } = globalState;
    const history = useHistory();
    const onClickEditData = globalState.state['editData'];
    const [prodData, updateProdData] = useState(onClickEditData);
    const [originalProdData, updateOriginalProdData] = useState(onClickEditData);
    const [priceFromDB, updatePriceFromDB] = useState({});
    const [calcObj, updateCalcObj] = useState({});
    const [dbStatus, setDBStatus] = useState(dbStatusObj);
    const [showMaterial, toggleMaterial] = useState(false);
    const [showWastage, toggleWastage] = useState(false);
    const [keysUpdate,updateKeysFromDb] = useState([]);
    let [productDataObj, setProductDataObj] = useState({});

    let userEmail = sessionStorage.getItem(LOGGED_USER_EMAIL);
    let userType = getUserType();
    let selectedCity = CITY_MUMBAI;
    let selectedRegion = REGION_BOM_A;
    const regionDetails = globalState.state['regionDetails'];
    if(regionDetails['bom_region']) {
        selectedRegion = regionDetails['bom_region'];
    } else if (regionDetails['all_region']){
        selectedRegion = regionDetails['all_region'];
    }
    if(regionDetails['curr_city']===CITY_PUNE){
        selectedRegion = REGION_PUNE_P;
    }
    selectedCity = regionDetails['curr_city'];
    let errorMessage = '';
    let returnFlag = false;
    const ObjPassedToAdmin = {
        prodData: { ...prodData },
        priceFromDB: { ...priceFromDB },
        calcObj: { ...calcObj },
        showMaterial,
        showWastage
    };
    const ObjPassedToFinance = {
        prodData: { ...prodData },
        priceFromDB: { ...priceFromDB },
        calcObj: { ...calcObj }
    };


    const initAddData = (data) => {
        const temp = { ...data };
        temp.sz_margin_per = "1%";
        return temp;
    };

const AddKeys = (data) => {

    const temp = {...data}
    const keyss = Object.keys(temp)
    let dbb = (keyss.filter(val => val.includes("extra_margin_per_"))).map(elem => elem.substring(17)).map(item => ({'label':item,'value':item}))
    return dbb;
}


    const updateCalcFunc = () => {
        updateCalcObj(PriceCalculations(priceFromDB, prodData, showMaterial, showWastage));
    };

    const getProductDetailsFromDB = async () =>{
        let productSnap = await database.database().ref(`Product_Master_New/A/`).once("value");
        let dealerpriceobj = productSnap.val();
        setProductDataObj(dealerpriceobj);
    }

    const getPriceDetailsFromDB = () => {
        
        priceDetailsDB.once('value').then(dataSnapshot => {
            dataSnapshot.forEach(child => {
                if (parseInt(child.key) === parseInt(prodData.item_id)) {
                    const data = child.val();
                    const upDated = initAddData(data);
                    updatePriceFromDB(upDated);
                    const updateKeys = AddKeys(data)
                    updateKeysFromDb(keysUpdate => [...keysUpdate, ...updateKeys])
                }
            });
        });
    };

const deleteSeries = (series) =>{
    const name = "extra_margin_per_"+series.label;
    // const value = event.target.value;
    const temp_D = { ...priceFromDB}
    delete temp_D[name]
    updatePriceFromDB(temp_D)
    
}

    const adminPriceModify = (eventOrName, value1) => {

        let name, value;
                
        if (typeof eventOrName === 'object' && eventOrName.target) {
            name = eventOrName.target.name;
            value = eventOrName.target.value;
        } else {
            name = eventOrName;
            value = value1;
        }    

        if(name == 'material_cost_factor' && value == 0) {
            toggleMaterial(true);
        } else if (name == 'package_material_cost' && value == 0) {
            toggleMaterial(!showMaterial);
        }

        if (name === "base_price") {
            const temp_P = { ...prodData };
            temp_P[name] = value;
            updateProdData(temp_P);
        }
        const temp = { ...priceFromDB };
        temp[name] = value;
        updatePriceFromDB(temp);
    };

    const adminPriceDropdownModify = (name,value) => {
      const temp = { ...priceFromDB };
      temp[name] = value;
      updatePriceFromDB(temp);
    }

    const closeEditScreen = () => {
        document.title = INIT_PAGE_TITLE;
        dispatch({ type: CLOSE_SCREEN });
    };

    const addMaterialOrWastageCost = (obj, setMaterialCost = true) => {
        const temp = { ...obj };
        if (showMaterial && setMaterialCost) {
            temp.material_cost = calcObj.material_cost;
        } else if (!showMaterial && setMaterialCost) {
            temp.material_cost = "0";
        }

        if (showWastage) {
            temp.wastage = calcObj.wastage;
        } else if (!showWastage) {
            temp.wastage = "0";
        }

        return temp;
    };

    const calculateGST = (gst_abs_per,dealer_price)  => {
        let gstCharges =  gst_abs_per * (parseFloat(dealer_price));
        if(parseFloat(priceFromDB.lot_size) > 1 && (!prodData.market_price || parseFloat(isValid(prodData.market_price)) < 1)) {
            gstCharges = gst_abs_per * (parseFloat(dealer_price)) / parseFloat(priceFromDB.lot_size);
        }
        return gstCharges.toFixed(4);
    };

    const isValid = (data) => {
        if (data === undefined || data === null || data === "" || data.length === 0) {
            return 0;
        }
        return data;
    };

    const getDealerPrice = (dealer_price,region_name)   => { 
        let transport_factor = 0;
        if (TRANSPORT_FACTOR[selectedRegion] > 0) {
            transport_factor = (parseFloat(priceFromDB.transport_charge) / TRANSPORT_FACTOR[selectedRegion]) * TRANSPORT_FACTOR[region_name];
        }
        const package_cost = priceFromDB.package_material_cost?parseFloat(priceFromDB.package_material_cost):0
        const dealerPrice =  parseFloat(dealer_price) + package_cost + (transport_factor * parseFloat(prodData.lot_size));
        return dealerPrice.toFixed(2);
    }

    const getRoundNo = (data) => {
        return parseFloat(data).toFixed(2);
    };

    const writeProdDataToDB = (region_name) => {
        const tempTime = new Date();
        let total_price = calcObj.total_price_wo_rounding;
        let total_bag_price = calcObj.total_bag_price;
        let dealer_price = calcObj.dealer_price;
        let apmc_charge = calcObj.apmc_charge
        total_price = calcObj['total_price_wo_rounding_'+region_name]?calcObj['total_price_wo_rounding_'+region_name]:calcObj['total_price'];
        total_bag_price = calcObj['total_bag_price_'+region_name]?calcObj['total_bag_price_'+region_name]:calcObj['total_bag_price'];
        dealer_price = calcObj['dealer_price_'+region_name]?calcObj['dealer_price_'+region_name]:calcObj['dealer_price'];
        apmc_charge = '0';
        const updObj = {
            base_price: priceFromDB.base_price,
            lot_size: priceFromDB.lot_size,
            gst_charge: calculateGST(calcObj.gst_abs_per, dealer_price),
            total_price: getRoundNo(total_price),
            total_bag_price: total_bag_price ? total_bag_price : 0,
            gst_per: priceFromDB.gst_per ? priceFromDB.gst_per.replace('%', '') : '0.00',
            dealer_price: dealer_price,
            updated_by: userEmail,
            last_updated: tempTime
        };
        let table_details = database.database().ref(`${PROD_MASTER_TABLE}/${region_name}/${prodData.item_id}`);
        table_details.once('value').then(dataSnapshot => {
            if (dataSnapshot.val()) {
                table_details.update(updObj, function (error) {
                    if (error) {
                        setDBStatus(dbStatus[`region_${region_name.toLowerCase()}`] = false);
                        errorMessage = "Couldn't able to update the region " + region_name;
                        console.log("Could not able to update for the region::: " + region_name);
                    } else {
                        console.log("Updated successfully for the region::: " + region_name);
                        setDBStatus(dbStatus[`region_${region_name.toLowerCase()}`] = true);
                    }
                    reloadHPAfterDBSet();
                });
            } else {
                errorMessage += errorMessage?", " + dataSnapshot.ref.parent.key:"Product is not available for ::: "+ dataSnapshot.ref.parent.key;
                setDBStatus(dbStatus[`region_${region_name.toLowerCase()}`] = true);
                console.log("Product is not available for::: " + dataSnapshot.ref.parent.key);
            }
        });
    };

    const setPriceAdminDB = () => {
        const tempTime = new Date();
        const initObj = {
            base_price: priceFromDB.base_price,
            apmc_loading: priceFromDB.apmc_loading,
            apmc_per: priceFromDB.apmc_per?priceFromDB.apmc_per:"",
            lot_size: priceFromDB.lot_size,
            conversion_per: priceFromDB.conversion_per,
            sz_margin: calcObj.sz_margin_charges,
            extra_margin_per: priceFromDB.extra_margin_per,
            material_cost_factor: priceFromDB.material_cost_factor?priceFromDB.material_cost_factor:"",
            wastage_per: priceFromDB.wastage_per?priceFromDB.wastage_per:"",
            primary_pm_cost: priceFromDB.primary_pm_cost?priceFromDB.primary_pm_cost:"",
            package_material_cost:calcObj.package_material_cost? calcObj.package_material_cost: "", //priceFromDB.package_material_cost?priceFromDB.package_material_cost:"",
            gst_per: priceFromDB.gst_per ? priceFromDB.gst_per.replace('%', '') : '0.00',
            total_price: calcObj.total_price,
            total_bag_price: calcObj.total_bag_price,
            source_type: priceFromDB.source_type,
            mill_transport: priceFromDB.mill_transport?priceFromDB.mill_transport:"",
            mill_transport_value: calcObj.mill_transport_value?calcObj.mill_transport_value:"0.00",
            cd_per: priceFromDB.cd_per?priceFromDB.cd_per:"0.00",
            buying_gst_charge: calcObj.buying_gst_charge,
            final_buying_price: calcObj.final_buying_price,
            buying_price_ex_gst: calcObj.buying_price_ex_gst,
            local_transport: priceFromDB.local_transport?priceFromDB.local_transport:"0.00",
            gross_cost:calcObj.gross_cost,
            landing_cost: calcObj.landing_cost,
            gross_rgm: calcObj.gross_rgm,
            gross_gm_per: calcObj.gross_gm_per,
            net_rgm: calcObj.net_rgm,
            net_gm_per: calcObj.net_gm_per,
            updated_by: userEmail,
            last_updated: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')//tempTime
            // dealer_price:calcObj.dealer_price

        };

        // ALL_REGIONS_LIST.forEach((region) =>{
        //     if(calcObj[`dealer_price_${region}`]) {
        //         initObj[`dealer_price_${region}`] = calcObj[`dealer_price_${region}`];
        //     }
        // })
        // if delete then no key in price from db should be there. before updating check add ya delete.
        // on delete check if price from db contains key. deleted key
        Object.keys(priceFromDB).map((key,index) => {
            if(key.includes("extra_margin_per_")) {
                initObj[key] = priceFromDB[key];
            }
        })

        if(prodData.market_price){
          initObj['retailer_margin_per'] = priceFromDB.retailer_margin_per?priceFromDB.retailer_margin_per:"0.00";
        }
    
        const updObj = { ...addMaterialOrWastageCost(initObj) };
        database.database().ref(`${PRICE_TABLE}/mumbai/items/${prodData.item_id}`).update(updObj, async function (error) {
            if (error) {
                setDBStatus(dbStatus['priceDetails'] = false);
            } else {
                let url = `${ORDERING_BASE_URL}/api/superzop/admin/price_details/insertpricedetails`;
                updObj['item_id'] = prodData.item_id;
                let dataToSubmit = updObj;
                try {
                    let respData = await axios.post(url, dataToSubmit);
                    let resCode = respData.data?.success || 0;
                    if (resCode === 1) {
                        setDBStatus(dbStatus['priceDetails'] = true);
                    }else{
                        setDBStatus(dbStatus['priceDetails'] = false);
                    }
                } catch (error) {
                    console.error(error);
                }
                
                //setDBStatus(dbStatus['priceDetails'] = true);
            }
            reloadHPAfterDBSet();
        });
    };

    const writeDataToPriceFromDB = () => {
        const tempTime = new Date();
        const initObj = {
            base_price: priceFromDB.base_price,
            sz_margin: calcObj.sz_margin_charges,
            total_price: calcObj.total_price,
            total_bag_price: calcObj.total_bag_price,
            updated_by: userEmail,
            last_updated: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')//tempTime
        };


        if (userType === FINANCE_TEXT) {
            const updObj = { ...addMaterialOrWastageCost(initObj, false) };
            database.database().ref(`${PRICE_TABLE}/mumbai/items/${prodData.item_id}`).update(updObj, async function (error) {
                if (error) {
                    setDBStatus(dbStatus['priceDetails'] = false);
                } else {
                    let url = `${ORDERING_BASE_URL}/api/superzop/admin/price_details/insertpricedetails`;
                    let dataToSubmit = updObj;
                    let respData = await axios.post(url, dataToSubmit);
                    let resCode = respData.data?.success || 0;
                    if (resCode === 1) {
                        setDBStatus(dbStatus['priceDetails'] = true);
                    }else{
                        setDBStatus(dbStatus['priceDetails'] = false);
                    }
                    //setDBStatus(dbStatus['priceDetails'] = true);
                }
                reloadHPAfterDBSet();
            });
        } else if (userType === ADMIN_TEXT) {
            setPriceAdminDB();
        }
    };

    const writeDataToPendingPriceUpdateDB = () => {
        let currdate=formatDate(new Date());
        let currentYear=currdate.split("-")[2];
        let currentMonth=currdate.split("-")[1];

        let d = new Date(),
    //   currdformat = [d.getDate().padLeft(),
    //             (d.getMonth()+1).padLeft(),
    //              d.getFullYear()].join('-') +' ' +
    //             [d.getHours().padLeft(),
    //              d.getMinutes().padLeft()].join(':');

        currdformat = [
            d.getDate().padLeft(),
            (d.getMonth() + 1).padLeft(),
            d.getFullYear(),
        ].join("-") + 
            " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");

        let prodele={};
        prodele['dealer_price']=calcObj.dealer_price;
        prodele['base_price']=priceFromDB.base_price;
        prodele['gst_charge']=calculateGST(calcObj.gst_abs_per,prodData.dealer_price);
        prodele['transport_charge']=""+TRANSPORT_FACTOR[selectedRegion];
        prodele['item_id']=prodData.item_id;
        prodele['approved']="Y";
        prodele['old_base_price']=originalProdData.base_price;
        prodele['old_dealer_price']=prodData.dealer_price;
        prodele['updated_by']=sessionStorage.getItem("Logged User Email Id");
        prodele['updated_at']=currdformat;
        prodele['approved_by']=sessionStorage.getItem("Logged User Email Id");
        prodele['approved_at']=currdformat;
        prodele['updated_from']="Edit Price";
        prodele['landing_cost'] = calcObj.landing_cost;
        prodele['gross_cost'] = calcObj.gross_cost;
        prodele['buying_price_ex_gst'] = calcObj.buying_price_ex_gst;
        prodele['extra_margin_per'] = priceFromDB.extra_margin_per;
        prodele['total_price'] = calcObj.total_price;


        let productid=prodData.item_id;
        let randomtwodigit=Math.floor(Math.random() * 90) + 10;
        let productkey=productid+"-"+randomtwodigit;
        

        //save in Pending_Price_Update table
        database.database().ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/${productkey}`).update(prodele)
        .then(() => {
          console.log("Successfully added product price to PendingPriceUpdate.");
        }).catch((error) => {
          console.log("Error in saving price details to PendingPriceUpdate : "+error);
        });
    }

    const getAbsFromPer = (data) => {
        return parseFloat(parseToNo(data) / 100);
    };

    const writeDataToPendingPriceUpdateDBForCommercial = () => {
        let currdate = formatDate(new Date());
        let currentYear = currdate.split("-")[2];
        let currentMonth = currdate.split("-")[1];
    
        let d = new Date();
        let currdformat =
            [
                d.getDate().padLeft(),
                (d.getMonth() + 1).padLeft(),
                d.getFullYear(),
            ].join("-") +
            " " +
            [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");
    
        const baseRef = database.database().ref(`Pending_Price_Update/${currentYear}/${currentMonth}/${currdate}/`);
        let updates = {};
        
        let wastage = "";
        if(priceFromDB.wastage_per) {
            wastage = getAbsFromPer(priceFromDB.wastage_per) * parseToNo(calcObj.gross_cost);
        }
        // Prepare parent product data
        let prodele = {
            dealer_price: calcObj.dealer_price,
            base_price: priceFromDB.base_price,
            gst_charge: calculateGST(calcObj.gst_abs_per, prodData.dealer_price),
            transport_charge: "" + TRANSPORT_FACTOR[selectedRegion],
            source_type: priceFromDB.source_type,
            mill_transport: priceFromDB.source_type == 'mill' ? priceFromDB.mill_transport : "",
            mill_transport_value: priceFromDB.source_type == 'mill' ? calcObj.mill_transport_value : "",
            item_id: prodData.item_id,
            approved: "N",
            old_base_price: originalProdData.base_price,
            old_dealer_price: prodData.dealer_price,
            updated_by: sessionStorage.getItem("Logged User Email Id"),
            updated_at: currdformat,
            updated_from: "Edit Price",
            gross_cost: calcObj.gross_cost,
            landing_cost: calcObj.landing_cost,
            buying_price_ex_gst: calcObj.buying_price_ex_gst,
            extra_margin_per: priceFromDB.extra_margin_per,
            cd_per: priceFromDB.cd_per || "",
            mrp: priceFromDB.mrp || "",
            total_price: calcObj.total_price,
            wastage_per: priceFromDB.wastage_per || "",
            wastage : parseFloat(wastage)?.toFixed(2) ||"",
            primary_pm_cost: priceFromDB.primary_pm_cost || "",
            package_material_cost: calcObj.package_material_cost || "",
            conversion_per: priceFromDB.conversion_per,
        };
    
        // Add extra margins and dealer price calculations to parent
        Object.entries(priceFromDB).forEach(([key, value]) => {
            if (key.includes("extra_margin_per_")) {
                prodele[key] = value;
            }
        });
        Object.entries(calcObj).forEach(([key, value]) => {
            if (key.includes("dealer_price_")) {
                prodele[key] = value;
            }
        });
    
        // Generate unique parent key
        let productid = prodData.item_id;
        let randomtwodigit = Math.floor(Math.random() * 90) + 10;
        let productkey = productid + "-" + randomtwodigit;
    
        // Add parent update to updates object
        updates[`${productkey}`] = prodele;
    
        Object.keys(productDataObj).forEach((key) => {
            let childData = productDataObj[key];
            let parent_id = childData.parent_id || "";
            let parent_id1 = childData.parent_id1 || "";
            let market_price = childData.market_price ? childData.market_price :'';
    
            if ((parent_id === productid || parent_id1 === productid) && (!market_price || !(market_price >0))) {
                //priceDataObj[childId] = priceDataObj[childId] || {};
                priceFromDB['cd_per'] =  prodele.newCD ?? prodele?.['cd_per'] ?? "";
                priceFromDB['mill_transport_value'] = prodele && prodele.transport_charge ? prodele.transport_charge:priceFromDB['mill_transport_value'];
                priceFromDB['source_type'] = prodele && prodele.newSource ? prodele.newSource:priceFromDB['source_type'];
                priceFromDB['transport'] = prodele && prodele.newTransport ? prodele.newTransport:priceFromDB['transport'];
                priceFromDB["apmc_loading"] = prodele && prodele.apmc_loading ? prodele.apmc_loading :0; 
                priceFromDB["conversion_per"] = prodele && prodele.conversion_per ? prodele.conversion_per :"";
                let show_mat = priceFromDB.material_cost > 0 ? true: false;
                let show_was = priceFromDB.wastage > 0 ? true : false;
                let updatedChildData = dealerPriceCalculations(
                    priceFromDB,
                    childData,
                    show_mat,
                    show_was,
                    priceFromDB.base_price
                );
                
                let childWastage = "";
                if(priceFromDB.wastage_per) {
                    childWastage = getAbsFromPer(priceFromDB.wastage_per) * parseToNo(updatedChildData.gross_cost);
                }
                let childprodele = {
                    newDealerPrice: updatedChildData.dealer_price,
                    newTotalPrice: updatedChildData.total_price,
                    base_price: priceFromDB.base_price,
                    apmc_charge: updatedChildData.apmc_charge,
                    gst_charge: getRoundNoThree(updatedChildData.gst_charge),
                    transport_charge: childData.transport_charge || "",
                    source_type: priceFromDB.source_type,
                    mill_transport: priceFromDB.source_type ? priceFromDB.mill_transport : "",
                    mill_transport_value: priceFromDB.source_type ? calcObj.mill_transport_value : "",
                    item_id: key,
                    approved: "N",
                    total_price:updatedChildData.total_price,
                    type: "child",
                    parent_id: productid,
                    gross_cost: updatedChildData.gross_cost,
                    landing_cost: updatedChildData.landing_cost,
                    updated_at: currdformat,
                    updated_from: "Edit Price",
                    conversion_per:priceFromDB.conversion_per,
                    cd_per: priceFromDB.cd_per || "",
                    buying_price_ex_gst: calcObj.buying_price_ex_gst,
                    extra_margin_per: priceFromDB.extra_margin_per,
                    wastage_per: priceFromDB.wastage_per || "",
                    wastage:parseFloat(childWastage)?.toFixed(2) || "",
                    primary_pm_cost: priceFromDB.primary_pm_cost || "",
                    package_material_cost: calcObj.package_material_cost || "",
                };
    
                // Generate unique child key
                let randomChildKey = key + "-" + (Math.floor(Math.random() * 90) + 10);
    
                updates[`${randomChildKey}`] = childprodele;
            }
        });
        
        console.log("Updates Object for Firebase:", updates);
        // Perform multi-path update database.database().ref
        baseRef.update(updates)
            .then(() => {
                console.log("Successfully added parent and child data to PendingPriceUpdate.");
                notifyUser(true);
                document.title = INIT_PAGE_TITLE;
                dispatch({ type: UPDATE_DATA_IN_DB });
                history.push("/home");
            })
            .catch((error) => {
                console.error("Error saving data:", error.message);
            });
    };

    const updateDataInDB = (event) => {
        event.persist();
        if(userType === ADMIN_TEXT) {
            ALL_REGIONS_LIST.forEach(region => {
                writeProdDataToDB(region);
             });
            writeDataToPriceFromDB();
            writeDataToPendingPriceUpdateDB();
        }else if(userType === COMMERCIAL_TEXT){
            writeDataToPendingPriceUpdateDBForCommercial();
        }
    };

    const getRoundNoThree = (data) => {
        return parseToNo(data).toFixed(3);
    };

    const parseToNo = (string) => {
        return parseFloat(isValid(string));
    };

    const dealerPriceCalculations = (
        priceFromDB,
        prodData,
        showMaterial,
        showWastage,
        updatedBasePrice
      ) => {
    
        // if(source == 'MILL') {
        //   priceFromDB['source_type'] = 'mill';
        // }else if(source == 'DC Delivered' || source == 'DC DELIVERED'){
        //   priceFromDB['source_type'] = 'dc_delivered';
        // }else if(source == 'LOCAL PICK' || source == 'Local Pick' || priceFromDB['source_type'] == 'LOCAL PICK'){
        //   priceFromDB['source_type'] = 'local_pick';
        // }
    
        // if(name == 'MILL') {
        //   priceFromDB['source_type'] = 'mill';
        // }
        // if(name == 'DC Delivered' || name == 'DC DELIVERED') {
        //   priceFromDB['source_type'] = 'dc_delivered';
        // }
        // if(name == 'LOCAL PICK' || name == 'Local Pick') {
        //   priceFromDB['source_type'] = 'local_pick';
        // }
    
        // if(transport == 'INCLUDED'){
        //   priceFromDB['mill_transport'] = 'included';
        // } else if(transport == 'EXCLUDED') {
        //   priceFromDB['mill_transport'] = 'excluded';
        // }
    
        // if(name == 'newTransport' && value == 'EXCLUDED') {
        //   priceFromDB['mill_transport'] = 'excluded';
        // } else if(name == 'newTransport' && value == 'INCLUDED') {
        //   priceFromDB['mill_transport'] = 'included';
        // }
      
        if(updatedBasePrice) {
          priceFromDB['base_price'] = updatedBasePrice;
          prodData['base_price'] = updatedBasePrice;
        }
        // if(name == 'newExtraMargin') {
        //   priceFromDB['extra_margin_per'] = `${value}%`;
        // }
        
        // if(name == 'newCD'){
        //   priceFromDB['cd_per'] = value;
        // }
    
        // if(name =='newtransportcharge') {
        //   priceFromDB['mill_transport_value'] = value;
        //}
        
        let resObj = PriceCalculations(priceFromDB, prodData, showMaterial, showWastage);
        return resObj;
    };
      
    const notifyUser = (success) => {
        if (success && errorMessage.includes("Product is not available") ) {
            return toast(errorMessage + " and successfully updated for others", { type: toast.TYPE.WARNING });
        } else if (success) {
            return toast("Successfully updated in server", { type: toast.TYPE.SUCCESS });
        }
        return toast(errorMessage, { type: toast.TYPE.ERROR });
    };


    //Toast Message on successful updation
    const reloadHPAfterDBSet = () => {
        console.log("DB status for various regions::: "+ dbStatus.region_a + dbStatus.region_b + dbStatus.region_c + dbStatus.region_n + dbStatus.priceDetails );
        if(dbStatus.region_a == null || dbStatus.region_b == null ||  dbStatus.region_c == null  ||  dbStatus.region_n == null || dbStatus.priceDetails == null  || returnFlag) {
            return;
        }
        if (dbStatus.region_a && dbStatus.region_b && dbStatus.region_c &&  dbStatus.region_n && dbStatus.priceDetails && !returnFlag) {
            returnFlag = true;
            notifyUser(true);
            document.title = INIT_PAGE_TITLE;
            dispatch({ type: UPDATE_DATA_IN_DB });
            history.push('/home');
        } else if (!dbStatus.region_a &&  !dbStatus.region_b && !dbStatus.region_c &&  !dbStatus.region_n  && !dbStatus.priceDetails) {
            notifyUser(false);
        }
        window.scrollTo(0, 0);
    };

    const includeMaterialCost = () => {
        toggleMaterial(!showMaterial);
    };

    const includeWastageCost = () => {
        toggleWastage(!showWastage);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    
        getPriceDetailsFromDB();
        getProductDetailsFromDB();
    }, []);

    useEffect(() => {
        toggleMaterial(checkValid(priceFromDB.material_cost));
    }, [priceFromDB.material_cost]);

    useEffect(() => {
        toggleWastage(checkValid(priceFromDB.wastage));
    }, [priceFromDB.wastage]);


    useEffect(() => {
        updateCalcFunc();
    }, [priceFromDB, prodData, showMaterial, showWastage]);

    return (
        <Fragment>
            {(userType === ADMIN_TEXT) ?
                (<AdminViewComp
                    {...ObjPassedToAdmin}
                    keysUpdate = {keysUpdate}
                    regionDetails={regionDetails}
                    updateData={updateDataInDB}
                    closeScreen={closeEditScreen}
                    priceModify={adminPriceModify}
                    deleteSeries = {deleteSeries}
                    priceDropdownModify={adminPriceDropdownModify}
                    includeMaterial={includeMaterialCost}
                    includeWastage={includeWastageCost}

                />) : 
                userType === COMMERCIAL_TEXT ? (
                  <CommercialViewComp
                    {...ObjPassedToAdmin}
                    regionDetails={regionDetails}
                    updateData={updateDataInDB}
                    closeScreen={closeEditScreen}
                    priceModify={adminPriceModify}
                    priceDropdownModify={adminPriceDropdownModify}
                    includeMaterial={includeMaterialCost}
                    includeWastage={includeWastageCost}
                  />) : null}
        </Fragment>
    );
};

export default EditProducts;