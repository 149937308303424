import React, { useState, useEffect, useRef } from "react";
import { 
    Paper,
    Modal, 
    Box, 
    Typography, 
    Button,
    Divider,
    Table, 
    TableContainer,
    TableBody, 
    TableRow, 
    TableCell, 
    TextField, 
    Select, 
    MenuItem,
    FormControl,
    InputLabel,
    Tooltip } 
from '@mui/material';

// import { DatePicker } from '@mui/x-date-pickers';

import { InputGroupAddon } from "reactstrap";
// import Select from "react-select";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
// import Creatable from "react-select/creatable";
import { getUserType, validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import Switch from "react-switch";
import baseUrl from "../../service/servicesConfig";
import moment from "moment";

const SuppliersDB = database.database().ref(`Suppliers`);

const PurchaseDetailsData = database.database().ref(`Purchase_Details`);

const sessionDate = new Date(sessionStorage.getItem(`datekey`));
let sessionMonth = sessionDate.getMonth() + 1;
if (sessionMonth < 10) sessionMonth = "0" + sessionMonth;
let sessionDt = sessionDate.getDate();
if (sessionDt < 10) sessionDt = "0" + sessionDt;



function AddGRNModal({
  open, 
  onClose,
  data,
  itemCode, // nothing but the unique key which is there in the selected Item's object (  purchase_details_key: itemCode ) as (data.uniqueKey)
  selectedDate,
  selectedMonth,
  selectedYear,
  setAllAddItemDetails, // mutating this state in NewListPurchaseAddAndEdit.js
  setAddSaveClick, // mutating this state in NewListPurchaseAddAndEdit.js
  supplierName,
  selectedPO_Item,
  ...props
}) {
   
  const [suppliersList, setSuppliersList] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [productEditObject, setProductEditObject] = useState({});
  const [updateDetails, setUpdateDetails] = useState(false);
  const [materialReceivingDt, setMaterialReceivingDt] = useState(new Date());
  const [bagRecieved, setBagRecieved] = useState("");
  const [totalWtReceived, setTotalWtReceived] = useState(
    Number(data.total_weight)
  );
  const [invoiceNoList, setInvoiceNoList] = useState("");
  const [addGrn, setAddGrn] = useState(false);
  const [billSubmitConfirm, setBillSubmitConfirm] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(data.purchase_price || "")
  const [replaceBrand, setReplaceBrand] = useState(data.replacement_brand || "");
  const [errors, setError] = useState({ // All Mandatory fields Check
    replacement_brand:"",
    no_of_bags:"",
    purchase_price:""
  });
  const [invoiceDate, setInvoiceDate] = useState();
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [invoiceRatePer, setInvoiceRatePer] = useState(
    Number(data.purchase_price)
  );
  const [allUpdatingAddItemsHash, setAllUpdatingAddItemsHash] = useState({})
  
  //console.log('AddGRNModal itemCode: ',itemCode);

  const totalReceivedWt = useRef();
  const bagReceivedRef = useRef(null);
  const diffWtRef = useRef();
  const supplierRef = useRef();

  const [schemeAmount,setSchemeAmount] = useState("")

const [poAmount,setPoAmount] = useState("")
const [gstCharge,setGstCharge] = useState("")
const [apmcCharge,setApmcCharge] = useState("")


useEffect(()=>{

  // invoice_amt is po amount for that item
  const getGST_Charges = () => {
    const calculatedCharge = (parseFloat(data.gstPerFromFb || data.gst_charges_per) * parseFloat(poAmount)) / 100;
    return calculatedCharge
  };

  const calculatedGstCharge = getGST_Charges()
  setGstCharge(calculatedGstCharge)

 const getAPMC_Charges = () => {
    const calculatedCharge = (parseFloat(data.apmcPerFromFb ||  data.apmc_charges_per) * parseFloat(poAmount)) / 100;
    return calculatedCharge
  };

  const calculatedApmcCharge = getAPMC_Charges()
  setApmcCharge(calculatedApmcCharge)

},[poAmount])


const handleBagsReceivedChange = (value) => {
    // const bagsReceived = parseInt(value, 10);
    setProductEditObject((prev) => ({
      ...prev,
      bags_received: value,
      invoice_amt: Number(data.outer_size) * value * invoiceRatePer,
    }));
    setBagRecieved(value);
    setTotalWtReceived(Number(data.outer_size) * value);
    setInvoiceRatePer(data.purchase_price)
    setError({ 
      replacement_brand:"",
      no_of_bags:"",
      purchase_price:""
    })

    setPurchasePrice(data.purchase_price)

    setPoAmount("")
    setGstCharge("")
    setApmcCharge("")
  };

  // Run the logic when the modal opens
   // to reinitialize the values of all the shared states
  useEffect(() => {
    if (open) {
      handleBagsReceivedChange(parseFloat(data.bags_received) || '');
    }
  }, [open]);



// making a predefined hash map items for Edit
  // all the items will be passed together in case of edit
  useEffect(()=>{
    const allAddItemshash={}
    
     for(let object of selectedPO_Item)
     {
      // only few keys are needed here not like edit because all the objects will be replaced in the list of objects at the time of submit
      // this is required for initial calculations
       const obj={
        // this will be used to identify that all the items are not yet full added in the array
        type:"not-added",
         // adding these keys for calculation of generic values in NewListPurchaseAddAndEdit.js
         outer_size:object.outer_size,
         bag_purchased:object.bag_purchased,
         purchase_price:object.purchase_price,
         apmc_charges:object.apmc_charges,
         gst_charges:object.gst_charges,

        gstPerFromFb:object.gstPerFromFb, // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
        apmcPerFromFb:object.apmcPerFromFb,
        gst_charges_per:object.gst_charges_per, // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
        indexVal:object.indexVal // being used to map the updated state value of bag purchased at NewListPurchaseAddAndEdit.js
        }
 
       const keyValue=object.indexVal
       allAddItemshash[keyValue]=obj
     }
     setAllUpdatingAddItemsHash(allAddItemshash)
   },[])
 



  useEffect(()=>{
    const addedItemsListOfObjects=[]
    for(let key in allUpdatingAddItemsHash)
    {
        // selecting a common GRN number for all Items
        allUpdatingAddItemsHash[key].commonGRN_no=allUpdatingAddItemsHash["0"].grn_no
        // making list of objects from hash map removing keys and only storing the values
        addedItemsListOfObjects.push(allUpdatingAddItemsHash[key])
    }
    // setAllUpdatingAddItemsArray(addedItemsListOfObjects)
    setAllAddItemDetails(addedItemsListOfObjects)
  },[allUpdatingAddItemsHash])



   
  

  const updatePurchaseDetailsNew = async () => {

    // if(!bagRecieved || parseFloat(bagRecieved)<0){
    //   window.alert("Please Enter No. of Bags Received Greater than 0")
    //   return
    // }


    setAddGrn(false);
    console.log("productEditObject From update PurchaseDetailsNew function in Check From NewAddGRNModal.js--->>",productEditObject)
    if (
      bagRecieved // &&
      // (productEditObject["invoice_number"] || data.invoice_number)
    ) {
      let newSupplier = true;
      if (productEditObject["supplier_name"]) {
        suppliersList.forEach((item) => {
          if (productEditObject["supplier_name"] == item.value) {
            newSupplier = false;
          }
        });
        if (newSupplier)
          SuppliersDB.push({
            supplier_name: productEditObject["supplier_name"],
          });
      }
      // GRN Number Creation
      if (!productEditObject["grn_no"]) {
        productEditObject["grn_no"] = `${selectedDate.split("-")[0]}${
          selectedDate.split("-")[1]
        }${selectedDate.split("-")[2].substring(0, 2)}${
          Math.floor(Math.random() * 90000) + 10000
        }`;
      }

      //const firebaseItemCode = itemCode.substring(11);

      //console.log("updatePurchaseDetails Item Key::: " + firebaseItemCode + " materialReceivingDt: ", materialReceivingDt);

      const grn_date_format = `${productEditObject.grn_date.split("-")[2]}-${
        productEditObject.grn_date.split("-")[1]
      }-${productEditObject.grn_date.split("-")[0]}`;

      console.log("grn_date_format: ", grn_date_format);

      const isAdmin = validateIsAdminUser(
        sessionStorage.getItem(LOGGED_USER_EMAIL)
      );

      console.log("isAdmin: ", isAdmin);

      const grn_date_millis = new Date(grn_date_format).getTime();
      const purchased_date_millis = new Date(data.purchased_date).getTime();
      let last30DaysDate = new Date();

      const currYear = last30DaysDate.getFullYear();
      const currMonth =
        last30DaysDate.getMonth().toString().length === 1
          ? `0${last30DaysDate.getMonth() + 1}`
          : last30DaysDate.getMonth() + 1;
      const currDate =
        last30DaysDate.getDate().toString().length === 1
          ? `0${last30DaysDate.getDate()}`
          : last30DaysDate.getDate();

      last30DaysDate = new Date(`${currYear}-${currMonth}-${currDate}`);
      last30DaysDate = last30DaysDate.setDate(last30DaysDate.getDate() - 30);

      //const fourDaysOlderDateMillis = fourDaysOlderDate.getTime();

      console.log(
        "new Date(grn_date_format: ",
        new Date(grn_date_format),
        " new Date(last30DaysDate): ",
        new Date(last30DaysDate)
      );

      if (!isAdmin) {
        if (new Date(grn_date_format) < new Date(last30DaysDate)) {
          return toast("GRN Date can not be more than 30 days old", {
            type: toast.TYPE.ERROR,
          });
        }
      }

      //console.log('purchased_date: ', purchased_date_millis, ' grn_date: ', grn_date_millis, ' fourDaysOlderDate: ', fourDaysOlderDate);
      let payment_terms = Number(data.payment_terms);
      let payment_date = moment(new Date(grn_date_format))
        .clone()
        .add(payment_terms, "day");
      const formattedPaymentDate = payment_date.format("YYYY-MM-DD");

      console.log("productEditObject Check From NewAddGRNModal.js: ", productEditObject);
      const updObj = {
        ...productEditObject,
        invoice_amt: poAmount || data.po_amount,
        diff_receiving: diffWtRef.current.value,
        total_weight_received: totalReceivedWt.current.value,
        bill_submitted: `${
          billSubmitConfirm !== "" ? "" + billSubmitConfirm : ""
        }`,
        bags_received: bagRecieved,
        replacement_brand: replaceBrand ? replaceBrand : data.replacement_brand,
        updated_by: UserEmail(),
        updated_at: `${UpdatedDate()} ${UpdatedTime()}`,
        isAdmin: isAdmin,
        purchased_date: data.purchased_date,
        payment_date: formattedPaymentDate,
        purchase_details_key: data.uniqueKey,
        expiry_date: productEditObject?.expiry_date
          ? productEditObject.expiry_date
          : `${
              new Date().getDate() < 10
                ? "0" + new Date().getDate()
                : new Date().getDate()
            }-${
              new Date().getMonth() + 1 < 10
                ? "0" + (new Date().getMonth() + 1)
                : new Date().getMonth() + 1
            }-${new Date().getFullYear()}`,
      };

      updObj.grn_date = grn_date_format;
      updObj.item_id = data.article_code;


    // adding these keys for calculation of generic values in NewListPurchaseAddAndEdit.js
    updObj.outer_size=data.outer_size
    updObj.bag_purchased=data.bag_purchased

    updObj.purchase_price=Number(purchasePrice) || data.purchase_price
    updObj.apmc_charges=apmcCharge || data.apmc_charges
    updObj.gst_charges=gstCharge || data.apmc_charges
    //(Number(purchasePrice) * Number(totalWtReceived))
    updObj.po_amount=poAmount || data.po_amount  // po amount at item level
    
    updObj.gstPerFromFb=data.gstPerFromFb // using at generic level calculation to identify exclude or include during calculation  at NewListPurchaseAddAndEdit.js
    updObj.apmcPerFromFb=data.apmcPerFromFb
    updObj.gst_charges_per=data.gst_charges_per // using at generic level calculation to identify exclude or include during calculation  at NewListPurchaseAddAndEdit.js
    updObj.indexVal=data.indexVal // being used to map the updated state value of bag purchased at NewListPurchaseAddAndEdit.js
    // these objects are added objects
    updObj.type="added"

      // console.log('check obj',updObj);
      

    // Getting the QR From the First API then adding it to the Second API Call
    //   try {
    //     const url = `/api/superzop/commercial/purchasedetails/addeditgrn?type=add`;
    //     console.log("updObj Check From NewAddGRNModal.js ---->",updObj)

    //     const getQRCodeFromAPI = await baseUrl.post(url, updObj);
 
    //     if (getQRCodeFromAPI.status === 200) {
    //       const QRCode = getQRCodeFromAPI.data.qr_code; 
          
    //       setProductEditObject({});
    //       notifyUser(true);
    //       setAddGrn(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 2000);
      
    //       const QRGenerationUrl = `/api/bag-qr?qr_code=${QRCode}`;
    //       const qrGenBody = {
    //         grn_number: updObj.grn_no,
    //         item_id: updObj.item_id,
    //         grn_date: updObj.grn_date,
    //         bags_received: updObj.bags_received,
    //       };
      
    //       console.log("Check qr gen body Check From NewAddGRNModal.js", qrGenBody);
      
    //       try {
    //         const qrResponse = await baseUrl.post(QRGenerationUrl, qrGenBody);
    //         if (qrResponse.status === 200) {
    //           toast("Saved data to QR Generation Table", {
    //             type: toast.TYPE.SUCCESS,
    //           });
    //         } else {
    //           toast("Failed to save data in QR Generation Table", {
    //             type: toast.TYPE.ERROR,
    //           });
    //         }
    //       } catch (error) {
    //         console.log(error);
    //         toast("Failed to save data in QR Generation Table", {
    //           type: toast.TYPE.ERROR,
    //         });
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     toast("Failed to update System Inventory", {
    //       type: toast.TYPE.ERROR,
    //     });
    // }

      // data.indexVal is a temporary unique 0 index values given to all the items 
      // It is made in NewListPurchaseAddAndEdit.js
      // It helps in identifying exactly in which item the changes are being made
      setAllUpdatingAddItemsHash({...allUpdatingAddItemsHash,[data.indexVal]:{...updObj}})
      setAddSaveClick(true)
      window.alert("Saved Successfully")
      onClose()
    } else {
      setUpdateDetails(false);
      setAddGrn(false);
      return toast("Enter all fields", { type: toast.TYPE.ERROR });
    }
  };

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast(`Added the GRN for ${data.article_code}`, {
        type: toast.TYPE.SUCCESS,
      });
    }
    return toast("Couldn't add the GRN", { type: toast.TYPE.ERROR });
  };

  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({
          name: suppliersList[supplier].supplier_name,
          email: suppliersList[supplier].EmailID,
          key: suppliersList[supplier].supplier_id,
          fssai: suppliersList[supplier].fssai,
          gstin: suppliersList[supplier]["GST_Identification_Number_(GSTIN)"],
          phone: suppliersList[supplier].MobilePhone,
          address: suppliersList[supplier].Billing_Address,
          street: suppliersList[supplier].Billing_Street2,
          city: suppliersList[supplier].Billing_City,
          pin: suppliersList[supplier].Billing_Code,
          state: suppliersList[supplier].Billing_State,
          supplierGroup: suppliersList[supplier].supplierGroup
        });
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        suppliers.push({
          value: items.name,
          label: items.name,
          email: items.email,
          key: items.key,
          fssai: items.fssai,
          gstin: items.gstin,
          phone: items.phone,
          address: items.address,
          street: items.street,
          city: items.city,
          pin: items.pin,
          state: items.state,
          supplierGroup:items.supplierGroup
        });
      });
    }).then(() => {
      setSuppliersList(suppliers);
    });
  };

  // invoice_amt is po amount for that item
  const getGSTCharges = () => {
    let gst_per = data.gst_charges_per
      ? Number(data.gst_charges_per.split("%")[0] || data.gstPerFromFb) //  ||  data.gst_charges_per
      : 0;

    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.po_amount);
  
    const calculateCharge = (gst_per * invoice_amt) / 100;
    
    return calculateCharge
  };

  const getAPMCCharges = () => {
    let apmc_per = data.apmc_charges_per
      ? Number(data.apmc_charges_per.split("%")[0] || data.apmcPerFromFb) // ||  data.apmc_charges_per
      : 0;
    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.po_amount);
    return (apmc_per * invoice_amt) / 100;
  };

  const parseTransportCharges = (value) => (value === "null" ? null : value);

  const getTransportCharges = () => {
    // let transport_charge = productEditObject?.transport_charges ? productEditObject.transport_charges : data.transport_charges?data.transport_charges:0;

    const transportCharges =
      parseTransportCharges(productEditObject?.transport_charges) ??
      parseTransportCharges(data?.transport_charges) ??
      0;
      
    return Number(transportCharges) * totalWtReceived;
  };
 // fetch api
  const fetchInvoiceNumber = () => {
    const invoiceNumberList = [];
    let allInvoiceNumbers = [];

    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log("fetchInvoiceNumber: ", complete_date);

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    baseUrl
      .get(url)
      .then((res) => {
        console.log("fetchInvoiceNumber: ", res);
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        Object.keys(purchaseList).forEach((items) => {
          if (purchaseList[items].invoice_number)
            allInvoiceNumbers.push(purchaseList[items].invoice_number);
        });
        allInvoiceNumbers = [...new Set(allInvoiceNumbers)];
        allInvoiceNumbers.forEach((invoices) => {
          invoiceNumberList.push({ value: invoices, label: invoices });
        });
      })
      .then(() => {
        console.log(
          "fetchInvoiceNumber invoiceNumberList: ",
          invoiceNumberList
        );
        setInvoiceNoList(invoiceNumberList);
      });
    /*PurchaseDetailsData.child(`${selectedDate.split("-")[2]}/${selectedDate.split("-")[1]}/${selectedDate}`).once("value", snapShot => {
            const purchaseList = snapShot.val();
            Object.keys(purchaseList).forEach(items => {

                if (purchaseList[items].invoice_number)
                    allInvoiceNumbers.push(purchaseList[items].invoice_number)
            })
            allInvoiceNumbers = [...new Set(allInvoiceNumbers)]
            allInvoiceNumbers.forEach(invoices => {

                invoiceNumberList.push({ "value": invoices, "label": invoices })
            })
        }).then(() => {
            console.log('fetchInvoiceNumber invoiceNumberList: ',invoiceNumberList);
            setInvoiceNoList(invoiceNumberList)
        })*/
  };

  const createGrnNumber = () => {
    let countCheck = 0;
    if (data.supplier_name && !productEditObject["supplier_name"]) {
      setProductEditObject({
        ...productEditObject,
        supplier_name: data.supplier_name,
      });
    }

    if (!productEditObject["expiry_date"]) {
      setProductEditObject({
        ...productEditObject,
        expiry_date: formatDate(new Date()),
      });
    }

    console.log(
      "GRN details" + bagRecieved + productEditObject["invoice_number"]
    );
    console.log(selectedDate);
    console.log(productEditObject);
    if (!productEditObject["bags_received"]) {
      setProductEditObject({
        ...productEditObject,
        bags_received: bagRecieved,
      });
    }

    // if (!replaceBrand) {
    //   setError({invoice_number:"",no_of_bags:"",replacement_brand:"Replacement Brand is required."});
    // } else {
    //   // setError('');
    //   console.log('Form submitted with:', replaceBrand);
    // }

    if (
      !bagRecieved ||
       !replaceBrand || !purchasePrice // || (!data.invoice_number && !productEditObject["invoice_number"])
    ) {
      // if(!data.invoice_number && !productEditObject["invoice_number"])
      // {
      //   setError({replacement_brand:"",no_of_bags:""});
      // }
      if( !bagRecieved || parseFloat(bagRecieved)<0 ){
      setError({replacement_brand:"",no_of_bags:"No of bags is required or it cannot be less than 0."});
      }
      else if(!replaceBrand)
      {
       setError({no_of_bags:"",replacement_brand:"Replacement Brand is required."});
      }
      else if(!purchasePrice)
      {
        setError({no_of_bags:"",replacement_brand:"",purchase_price:"Purchase Price is required"});
      }
      return toast("Enter all fields please check all fields", { type: toast.TYPE.ERROR });
    }

    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log(
      "createGrnNumber: ",
      complete_date,
      " productEditObject ",
      productEditObject
    );

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;

    baseUrl
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        console.log("Purchase List", purchaseList);
        let grnNumSet = new Set();
        let conditionMatchGRN = false;
        Object.keys(purchaseList).some((items) => {
          let grnNum = purchaseList[items].grn_no;
          if (grnNum) {
            if (!grnNumSet.has(grnNum)) {
              grnNumSet.add(grnNum);
            }
          }

          if (
            productEditObject["invoice_number"] ===
            purchaseList[items].invoice_number
          ) {
            conditionMatchGRN = true;
            console.log(
              'productEditObject["supplier_name"]: ',
              productEditObject["supplier_name"],
              " purchaseList[items].supplier_name: ",
              purchaseList[items].supplier_name
            );
            if (
              productEditObject["supplier_name"] !=
              purchaseList[items].supplier_name
            ) {
              toast("Supplier is not matching with Invoice Number", {
                type: toast.TYPE.ERROR,
              });
            }
            setProductEditObject({
              ...productEditObject,
              grn_no: purchaseList[items].grn_no,
            });
            setAddGrn(true);
            return true;
          }
        });
        if (!conditionMatchGRN) {
          let newGrnNum = getRandNotInList(grnNumSet);
          setProductEditObject({ ...productEditObject, grn_no: newGrnNum });
          setAddGrn(true);
        }
      });
    /*PurchaseDetailsData.child(`${selectedDate.split("-")[2]}/${selectedDate.split("-")[1]}/${selectedDate}`).once("value", snapShot => {

            const purchaseList = snapShot.val();
            console.log(purchaseList);
            let grnNumSet = new Set();
            let conditionMatchGRN = false
            Object.keys(purchaseList).forEach(items => {
                let grnNum = purchaseList[items].grn_no;
                if (grnNum) {
                    if (!grnNumSet.has(grnNum)) {
                        grnNumSet.add(grnNum);
                    }
                }

                if (productEditObject["invoice_number"] === purchaseList[items].invoice_number) {
                    conditionMatchGRN = true;
                    if (productEditObject["supplier_name"] != purchaseList[items].supplier_name) {
                        toast("Supplier is not matching with Invoice Number", { type: toast.TYPE.ERROR })
                    }
                    setProductEditObject({ ...productEditObject, "grn_no": purchaseList[items].grn_no })
                    setAddGrn(true)
                    return
                }
            })
            if (!conditionMatchGRN) {
                let newGrnNum = getRandNotInList(grnNumSet);
                setProductEditObject({ ...productEditObject, "grn_no": newGrnNum })
                setAddGrn(true)
            }
        });*/
  };

  const getRandNotInList = (distinctgrnset) => {
    let yearFromDate = selectedDate.split("-")[2];
    let newYearStr = yearFromDate.substr(yearFromDate.length - 2);
    let randomFiveDigitNum = Math.floor(Math.random() * 90000) + 10000;
    let newGrnNum = `${selectedDate.split("-")[0]}${
      selectedDate.split("-")[1]
    }${newYearStr}${randomFiveDigitNum}`;

    return distinctgrnset.has(newGrnNum)
      ? getRandNotInList(distinctgrnset)
      : newGrnNum;
  };

  useEffect(() => {
    fetchSuppliers();
    initialiseGRNDate();
    fetchInvoiceNumber();
  }, []);

  useEffect(() => {
    if (addGrn) {
      updatePurchaseDetailsNew();
      //updatePurchaseDetails()
    }
  }, [addGrn]);

  const initialiseGRNDate = () => {
    const date = new Date();
    setProductEditObject({
      ...productEditObject,
      grn_date: `${
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
      }-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getFullYear()}`,
    });
    setCurrentDate(
      `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getFullYear()}`
    );
  };

  useEffect(() => {
    if (supplierName) {
      supplierRef.current= supplierName
      const selectedSupplier = suppliersList.find(supplier => supplier.value === supplierName);
      if (selectedSupplier) {
        setProductEditObject({
          ...productEditObject,
          supplier_name: selectedSupplier.value,
          supplier_id: selectedSupplier.key,
          supplier_email: selectedSupplier.email,
          bill_address: selectedSupplier.address,
          bill_city: selectedSupplier.city,
          bill_state: selectedSupplier.state,
          bill_street: selectedSupplier.street,
          bill_pin: selectedSupplier.pin,
          bill_fssai: selectedSupplier.fssai,
          bill_gstin: selectedSupplier.gstin,
          bill_phone: selectedSupplier.phone,
        });
      }
    }
    // window.alert(JSON.stringify(productEditObject))
  }, [supplierName, suppliersList]);


  const formatDate = (val) => {
    let date = new Date(val);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };


  const materialUiformatDate = (val) => {

    if(!val){
        return ''
    }

    if (typeof val === 'string' && val.includes('-')) {
        // Assuming the input is in "DD-MM-YYYY" format
        const [day, month, year] = val.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }


    // On Change the value will be
    // Wed Jul 17 2024 05:30:00 GMT+0530 (India Standard Time) {}
    if (!(val instanceof Date)) {
      val = new Date(val);
    }

    // Ensure month and day are padded with leading zeros if necessary
    const month = String(val.getMonth() + 1).padStart(2, '0');
    const day = String(val.getDate()).padStart(2, '0');
    return `${val.getFullYear()}-${month}-${day}`;
  };



  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );


  const modalStyle = {
    position: 'relative',
    marginTop:'-10%',
    marginTop:'-10%',
    top: '50%',
    left: '50%',
   transform: 'translate(-50%, -56%)',
    width: '100vw',
    height:'100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // for scrolling
    mt: 5,
    flexGrow: 1,
    display: 'flex', 
    flexDirection: 'column',
    gap: 2,
    overflow: 'hidden'
  };

  const toNumber = (value, defaultValue = 0) => {
    const num = Number(value);
    return isNaN(num) ? defaultValue : num;
  };

  const finalInvoiceCalculationField = () =>
  {

  // Calculate invoice rate
  const invoice_rate = toNumber(data.outer_size) * toNumber(bagRecieved) * toNumber(purchasePrice);

  // Get additional charges
  const gst = toNumber(gstCharge);
  const apmc = toNumber(apmcCharge);
  const transport = toNumber(getTransportCharges());

  // Calculate total additional charges
  const total = gst + apmc + transport;

  // Calculate final invoice amount
  const calculatedFinalInvoice = invoice_rate + total - toNumber(schemeAmount);
  
  return calculatedFinalInvoice;
};


  return (
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="add-grn-modal-title"
    aria-describedby="add-grn-modal-description"
  >
    <Box sx={modalStyle}>

    <Button
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        Close
      </Button>

    <Typography 
            sx={{ textAlign: 'center',
             marginBottom: 1,  
             fontWeight: 'bold'}} 
            variant="h6" 
            component="h4">
      Add GRN
    </Typography>

    <Divider/>

     <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
        {/* <Table  sx={{
                        '& .MuiTableCell-root': {
                          padding: '4px 8px',
                          fontSize: '0.75rem',
                        },
                        '& .MuiTableCell-head': {
                          fontWeight: 'bold',
                          backgroundColor: '#28a745',
                        },
                        '& .MuiButton-root': {
                          padding: '2px 8px',
                          fontSize: '0.7rem',
                          minWidth: '60px',
                          height: '24px'
                        }
                      }}  > */}
      <Table >
     <TableBody>
        <TableRow>
       
              <TableCell>PO Number</TableCell>
              <TableCell>
              <Tooltip title={data.po_number} arrow>
                <TextField disabled value={data.po_number} InputProps={{ readOnly: true }} fullWidth />
            </Tooltip>
              </TableCell>
    
              <TableCell>PO Date</TableCell>
              <TableCell>
              <TextField disabled value={data.purchased_date} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>GST Amount</TableCell>
              <TableCell>
                {/* <TextField disabled value={getGSTCharges()} InputProps={{ readOnly: true }} fullWidth /> */}
                <TextField disabled value={gstCharge} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

         </TableRow>


         


         <TableRow>

         <TableCell>Item Name</TableCell>
              <TableCell>
            <Tooltip title={data.item_name} arrow>
                <TextField disabled value={data.item_name} InputProps={{ readOnly: true }} fullWidth />
            </Tooltip>
         </TableCell>
        

         <TableCell>Article Code</TableCell>
              <TableCell>
                <TextField disabled value={data.article_code} InputProps={{ readOnly: true }} fullWidth />
         </TableCell>


            <TableCell>Source</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Source</InputLabel>
                  <Select
                    value={data?.source_type ? data.source_type.toUpperCase() : ""}
                    label="Source"
                    disabled
                  >
                    <MenuItem value="MILL">MILL</MenuItem>
                    <MenuItem value="LOCAL PICK">LOCAL PICK</MenuItem>
                    <MenuItem value="DC DELIVERED">DC DELIVERED</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

         </TableRow>
            
     
            

         <TableRow>

        
            <TableCell>HSN</TableCell>
              <TableCell>
                <TextField disabled value={data.hsn_code} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>
              

              <TableCell>Outer Type</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Outer Type</InputLabel>
                  <Select
                    value={data?.outer_type || ""}
                    label="Outer Type"
                    disabled
                  >
                    <MenuItem value="SMALL PACK">SMALL PACK</MenuItem>
                    <MenuItem value="GONI">GONI</MenuItem>
                    <MenuItem value="BAG OF PACKS">BAG OF PACKS</MenuItem>
                    <MenuItem value="HIGH VALUE OF LOOSE">HIGH VALUE OF LOOSE</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell>Outer Size</TableCell>
              <TableCell>
                <TextField  disabled value={`${data.outer_size} ${data?.uom || ""}`} InputProps={{ readOnly: true }} fullWidth />
            </TableCell>

         </TableRow>



         <TableRow>

              <TableCell>Category</TableCell>
              <TableCell>
                <TextField disabled value={data.category} InputProps={{ readOnly: true }} fullWidth />
             </TableCell>

              <TableCell>Final Invoice Rate (per kg/pc)</TableCell>
              <TableCell>
                <TextField
                disabled
                  value={
                    // productEditObject?.invoice_amt
                    //   ? (
                    //       (Number(productEditObject.invoice_amt) +
                    //         getGSTCharges() +
                    //         getAPMCCharges() +
                    //         getTransportCharges() -
                    //         Number(
                    //           productEditObject?.scheme_amount
                    //             ? productEditObject?.scheme_amount
                    //             : 0
                    //         )) /
                    //       totalWtReceived
                    //     ).toFixed(2)
                    //   : ""
                    totalWtReceived==0 ? 0 : (finalInvoiceCalculationField()/totalWtReceived).toFixed(2)

                  }
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </TableCell>

             



         <TableCell>Invoice Amount</TableCell>
              <TableCell>
                <TextField
                disabled
                  // value={
                  //   productEditObject?.invoice_amt
                  //     ? productEditObject.invoice_amt
                  //     : ""
                  // }
                  value={poAmount}
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </TableCell>

         </TableRow>



         <TableRow>

       <TableCell>PO Rate</TableCell>
              <TableCell>
                <TextField disabled value={data.purchase_price} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>
      

        <TableCell>Total Weight Ordered</TableCell>
              <TableCell>
                <TextField disabled value={`${data.total_weight} ${data?.uom || ""}`} InputProps={{ readOnly: true }} fullWidth />
         </TableCell>


              <TableCell>APMC Amount</TableCell>
              <TableCell>
                {/* <TextField  disabled value={getAPMCCharges()} InputProps={{ readOnly: true }} fullWidth /> */}
                <TextField  disabled value={apmcCharge} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>


         </TableRow>



         <TableRow>


              <TableCell>Total Weight Received</TableCell>
              <TableCell>
              <TextField
              type="number"
              inputRef={totalReceivedWt}
              value={totalWtReceived}
              onChange={(e) => {
                let inputValue = e.target.value;

               if (inputValue === "") {
                setTotalWtReceived("");
                 return;
                }

         // Allow only numbers with up to 2 decimal places
          if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
             const numericValue = parseFloat(inputValue); 

             setTotalWtReceived(numericValue); 

             setPoAmount((numericValue * parseFloat(purchasePrice)).toFixed(2));

            setProductEditObject({
              ...productEditObject,
              invoice_amt: (numericValue * invoiceRatePer).toFixed(2),
            });
          }
         }}
        fullWidth
        onWheel={(e) => e.target.blur()}
        />

              </TableCell>

            
                <TableCell>Final Invoice Amount</TableCell>
              <TableCell>
                <TextField
                 disabled
                  value={
                    // productEditObject?.invoice_amt
                    //   ? Number(productEditObject.invoice_amt) +
                    //     getGSTCharges() +
                    //     getAPMCCharges() +
                    //     getTransportCharges() -
                    //     Number(
                    //       productEditObject?.scheme_amount
                    //         ? productEditObject?.scheme_amount
                    //         :    0
                    //         )
                    //       : ""

                    (finalInvoiceCalculationField() || 0) .toFixed(2)
                    
                      }
                      InputProps={{ readOnly: true }}
                      fullWidth
                    />
         </TableCell>


         <TableCell>MRP</TableCell>
              <TableCell>
                <TextField
                    //  error={!!error}
                    //  helperText={error}
                  defaultValue={data.mrp}
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      mrp: e.target.value,
                    })
                  }
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                />
              </TableCell>
            
        
                      {/* <TableCell>Supplier Name</TableCell>
                    <TableCell>
        <FormControl fullWidth>
          <InputLabel id="supplier-select-label">Supplier</InputLabel>
          <Select
            disabled
            labelId="supplier-select-label"
            ref={supplierRef}
            id="supplier-select"
            // value={productEditObject.supplier_name || ''}
            value={supplierName || ''}
            label="Supplier"
            onChange={(event) => {
              const selectedSupplier = suppliersList.find(supplier => supplier.value === event.target.value);
              if (selectedSupplier) {
                setProductEditObject({
                  ...productEditObject,
                  supplier_name: selectedSupplier.value,
                  supplier_id: selectedSupplier.key,
                  supplier_email: selectedSupplier.email,
                  bill_address: selectedSupplier.address,
                  bill_city: selectedSupplier.city,
                  bill_state: selectedSupplier.state,
                  bill_street: selectedSupplier.street,
                  bill_pin: selectedSupplier.pin,
                  bill_fssai: selectedSupplier.fssai,
                  bill_gstin: selectedSupplier.gstin,
                  bill_phone: selectedSupplier.phone,
                });
              }
            }}
          >
            {suppliersList && suppliersList.map((supplier) => (
              <MenuItem key={supplier.key} value={supplier.value}>
                {supplier.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell> */}
                  

            

         </TableRow>



    

         {/* <TableRow> */}
        
        
        
           
        
             {/* <TableCell>Invoice Date</TableCell>
            <TableCell>
            <TextField
            type="date"
            id="date"
            label="Select Date"
            value={materialUiformatDate(invoiceDate) || (data.invoice_date ? materialUiformatDate(data.invoice_date) : materialUiformatDate(new Date(data.invoice_date)))}
            onChange={(e) => {
                const newDate = e.target.value ? new Date(e.target.value) : null;
                setProductEditObject({
                ...productEditObject,
                invoice_date: formatDate(newDate)
                });
                setInvoiceDate(newDate);
            }}

            inputProps={{
                max: materialUiformatDate(new Date()) // This sets the maximum date to today
              }}

            />
        </TableCell> */}

               {/* <TableCell>Material Receiving Date</TableCell>
              <TableCell>
              <TextField
                    type="date"
                    id="date"
                    label="Select Date"
                    value={materialUiformatDate(materialReceivingDt)}
                    onChange={(e) => {
                      const newDate = e.target.value ? new Date(e.target.value) : null;
                      setMaterialReceivingDt(newDate);
                      setProductEditObject({
                        ...productEditObject,
                        grn_date: formatDate(newDate), // converts to "dd/MM/yyyy"
                      });
                    }}
                    inputProps={{
                        max: materialUiformatDate(new Date()) // This sets the maximum date to today
                      }}
                  />
              </TableCell> */}

         {/* </TableRow> */}



         <TableRow>

        
         <TableCell>Bags Received *</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  inputRef={bagReceivedRef}
                  error={!!errors.no_of_bags}
                  helperText={errors.no_of_bags}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const bagsReceived = parseInt(e.target.value, 10);
                    if (bagsReceived > data.bag_purchased) {
                      bagReceivedRef.current.value = "";
                      toast("Bags Received cannot be more than Bag Purchased", {
                        type: toast.TYPE.ERROR,
                      });
                      return;
                    }
                    setProductEditObject({
                      ...productEditObject,
                      bags_received: e.target.value,
                    });
                    setBagRecieved(e.target.value);

                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(purchasePrice)*parseFloat(data.outer_size))

                    setTotalWtReceived(
                      (Number(data.outer_size) * Number(e.target.value)).toFixed(2)
                    );
                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt:
                        Number(data.outer_size) *
                        Number(e.target.value) *
                        invoiceRatePer,
                    });
                  }}
                  fullWidth

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                />
              </TableCell>
        
        
              <TableCell>Difference in Receiving</TableCell>
              <TableCell>
                <TextField
                  disabled
                  inputRef={diffWtRef}
                  value={
                    bagRecieved ? Number(data.total_weight) - totalWtReceived : ""
                  }
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </TableCell>

         <TableCell>Scheme Amount</TableCell>
              <TableCell>
                <TextField
                // error={!productEditObject.scheme_amount}
                onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                  {
                    setProductEditObject({
                      ...productEditObject,
                      scheme_amount: e.target.value,
                    })
                    setSchemeAmount(e.target.value)
                  }
                  }
                  fullWidth
                />
              </TableCell>


         </TableRow>




         <TableRow>


         {/* <TableCell>Invoice Number</TableCell>
              <TableCell>

              <TextField
                  options={invoiceNoList}
                  error={!!errors.invoice_number}
                  helperText={errors.invoice_number}
                  placeholder={data.invoice_number}
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      invoice_number: e.target.value,
                    })
                  }
                  defaultValue={data.invoice_number}
                  styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                />
              </TableCell> */}


              <TableCell>Purchase Price *</TableCell>
              <TableCell>
                <TextField

                  disabled
                  
                  type="number"
                  onWheel={(e) => e.target.blur()}

                  error={!!errors.purchase_price}
                  helperText={errors.purchase_price}

                  defaultValue={data.purchase_price}
                  onChange={(e) => {
                    setInvoiceRatePer(e.target.value);
                    setPurchasePrice(e.target.value)

                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(bagRecieved)*parseFloat(data.outer_size))

                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt:
                        Number(e.target.value) * Number(totalWtReceived),
                    });
                  }}
                  fullWidth

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                />
              </TableCell>


           

              <TableCell>Replacement Brand *</TableCell>
              <TableCell>
                <TextField
                  onChange={(e) => setReplaceBrand(e.target.value)}
                  defaultValue={data.replacement_brand}
                  error={!!errors.replacement_brand}
                  helperText={errors.replacement_brand}
                  fullWidth

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}
                />
              </TableCell>


              <TableCell>Location</TableCell>
              <TableCell>
                <TextField
                  onChange={(e) => {
                    setProductEditObject({
                      ...productEditObject,
                      location: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </TableCell>
            

         </TableRow>






         <TableRow>

              <TableCell>Remark</TableCell>
              <TableCell>
                <TextField
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      remark: e.target.value,
                    })
                  }
                  fullWidth
                />
              </TableCell>

        </TableRow>


 {/* should create different tablerows for each dynamic render */}
     <TableRow>
       
              {data.transport && (
                <>
                <TableCell>Transport</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Transport</InputLabel>
                    <Select
                      value={data?.transport ? data.transport.toUpperCase() : ""}
                      label="Transport"
                      disabled
                    >
                      <MenuItem value="EXCLUDED">EXCLUDED</MenuItem>
                      <MenuItem value="INCLUDED">INCLUDED</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                </>
            
            )}

         
            {data.transport === "excluded" && (
              <>
             
                  <TableCell>Total Transport</TableCell>
                  <TableCell>
                    <TextField
                      placeholder={String(data.transport_charges * data.total_weight)}
                      onChange={(e) =>
                        setProductEditObject({
                          ...productEditObject,
                          total_transport: e.target.value,
                          transport_charges:
                            Number(e.target.value) / Number(data.total_weight),
                        })
                      }
                      fullWidth
                    />
                  </TableCell>
              
                  <TableCell>Mill Transport (Per KG/Pc) *</TableCell>
                  <TableCell>
                    <TextField
                      value={
                        productEditObject?.transport_charges
                          ? productEditObject.transport_charges
                          : data.transport_charges
                      }
                      InputProps={{ readOnly: true }}
                      disabled
                      fullWidth
                    />
                  </TableCell>
               
              </>
            )}

         </TableRow>




         <TableRow>

             {data.category && data.category.toUpperCase() === "FRESH" && (
              <>
               
                  <TableCell>Batch No</TableCell>
                  <TableCell>
                    <TextField
                      onChange={(e) =>
                        setProductEditObject({
                          ...productEditObject,
                          batch_no: e.target.value,
                        })
                      }
                      fullWidth
                    />
                  </TableCell>
               

            
                  <TableCell>Expiry Date</TableCell>
                  <TableCell>
                  <TextField
                        type="date"
                        id="date"
                        label="Select Date"
                        value={materialUiformatDate(expiryDate) || (data.expiry_date ? materialUiformatDate(data.expiry_date) : materialUiformatDate(new Date(data.expiry_date)))}
                        onChange={(e) => {
                          const newDate = e.target.value ? new Date(e.target.value) : null;
                          setExpiryDate(newDate);
                          setProductEditObject({
                            ...productEditObject,
                            expiry_date: formatDate(newDate),
                          });
                        }}
                        inputProps={{
                            max: materialUiformatDate(new Date()) // This sets the maximum date to today
                        }}
                      />
                  </TableCell>
             
              </>
            )}
         </TableRow>


     </TableBody>
     </Table>
     </TableContainer>


     <Divider />
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
     <Button sx={{ mr: 1 }} onClick={() => createGrnNumber()} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
    </Box>

     </Box>
  
  </Modal>
  );
}

export default AddGRNModal;
