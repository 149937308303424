import React, { useContext, useState, useEffect } from "react";
import { Breadcrumb, Tab, Row, Col, Nav, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ProductDataCardContainer,
  TabFirstContainer,
  TabSecondContainer,
} from "./wrapper";
import ImageComp from "../ImageComp_ProductData";
import VideoComp from "../ImageComp_ProductData/VideoComp";
import VideoUpload from "../ImageComp_ProductData/VideoUpload";
import { imageDetails, videoDetails } from "./constants";

import ToggleBarComp from "../../components/ToggleBar";
import { rootStore } from "../../store";
import database from "../../service/firebaseConfig";
import {
  EDIT_PRODUCTS,
  INIT_PAGE_TITLE,
  PROD_MASTER_TABLE,
  REGION_ALL,
  REGION_BOM_ALL,
  REGION_BOM_A,
  REGION_BOM_B,
  REGION_BOM_H,
  REGION_BOM_K,
  REGION_BOM_N,
  REGION_PUNE_P,
  REGION_S,
  ALL_REGIONS_LIST,
  LOGGED_USER_EMAIL,
  PRICE_TABLE
} from "../../constants/allConstants";
import { parseToNumber, getRoundNumber } from "../../service/commonChecks";
import { getCurrCity, getCurrRegion } from "../../service/getCityRegion";
import ImgUpload from "../ImageComp_ProductData/ImageUpload";
import GoniImgUpload from "../ImageComp_ProductData/GoniImgUpload";
import PriceEditModal from "./EditPriceItems";
import ComboItemsModal from "./Combo";
import {
  getUserType,
  validateIsAdminUser,
  validateCommercialUser,
  validateMDMUser,
} from "../../service/credentials";
import EditDealerPriceModal from "./EditDealerPrice";
import EditSuperchoiceDealerPrice from "./EditSuperchoiceDealerPrice";

const ProductDataCardComp = (data) => {
  // console.log(data)
  const [getNewUrl, setNewUrl] = useState("");
  const [getNewUrl2, setNewUrl2] = useState("");
  const [getNewUrl3, setNewUrl3] = useState("");
  const [imageName, setImageName] = useState("");
  const [getNewImage, setNewImage] = useState("");
  const [getNewImage2, setNewImage2] = useState("");
  const [getNewImage3, setNewImage3] = useState("");

  const [getNewVidUrl,setNewVidUrl] = useState("");
  const [getNewVideo,setNewVideo] = useState("");


  const [modalShow, setModalShow] = useState(false);
  const [comboItemModalShow, setComboItemModalShow] = useState(false);
  const [getModalAccess, setModalAccess] = useState(false);
  const [getcomboEdit, setcomboEdit] = useState(false);
  const [getComboItems, setComboItems] = useState("");
  const [EditDealerPrice, setEditDealerPrice] = useState(false);
  const [getSuperChoiceEditDealerPrice, setSuperChoiceEditDealerPrice] =
    useState(false);
  const [goniImgUrl, setGoniImgUrl] = useState("");

  let dtModified = new Date();
  dtModified = `${dtModified.getDate()}/${
    dtModified.getMonth() + 1
  }/${dtModified.getFullYear()} ${
    dtModified.getHours() < 10
      ? "0" + dtModified.getHours()
      : dtModified.getHours()
  }:${
    dtModified.getMinutes() < 10
      ? "0" + dtModified.getMinutes()
      : dtModified.getMinutes()
  }`;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const tempObj = {
    final_price: 0,
    final_bag_price: 0,
  };

  const globalState = useContext(rootStore);
  const { dispatch } = globalState;
  const global_state = globalState.state;
  const stockEditModalShow = global_state.hasOwnProperty("showStockModal")
    ? global_state.showStockModal
    : false;
  console.log(stockEditModalShow);

  const currCity = getCurrCity(global_state);
  console.log(currCity);
  const currRegion = getCurrRegion(global_state);
  console.log(currRegion);
  const statusInd = data.stock_ind === "Y" ? true : false;
  const [stockStatus, setStockStatus] = useState(statusInd);
  const [dbStatus, setDBStatus] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const [initPriceCalc, setInitPriceCalc] = useState(tempObj);
  const [grossCost, setGrossCost] = useState();
  const [secondaryPMCost, setSecondaryPMCost] = useState();
  const [conversionPer, setConversionPer] = useState();
  const [wastagePer, setWastagePer] = useState();
  const priceSymbol = <span style={{ width: "auto" }}> &#x20B9; </span>;
  const toggleTextObj = { header: "Stock" };
  const emptyData = (
    <div style={{ color: "#aeaeae", display: "inline" }}>No Data</div>
  );
  let statusIndCounter = 0;

  const handleEdit = () => {
    document.title = `${INIT_PAGE_TITLE} | Edit Product`;
    dispatch({ type: EDIT_PRODUCTS, data });
  };

  const notifyUser = (success) => {
    if (success) {
      return toast("Changed the status", { type: toast.TYPE.SUCCESS });
    }
    return toast("Couldn't change the status", { type: toast.TYPE.ERROR });
  };

  const notifyUserWithMessage = (success, message) => {
    if (!message) {
      return console.log("please pass message argument");
    }
    if (success) {
      return toast(message, {
        type: toast.TYPE.SUCCESS,
        style: { width: "100px", color: "red" },
      });
    }
    return toast(message, { type: toast.TYPE.ERROR });
  };

  const notifyImageChange = (success) => {
    if (success) {
      return toast("Changed image across all regions", {
        type: toast.TYPE.SUCCESS,
      });
    }
  };

  const notifyImageChangeWithMessage = (success, message) => {
    if (!message) {
      return console.log("please pass message arguement");
    }
    if (success) {
      return toast(message, {
        type: toast.TYPE.SUCCESS,
        style: { width: "100px", color: "red" },
      });
    }
    return toast(message, { type: toast.TYPE.ERROR });
  };

  const updateStockInd = (region, item, stockInd, counter) => {
    database
      .database()
      .ref(`${PROD_MASTER_TABLE}`)
      .child(region)
      .child(item)
      .child('stock_ind')
      .once("value", (results) => {
        const product = results.val();
        if (product) {
          database
            .database()
            .ref(`${PROD_MASTER_TABLE}`)
            .child(region)
            .child(item)
            .update(
              {
                stock_ind: stockInd,
                updated_by: sessionStorage.getItem(`Logged User Email Id`),
                updated_at: dtModified,
              },
              function (error) {
                statusIndCounter++;
                if (error) {
                  console.log("Error for the region:: " + region);
                  notifyUser(false);
                }
            
                else {
                  console.log("Updated for the region:: " + region);
                  notifyUserWithMessage(
                    true,
                    "Updated for the region:: " + region
                  );
                }
              }
            );
        } else {
          statusIndCounter++;
          console.log("Product not available:: " + region);
          notifyUserWithMessage(false, "Product not available:: " + region);
          // if (counter == statusIndCounter) {
          //   notifyUser(true);
          // }
        }
      });
  };

  const calculatePriceDetails = async () =>{
    const priceDetails = await database.database().ref(`Price_Details/mumbai/items/${data.item_id}`).once("value");
    let calVal = priceDetails.val();
    let package_material_cost = calVal?.package_material_cost ? calVal?.package_material_cost : "";
    let conversion_per = calVal?.conversion_per ? calVal?.conversion_per : "";
    let wastage_per = calVal?.wastage_per ? calVal?.wastage_per : "";
    setSecondaryPMCost(package_material_cost);
    setConversionPer(conversion_per);
    setWastagePer(wastage_per);
  }

  const calculateGrossCost = async () => {
    const priceDetails = await database.database().ref(`Price_Details/mumbai/items/${data.parent_id}`).once("value");
    let calVal = priceDetails.val();
    let gross_cost = calVal?.gross_cost ? calVal?.gross_cost : 0;
    if((data.weight).includes('gm')) {
      let weightVal = data.weight.split(' ')[0];
      gross_cost = gross_cost * (Number(weightVal) /1000 );
    }
    if((data.weight).includes('kg')) {
      let weightVal = data.weight.split(' ')[0];
      gross_cost = gross_cost; //* weightVal
    }
    setGrossCost(parseFloat(gross_cost).toFixed(2));
  }

  const writeDataToDB = () => {
    statusIndCounter = 0;
    const stockInd = stockStatus ? "Y" : "N";
    console.log("Region Name:: " + currRegion);
    const regionA = "A";
    const regionB = "B";
    const regionN = "N";
    const regionPG = "PG";
    const regionNK = "NK";
    const regionST = "ST";
    const regionH = "H";
    const regionP = "P";
    const regionK = "K";

    if (currRegion === REGION_ALL) {
      ALL_REGIONS_LIST.forEach((item, index) => {
        updateStockInd(item, data.keyNo, stockInd, 10);
      });
    } else if (currRegion === REGION_BOM_ALL) {
      updateStockInd(regionA, data.keyNo, stockInd, 8);
      updateStockInd(regionB, data.keyNo, stockInd, 8);
      updateStockInd(regionN, data.keyNo, stockInd, 8);
      updateStockInd(regionPG, data.keyNo, stockInd, 8);
      updateStockInd(regionNK, data.keyNo, stockInd, 8);
      updateStockInd(regionST, data.keyNo, stockInd, 8);
      updateStockInd(regionH, data.keyNo, stockInd, 8);
      updateStockInd(regionK, data.keyNo, stockInd, 8);
    } else {
      database
        .database()
        .ref(`${PROD_MASTER_TABLE}/${data.regionName}/${data.keyNo}`)
        .update(
          {
            stock_ind: stockInd,
          },
          function (error) {
            if (error) {
              notifyUser(false);
            } else {
              notifyUser(true);
            }
          }
        );
    }
  };

  const handleStockStatus = () => {
    setStockStatus(!stockStatus);
    setInitLoad(false);
  };

  const doInitPriceCalc = () => {
    const temp = { ...initPriceCalc };
    temp.final_price =
      parseToNumber(data.total_price) +
      parseToNumber(data.transport_charge) +
      parseToNumber(data.apmc_charge) +
      parseToNumber(data.gst_charge);
    temp.final_price = getRoundNumber(temp.final_price);

    temp.final_bag_price =
      parseToNumber(temp.final_price) * parseToNumber(data.lot_size);
    temp.final_bag_price = getRoundNumber(temp.final_bag_price);

    setInitPriceCalc(temp);
  };

  const editDetails = {
    dealer_price: data.dealer_price,
    apmc_charge: data.apmc_charge,
    gst_charge: data.gst_charge,
    transport_charge: data.transport_charge,
    market_price: data.market_price,
    moq: data.moq,
    weight: data.weight,
    case_size: data.case_size,
    brand: data.brand,
    brand_hindi: data.brand_hindi,
    brand_marathi: data.brand_marathi,
    brand_gujarati: data.brand_gujarati,
    hindi_description: data.hindi_description,
    marathi_description: data.marathi_description,
    gujarati_description: data.gujarati_description,
    long_description: data.long_description,
    long_description_hindi: data.long_description_hindi,
    long_description_marathi: data.long_description_marathi,
    long_description_gujarati: data.long_description_gujarati,
    location:data.location,
    item_description1: data.item_description1,
    category: data.category,
    major_category: data.major_category,
    sub_category: data.sub_category,
    variety: data.variety,
    active_status: data.active_status,
    buying_flag: data.buying_flag,
    uom: data.uom,
    outer_size: data.outer_size,
    image: data.image,
    image_url: data.image_url,
    parent_id: data.parent_id,
    parent_id1: data.parent_id1,
    top_selling: data.top_selling,
    hsn_code: data.hsn_code,
    max_order_quantity: data.max_order_quantity,
    ean:data.ean,
    pcs_per_bag:data.pcs_per_bag,
    product_type:data.product_type,
    packing_material_size:data.packing_material_size
  };

  const loadComboDetails = () => {
    let count = 1;
    let comboItems = {};

    if (data.combo1) {
      comboItems["combo1"] = data.combo1;
    }
    if (data.combo2) {
      comboItems["combo2"] = data.combo2;
    }
    if (data.combo3) {
      comboItems["combo3"] = data.combo3;
    }
    if (data.combo4) {
      comboItems["combo4"] = data.combo4;
    }
    if (data.combo5) {
      comboItems["combo5"] = data.combo5;
    }
    if (data.combo6) {
      comboItems["combo6"] = data.combo6;
    }
    if (data.combo7) {
      comboItems["combo7"] = data.combo7;
    }
    if (data.combo8) {
      comboItems["combo8"] = data.combo8;
    }
    if (data.combo9) {
      comboItems["combo9"] = data.combo9;
    }
    if (data.combo10) {
      comboItems["combo10"] = data.combo10;
    }
    if (data.combo1) {
      comboItems["combo1"] = data.combo1;
    }
    setComboItems(comboItems);
  };

  useEffect(() => {
    if (!initLoad) {
      writeDataToDB();
    }
  }, [stockStatus, initLoad]);

  useEffect(() => {
    if (!initLoad) {
      notifyUser(dbStatus);
    }
  }, [dbStatus]);

  useEffect(() => {
    doInitPriceCalc();
    calculateGrossCost();
    calculatePriceDetails();
  }, []);

  useEffect(() => {
    if (getcomboEdit) loadComboDetails();
  }, [getcomboEdit]);

  const pushImgToDB = (region, keyNo, dataToSave) => {
    
    return new Promise((resolve, reject) => {
      database
        .database()
        .ref(`${PROD_MASTER_TABLE}/${region}/${keyNo}`)
        .once("value", (snapShot) => {
          if (snapShot.hasChildren()) {
            notifyImageChangeWithMessage(
              true,
              `Image updated for region ${region}`
            );
            console.log(`data updated in region ${region}`);
            database
              .database()
              .ref(`${PROD_MASTER_TABLE}/${region}/${keyNo}`)
              .update(dataToSave)
              .then(() => {
                resolve();
              });
          } else {
            notifyImageChangeWithMessage(
              false,
              `Product not available:: region ${region}`
            );
            resolve();
          }
        });
    });
  };

  const saveImgToDB = (img_url, image_identifier) => {
    
    if (!image_identifier) {
      return console.log("Cannot save image, missing arguements");
    }
    let image_node = "";
    if (image_identifier === "image") {
      image_node = "image_url";
    } else if (image_identifier === "add1") {
      image_node = "add1_image_url";
    } else if (image_identifier === "add2") {
      image_node = "add2_image_url";
    }

    console.log(
      "saving to....",
      `${PROD_MASTER_TABLE}/${REGION_BOM_A}/${data.keyNo}`
    );

    let dataToSave = {
      [image_node]: img_url,
      updated_by: sessionStorage.getItem(`Logged User Email Id`),
      updated_at: dtModified,
    };
    if(image_identifier == "image") {
      let image_name = img_url.substring(img_url.indexOf("%2F") + 3, img_url.lastIndexOf("?"))
      dataToSave["image"] = image_name
    }

    const writePromises = [];
    ALL_REGIONS_LIST.forEach((item, index) => {
      writePromises.push(pushImgToDB(item, data.keyNo, dataToSave));
    });

    Promise.all(writePromises)
      .then((results) => {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.log("Error occured while attempting to save img data", err);
      });
  };

  const pushVidToDB = (region, keyNo, VideoDataToSave) => {
    
    return new Promise((resolve, reject) => {
      database
        .database()
        .ref(`${PROD_MASTER_TABLE}/${region}/${keyNo}`)
        .once("value", (snapShot) => {
          if (snapShot.hasChildren()) {
            notifyImageChangeWithMessage(
              true,
              `Video updated for region ${region}`
            );
            console.log(`data updated in region ${region}`);
            database
              .database()
              .ref(`${PROD_MASTER_TABLE}/${region}/${keyNo}`)
              .update(VideoDataToSave)
              .then(() => {
                resolve();
              });
          } else {
            notifyImageChangeWithMessage(
              false,
              `Product not available:: region ${region}`
            );
            resolve();
          }
        });
    });

  }

  const saveVidToDB = (vid_url, video_identifier) => {
    
    if (!video_identifier) {
      return console.log("Cannot save video, missing arguements");
    }

    let VideoName = data.brand
    ? data.brand + "_" + data.item_description1
    : data.item_description1;
  const modifiedVideoName =
    VideoName.replace(
      /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    ).replace(/ /g, "_") + ".mp4";

  let video_node = "";
  let video_name = data.video;
  if (video_identifier === "video") {
    video_node = "video_url";
    video_name = modifiedVideoName;
  } 

  console.log(
    "saving to....",
    `${PROD_MASTER_TABLE}/${REGION_BOM_A}/${data.keyNo}`
  );

  const VideoDataToSave = {
    video: video_name,
    [video_node]: vid_url,
    updated_by: sessionStorage.getItem(`Logged User Email Id`),
    updated_at: dtModified,
  };
  
  const writePromises = [];
  ALL_REGIONS_LIST.forEach((item, index) => {
      writePromises.push(pushVidToDB(item, data.keyNo, VideoDataToSave));
    });


    Promise.all(writePromises)
      .then((results) => {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.log("Error occured while attempting to save video data", err);
      });




  }

  useEffect(() => {
    if (getNewUrl !== "") {
      setNewImage(getNewUrl);
      saveImgToDB(getNewUrl, "image");
    }
  }, [getNewUrl]);

  useEffect(() => {
    if (getNewUrl2 !== "") {
      setNewImage2(getNewUrl2);
      saveImgToDB(getNewUrl2, "add1");
    }
  }, [getNewUrl2]);

  useEffect(() => {
    if (getNewUrl3 !== "") {
      setNewImage3(getNewUrl3);
      saveImgToDB(getNewUrl3, "add2");
    }
  }, [getNewUrl3]);

  useEffect(() => {
    if(getNewVidUrl !== ""){
      setNewVideo(getNewVidUrl);
      saveVidToDB(getNewVidUrl, "video")
    }
  },[getNewVidUrl])

  useEffect(() => {
    if (goniImgUrl) {
      const randomFile = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);
      let imgName = `${randomFile}_goni_img_${dtModified}`;

      if (currRegion == "ALL") {
        ALL_REGIONS_LIST.forEach((regions, index) => {
          database
          .database()
          .ref(`${PROD_MASTER_TABLE}`)
            .child(regions)
            .child(data.keyNo)
            .once("value", (snapshot) => {
              if (snapshot.hasChildren()) {
                  database
                    .database()
                    .ref(`${PROD_MASTER_TABLE}/${regions}/${data.keyNo}`)
                    .update({
                      goni_image: imgName,
                      goni_image_url: goniImgUrl,
                      updated_by: sessionStorage.getItem(`Logged User Email Id`),
                      updated_at: dtModified,
                    })
                    .then(() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                      return toast(`Goni Image uploaded for region ${regions}`, {
                        type: toast.TYPE.SUCCESS,
                      });
                    });
                }
              });
            });
      } else {
        database
          .database()
          .ref(`${PROD_MASTER_TABLE}/${currRegion}/${data.keyNo}`)
          .update({
            goni_image: imgName,
            goni_image_url: goniImgUrl,
            updated_by: sessionStorage.getItem(`Logged User Email Id`),
            updated_at: dtModified,
          })
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            return toast(`Goni Image uploaded for region ${currRegion}`, {
              type: toast.TYPE.SUCCESS,
            });
          });
      }
    }
  }, [goniImgUrl]);

  return (
    <div>
      <ProductDataCardContainer key={data.serialNo}>
        <Breadcrumb className="topDetails">
          <Breadcrumb.Item active variant="primary">
            {" "}
            {data.serialNo}. {data.keyNo} / {data.item_description1} /{" "}
            {data.brand}
          </Breadcrumb.Item>

          {validateMDMUser(sessionStorage.getItem(LOGGED_USER_EMAIL))&&<Breadcrumb.Item>
            <ToggleBarComp
              handleStatus={handleStockStatus}
              isInStock={stockStatus}
              text={toggleTextObj}
            />
          </Breadcrumb.Item>}

          {currRegion !== "S" && 
          !(
            
            data.brand && data.brand.includes("Khetika Naturale") ||
            data.brand && data.brand.includes("Khetika Naturalé") ||
            data.brand && data.brand.includes("Khetika Choice") || 
            data.brand && data.brand.includes("KhetikaChoice") ||
            data.market_price && data.market_price > 0
          ) &&
          getUserType(sessionStorage.getItem(LOGGED_USER_EMAIL)) ===
            "admin" ? (
            <Breadcrumb.Item className="editSign" onClick={handleEdit}>
              {" "}
              Edit price <i className="fas fa-user-edit"></i>{" "}
            </Breadcrumb.Item>
          ) : (
            data.category == "Combo" && (
              <Button
                variant="warning"
                onClick={() => {
                  setComboItemModalShow(true);
                  setcomboEdit(true);
                }}
              >
                Add Combo
              </Button>
            )
          )}
          {currRegion !== "S" && 
          !(
            
            data.brand && data.brand.includes("Khetika Naturale") ||
            data.brand && data.brand.includes("Khetika Naturalé") ||
            data.brand && data.brand.includes("Khetika Choice") || 
            data.brand && data.brand.includes("KhetikaChoice") ||
            data.market_price && data.market_price > 0
          ) && getUserType(sessionStorage.getItem(LOGGED_USER_EMAIL)) ==
            "commercial" && (
            <Breadcrumb.Item className="editSign" onClick={handleEdit}>
              {" "}
              Edit price <i className="fas fa-user-edit"></i>{" "}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                 <Nav.Item>
                    <Nav.Link eventKey="first">Basic Product Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Pricing Details</Nav.Link>
                  </Nav.Item>
                </Nav>
          
                      
                  <Col  style={{ width:"fit-content" , marginRight: "10px", marginTop:"3rem" }} >
                            <VideoUpload
                              fileName={
                                data.brand
                                  ? data.brand + "_" + data.item_description1
                                  : data.item_description1
                              }
                              src={data.video_url}
                              newUrl={setNewVidUrl}
                            >
                              <VideoComp
                                style={{ fontSize: "17px", color: "black" }}
                                vidDetails={videoDetails}
                                alt={"Item Video"}
                                newVid={getNewVideo}
                                videoUrl={data.video_url}
                              />
                            </VideoUpload>
                   </Col>

           
              {/* add logic  */}
            </Col>

            
          
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <TabFirstContainer>
                    <div className="leftMain">
                      <h6>
                        <span>Category</span>: {data.category || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Sub Category</span>:{" "}
                        {data.sub_category || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Major Category</span>:{" "}
                        {data.major_category || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Brand</span>: {data.brand || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Brand in Hindi</span>:{" "}
                        {data.brand_hindi || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Brand in Marathi</span>:{" "}
                        {data.brand_marathi || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Brand in Gujarati</span>:{" "}
                        {data.brand_gujarati || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Description in Hindi</span>:{" "}
                        {data.hindi_description || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Description in Marathi</span>:{" "}
                        {data.marathi_description || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Description in Gujarati</span>:{" "}
                        {data.gujarati_description || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Long Description</span>:{" "}
                        {data.long_description || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Long Description in Hindi</span>:{" "}
                        {data.long_description_hindi || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Long Description in Marathi</span>:{" "}
                        {data.long_description_marathi || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Long Description in Gujarati</span>:{" "}
                        {data.long_description_gujarati || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Variety</span>: {data.variety || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Brick</span>: {data.brick || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Grade</span>: {data.grade || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Internal Brand</span>:{" "}
                        {data.internal_brand || emptyData}{" "}
                      </h6>
                    </div>
                    <div
                      className="rightMain"
                      style={{ height: "400px", position: "relative" }}
                    >
                      <Button
                        variant="outline-info"
                        onClick={() => {
                          setModalShow(true);
                          setModalAccess(true);
                        }}
                      >
                        {" "}
                        Edit Products Details
                      </Button>
                      <div>
                        <Row style={{ width: "400px" }}>
                          <Col style={{ width: "150px", marginRight: "10px" }}>
                            <ImgUpload
                              fileName={
                                // data.brand
                                //   ? data.brand + "_" + data.item_description1
                                //   : data.item_description1
                                data.brand ? (data.brand || "defaultBrand") + "_" + (data.item_description1 || "defaultDescription") :(data.item_description1 ||"defaultDescription")
                              }
                              newUrl={setNewUrl}
                            >
                              <ImageComp
                                style={{ fontSize: "17px", color: "black" }}
                                imgDetails={imageDetails}
                                alt={"Item Image"}
                                newImg={getNewImage}
                                imageUrl={data.image_url}
                              />
                            </ImgUpload>
                          </Col>
                          <>
                            <Col style={{ width: "150px" }}>
                              <GoniImgUpload downloadedUrl={setGoniImgUrl}>
                                <figure
                                  style={{ fontSize: "17px", color: "black" }}
                                >
                                  <img
                                    src={data.goni_image_url}
                                    alt={"Goni Image"}
                                    height="120"
                                    width="120"
                                  />
                                </figure>
                              </GoniImgUpload>
                            </Col>
                          </>
                        </Row>

                        <Row style={{ width: "400px" }}>
                          <Col style={{ width: "150px", marginRight: "10px" }}>
                            <ImgUpload
                              fileName={
                                data.brand
                                  ? data.brand +
                                    "_" +
                                    data.item_description1 +
                                    "_add1_img"
                                  : data.item_description1 + "_add1_img"
                               
                              }
                              newUrl={setNewUrl2}
                            >
                              <ImageComp
                                style={{ fontSize: "17px", color: "black" }}
                                alt={"Add Image 1"}
                                imgDetails={imageDetails}
                                newImg={getNewImage2}
                                imageUrl={data.add1_image_url}
                              />{" "}
                            </ImgUpload>
                          </Col>
                          <Col>
                            <ImgUpload
                            
                              fileName={
                                data.brand
                                  ? data.brand +
                                    "_" +
                                    data.item_description1 +
                                    "_add2_img"
                                  : data.item_description1 + "_add2_img"
                              }
                              newUrl={setNewUrl3}
                            >
                              <ImageComp
                                style={{ fontSize: "17px", color: "black" }}
                                alt={"Add Image 2"}
                                imgDetails={imageDetails}
                                newImg={getNewImage3}
                                imageUrl={data.add2_image_url}
                              />
                            </ImgUpload>
                          </Col>
                        </Row>
                        
                       


                      </div>
                    </div>
                  </TabFirstContainer>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <TabSecondContainer>
                    <div className="leftMain">
                      <h6>
                        <span>MRP</span>: {priceSymbol}{" "}
                        {data.market_price || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Retailer Margin %</span>:{" "}
                        {data.retailer_margin_per || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Base Price</span>: {priceSymbol}{" "}
                        {data.base_price || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Transport Price</span>: {priceSymbol}{" "}
                        {data.transport_charge || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>APMC Charge</span>: {priceSymbol}{" "}
                        {data.apmc_charge || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>GST Charge</span>: {priceSymbol}{" "}
                        {data.gst_charge || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Weight</span>: {data.weight || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Case Size</span>: {data.case_size || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>MoQ</span>: {data.moq || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Selling Price</span>: {priceSymbol}{" "}
                        {data.selling_price || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Selling Price Ex GST</span>: {priceSymbol}{" "}
                        {data.selling_price_ex_gst || emptyData}{" "}
                      </h6>
                      <h6>
                        <span>Dealer Price</span>: {priceSymbol}{" "}
                        {data.dealer_price || emptyData}{" "}
                      </h6>
                    </div>
                    {(validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) || validateCommercialUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) )&& (
                      <div className="rightMain">
                        {data.market_price && Number(data.market_price) > 0 || 
                        data.brand && data.brand.includes("Khetika Choice") || 
                        data.brand && data.brand.includes("KhetikaChoice") ? (
                          <Button
                            onClick={() => {
                              setSuperChoiceEditDealerPrice(true);
                            }}
                            variant="primary"
                          >
                            Edit Dealer Price
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </TabSecondContainer>
                </Tab.Pane>
              </Tab.Content>
            </Col>
            
          </Row>

          {/* video comp */}
       

        </Tab.Container>
      </ProductDataCardContainer>
      <PriceEditModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        editableData={editDetails}
        currentRegion={currRegion}
        keyNumber={data.keyNo}
      />
      {data.category == "Combo" && (
        <ComboItemsModal
          show={comboItemModalShow}
          onHide={() => setComboItemModalShow(false)}
          allComboItems={getComboItems}
          itemIdDetails={data.item_id}
        />
      )}
      {EditDealerPrice && (
        <EditDealerPriceModal
          show={EditDealerPrice}
          onHide={() => setEditDealerPrice(false)}
          itemId={data.item_id}
          cityName={currCity}
          regionName={currRegion}
          transportCharges={data.transport_charge}
          DealerPrice={data.dealer_price}
          BasePrice={data.base_price}
          marketPrice={data.market_price}
          totalpriceValue={data.total_price}
          actualApmcCharges={data.apmc_charge}
          offerInd={data.offer_indicator}
          longDescription={data.long_description}
          lotSize={data.lot_size}
          retailerMarginPerc={data.retailer_margin_perc}
        />
      )}

      {getSuperChoiceEditDealerPrice && (
        <EditSuperchoiceDealerPrice
          show={getSuperChoiceEditDealerPrice}
          onHide={() => setSuperChoiceEditDealerPrice(false)}
          itemId={data.item_id}
          cityName={currCity}
          regionName={currRegion}
          data={data}
          transportCharges={data.transport_charge}
          DealerPrice={data.dealer_price}
          BasePrice={data.base_price}
          marketPrice={data.market_price}
          totalpriceValue={data.total_price}
          actualApmcCharges={data.apmc_charge}
          offerInd={data.offer_indicator}
          longDescription={data.long_description}
          lotSize={data.lot_size}
          retailerMarginPerc={data.retailer_margin_perc}
          selling_price={data.selling_price}
          selling_price_ex_gst={data.selling_price_ex_gst}
          gross_cost = {grossCost} //{data.gross_cost}
          cost_of_conversion_per = {conversionPer} //{data.conversion_per}
          cost_of_wastage_per = {wastagePer} //{data.wastage_per}
          pm_cost = {data.primary_pm_cost}
          secondary_pm_cost = {secondaryPMCost} // {data.package_material_cost}
          landing_cost = {data.landing_cost}
          parent_id = {data.parent_id}
        />
      )}
    </div>
  );
};

export default ProductDataCardComp;
